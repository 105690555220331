@import url(https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900|Raleway:400,400i,500,500i,600,600i,700,700i,800&display=swap);
@charset "UTF-8";
/* All Plugin Css Here */
@font-face {
  font-family: Material-Design-Iconic-Font;
  src: url(/static/media/Material-Design-Iconic-Font.a4d31128.woff2) format("woff2"), url(/static/media/Material-Design-Iconic-Font.d2a55d33.woff) format("woff"), url(/static/media/Material-Design-Iconic-Font.b351bd62.ttf) format("truetype"); }

.zmdi {
  display: inline-block;
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.zmdi-hc-lg {
  font-size: 1.33333333em;
  line-height: .75em;
  vertical-align: -15%; }

.zmdi-hc-2x {
  font-size: 2em; }

.zmdi-hc-3x {
  font-size: 3em; }

.zmdi-hc-4x {
  font-size: 4em; }

.zmdi-hc-5x {
  font-size: 5em; }

.zmdi-hc-fw {
  width: 1.28571429em;
  text-align: center; }

.zmdi-hc-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none; }

.zmdi-hc-ul > li {
  position: relative; }

.zmdi-hc-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: .14285714em;
  text-align: center; }

.zmdi-hc-li.zmdi-hc-lg {
  left: -1.85714286em; }

.zmdi-hc-border {
  padding: .1em .25em;
  border: solid .1em #9e9e9e;
  border-radius: 2px; }

.zmdi-hc-border-circle {
  padding: .1em .25em;
  border: solid .1em #9e9e9e;
  border-radius: 50%; }

.zmdi.pull-left {
  float: left;
  margin-right: .15em; }

.zmdi.pull-right {
  float: right;
  margin-left: .15em; }

.zmdi-hc-spin {
  -webkit-animation: zmdi-spin 1.5s infinite linear;
  animation: zmdi-spin 1.5s infinite linear; }

.zmdi-hc-spin-reverse {
  -webkit-animation: zmdi-spin-reverse 1.5s infinite linear;
  animation: zmdi-spin-reverse 1.5s infinite linear; }

@-webkit-keyframes zmdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes zmdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-webkit-keyframes zmdi-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg); } }

@keyframes zmdi-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg); } }

.zmdi-hc-rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.zmdi-hc-rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.zmdi-hc-rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.zmdi-hc-flip-horizontal {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.zmdi-hc-flip-vertical {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1); }

.zmdi-hc-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle; }

.zmdi-hc-stack-1x, .zmdi-hc-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center; }

.zmdi-hc-stack-1x {
  line-height: inherit; }

.zmdi-hc-stack-2x {
  font-size: 2em; }

.zmdi-hc-inverse {
  color: #fff; }

.zmdi-3d-rotation:before {
  content: '\f101'; }

.zmdi-airplane-off:before {
  content: '\f102'; }

.zmdi-airplane:before {
  content: '\f103'; }

.zmdi-album:before {
  content: '\f104'; }

.zmdi-archive:before {
  content: '\f105'; }

.zmdi-assignment-account:before {
  content: '\f106'; }

.zmdi-assignment-alert:before {
  content: '\f107'; }

.zmdi-assignment-check:before {
  content: '\f108'; }

.zmdi-assignment-o:before {
  content: '\f109'; }

.zmdi-assignment-return:before {
  content: '\f10a'; }

.zmdi-assignment-returned:before {
  content: '\f10b'; }

.zmdi-assignment:before {
  content: '\f10c'; }

.zmdi-attachment-alt:before {
  content: '\f10d'; }

.zmdi-attachment:before {
  content: '\f10e'; }

.zmdi-audio:before {
  content: '\f10f'; }

.zmdi-badge-check:before {
  content: '\f110'; }

.zmdi-balance-wallet:before {
  content: '\f111'; }

.zmdi-balance:before {
  content: '\f112'; }

.zmdi-battery-alert:before {
  content: '\f113'; }

.zmdi-battery-flash:before {
  content: '\f114'; }

.zmdi-battery-unknown:before {
  content: '\f115'; }

.zmdi-battery:before {
  content: '\f116'; }

.zmdi-bike:before {
  content: '\f117'; }

.zmdi-block-alt:before {
  content: '\f118'; }

.zmdi-block:before {
  content: '\f119'; }

.zmdi-boat:before {
  content: '\f11a'; }

.zmdi-book-image:before {
  content: '\f11b'; }

.zmdi-book:before {
  content: '\f11c'; }

.zmdi-bookmark-outline:before {
  content: '\f11d'; }

.zmdi-bookmark:before {
  content: '\f11e'; }

.zmdi-brush:before {
  content: '\f11f'; }

.zmdi-bug:before {
  content: '\f120'; }

.zmdi-bus:before {
  content: '\f121'; }

.zmdi-cake:before {
  content: '\f122'; }

.zmdi-car-taxi:before {
  content: '\f123'; }

.zmdi-car-wash:before {
  content: '\f124'; }

.zmdi-car:before {
  content: '\f125'; }

.zmdi-card-giftcard:before {
  content: '\f126'; }

.zmdi-card-membership:before {
  content: '\f127'; }

.zmdi-card-travel:before {
  content: '\f128'; }

.zmdi-card:before {
  content: '\f129'; }

.zmdi-case-check:before {
  content: '\f12a'; }

.zmdi-case-download:before {
  content: '\f12b'; }

.zmdi-case-play:before {
  content: '\f12c'; }

.zmdi-case:before {
  content: '\f12d'; }

.zmdi-cast-connected:before {
  content: '\f12e'; }

.zmdi-cast:before {
  content: '\f12f'; }

.zmdi-chart-donut:before {
  content: '\f130'; }

.zmdi-chart:before {
  content: '\f131'; }

.zmdi-city-alt:before {
  content: '\f132'; }

.zmdi-city:before {
  content: '\f133'; }

.zmdi-close-circle-o:before {
  content: '\f134'; }

.zmdi-close-circle:before {
  content: '\f135'; }

.zmdi-close:before {
  content: '\f136'; }

.zmdi-cocktail:before {
  content: '\f137'; }

.zmdi-code-setting:before {
  content: '\f138'; }

.zmdi-code-smartphone:before {
  content: '\f139'; }

.zmdi-code:before {
  content: '\f13a'; }

.zmdi-coffee:before {
  content: '\f13b'; }

.zmdi-collection-bookmark:before {
  content: '\f13c'; }

.zmdi-collection-case-play:before {
  content: '\f13d'; }

.zmdi-collection-folder-image:before {
  content: '\f13e'; }

.zmdi-collection-image-o:before {
  content: '\f13f'; }

.zmdi-collection-image:before {
  content: '\f140'; }

.zmdi-collection-item-1:before {
  content: '\f141'; }

.zmdi-collection-item-2:before {
  content: '\f142'; }

.zmdi-collection-item-3:before {
  content: '\f143'; }

.zmdi-collection-item-4:before {
  content: '\f144'; }

.zmdi-collection-item-5:before {
  content: '\f145'; }

.zmdi-collection-item-6:before {
  content: '\f146'; }

.zmdi-collection-item-7:before {
  content: '\f147'; }

.zmdi-collection-item-8:before {
  content: '\f148'; }

.zmdi-collection-item-9-plus:before {
  content: '\f149'; }

.zmdi-collection-item-9:before {
  content: '\f14a'; }

.zmdi-collection-item:before {
  content: '\f14b'; }

.zmdi-collection-music:before {
  content: '\f14c'; }

.zmdi-collection-pdf:before {
  content: '\f14d'; }

.zmdi-collection-plus:before {
  content: '\f14e'; }

.zmdi-collection-speaker:before {
  content: '\f14f'; }

.zmdi-collection-text:before {
  content: '\f150'; }

.zmdi-collection-video:before {
  content: '\f151'; }

.zmdi-compass:before {
  content: '\f152'; }

.zmdi-cutlery:before {
  content: '\f153'; }

.zmdi-delete:before {
  content: '\f154'; }

.zmdi-dialpad:before {
  content: '\f155'; }

.zmdi-dns:before {
  content: '\f156'; }

.zmdi-drink:before {
  content: '\f157'; }

.zmdi-edit:before {
  content: '\f158'; }

.zmdi-email-open:before {
  content: '\f159'; }

.zmdi-email:before {
  content: '\f15a'; }

.zmdi-eye-off:before {
  content: '\f15b'; }

.zmdi-eye:before {
  content: '\f15c'; }

.zmdi-eyedropper:before {
  content: '\f15d'; }

.zmdi-favorite-outline:before {
  content: '\f15e'; }

.zmdi-favorite:before {
  content: '\f15f'; }

.zmdi-filter-list:before {
  content: '\f160'; }

.zmdi-fire:before {
  content: '\f161'; }

.zmdi-flag:before {
  content: '\f162'; }

.zmdi-flare:before {
  content: '\f163'; }

.zmdi-flash-auto:before {
  content: '\f164'; }

.zmdi-flash-off:before {
  content: '\f165'; }

.zmdi-flash:before {
  content: '\f166'; }

.zmdi-flip:before {
  content: '\f167'; }

.zmdi-flower-alt:before {
  content: '\f168'; }

.zmdi-flower:before {
  content: '\f169'; }

.zmdi-font:before {
  content: '\f16a'; }

.zmdi-fullscreen-alt:before {
  content: '\f16b'; }

.zmdi-fullscreen-exit:before {
  content: '\f16c'; }

.zmdi-fullscreen:before {
  content: '\f16d'; }

.zmdi-functions:before {
  content: '\f16e'; }

.zmdi-gas-station:before {
  content: '\f16f'; }

.zmdi-gesture:before {
  content: '\f170'; }

.zmdi-globe-alt:before {
  content: '\f171'; }

.zmdi-globe-lock:before {
  content: '\f172'; }

.zmdi-globe:before {
  content: '\f173'; }

.zmdi-graduation-cap:before {
  content: '\f174'; }

.zmdi-home:before {
  content: '\f175'; }

.zmdi-hospital-alt:before {
  content: '\f176'; }

.zmdi-hospital:before {
  content: '\f177'; }

.zmdi-hotel:before {
  content: '\f178'; }

.zmdi-hourglass-alt:before {
  content: '\f179'; }

.zmdi-hourglass-outline:before {
  content: '\f17a'; }

.zmdi-hourglass:before {
  content: '\f17b'; }

.zmdi-http:before {
  content: '\f17c'; }

.zmdi-image-alt:before {
  content: '\f17d'; }

.zmdi-image-o:before {
  content: '\f17e'; }

.zmdi-image:before {
  content: '\f17f'; }

.zmdi-inbox:before {
  content: '\f180'; }

.zmdi-invert-colors-off:before {
  content: '\f181'; }

.zmdi-invert-colors:before {
  content: '\f182'; }

.zmdi-key:before {
  content: '\f183'; }

.zmdi-label-alt-outline:before {
  content: '\f184'; }

.zmdi-label-alt:before {
  content: '\f185'; }

.zmdi-label-heart:before {
  content: '\f186'; }

.zmdi-label:before {
  content: '\f187'; }

.zmdi-labels:before {
  content: '\f188'; }

.zmdi-lamp:before {
  content: '\f189'; }

.zmdi-landscape:before {
  content: '\f18a'; }

.zmdi-layers-off:before {
  content: '\f18b'; }

.zmdi-layers:before {
  content: '\f18c'; }

.zmdi-library:before {
  content: '\f18d'; }

.zmdi-link:before {
  content: '\f18e'; }

.zmdi-lock-open:before {
  content: '\f18f'; }

.zmdi-lock-outline:before {
  content: '\f190'; }

.zmdi-lock:before {
  content: '\f191'; }

.zmdi-mail-reply-all:before {
  content: '\f192'; }

.zmdi-mail-reply:before {
  content: '\f193'; }

.zmdi-mail-send:before {
  content: '\f194'; }

.zmdi-mall:before {
  content: '\f195'; }

.zmdi-map:before {
  content: '\f196'; }

.zmdi-menu:before {
  content: '\f197'; }

.zmdi-money-box:before {
  content: '\f198'; }

.zmdi-money-off:before {
  content: '\f199'; }

.zmdi-money:before {
  content: '\f19a'; }

.zmdi-more-vert:before {
  content: '\f19b'; }

.zmdi-more:before {
  content: '\f19c'; }

.zmdi-movie-alt:before {
  content: '\f19d'; }

.zmdi-movie:before {
  content: '\f19e'; }

.zmdi-nature-people:before {
  content: '\f19f'; }

.zmdi-nature:before {
  content: '\f1a0'; }

.zmdi-navigation:before {
  content: '\f1a1'; }

.zmdi-open-in-browser:before {
  content: '\f1a2'; }

.zmdi-open-in-new:before {
  content: '\f1a3'; }

.zmdi-palette:before {
  content: '\f1a4'; }

.zmdi-parking:before {
  content: '\f1a5'; }

.zmdi-pin-account:before {
  content: '\f1a6'; }

.zmdi-pin-assistant:before {
  content: '\f1a7'; }

.zmdi-pin-drop:before {
  content: '\f1a8'; }

.zmdi-pin-help:before {
  content: '\f1a9'; }

.zmdi-pin-off:before {
  content: '\f1aa'; }

.zmdi-pin:before {
  content: '\f1ab'; }

.zmdi-pizza:before {
  content: '\f1ac'; }

.zmdi-plaster:before {
  content: '\f1ad'; }

.zmdi-power-setting:before {
  content: '\f1ae'; }

.zmdi-power:before {
  content: '\f1af'; }

.zmdi-print:before {
  content: '\f1b0'; }

.zmdi-puzzle-piece:before {
  content: '\f1b1'; }

.zmdi-quote:before {
  content: '\f1b2'; }

.zmdi-railway:before {
  content: '\f1b3'; }

.zmdi-receipt:before {
  content: '\f1b4'; }

.zmdi-refresh-alt:before {
  content: '\f1b5'; }

.zmdi-refresh-sync-alert:before {
  content: '\f1b6'; }

.zmdi-refresh-sync-off:before {
  content: '\f1b7'; }

.zmdi-refresh-sync:before {
  content: '\f1b8'; }

.zmdi-refresh:before {
  content: '\f1b9'; }

.zmdi-roller:before {
  content: '\f1ba'; }

.zmdi-ruler:before {
  content: '\f1bb'; }

.zmdi-scissors:before {
  content: '\f1bc'; }

.zmdi-screen-rotation-lock:before {
  content: '\f1bd'; }

.zmdi-screen-rotation:before {
  content: '\f1be'; }

.zmdi-search-for:before {
  content: '\f1bf'; }

.zmdi-search-in-file:before {
  content: '\f1c0'; }

.zmdi-search-in-page:before {
  content: '\f1c1'; }

.zmdi-search-replace:before {
  content: '\f1c2'; }

.zmdi-search:before {
  content: '\f1c3'; }

.zmdi-seat:before {
  content: '\f1c4'; }

.zmdi-settings-square:before {
  content: '\f1c5'; }

.zmdi-settings:before {
  content: '\f1c6'; }

.zmdi-shield-check:before {
  content: '\f1c7'; }

.zmdi-shield-security:before {
  content: '\f1c8'; }

.zmdi-shopping-basket:before {
  content: '\f1c9'; }

.zmdi-shopping-cart-plus:before {
  content: '\f1ca'; }

.zmdi-shopping-cart:before {
  content: '\f1cb'; }

.zmdi-sign-in:before {
  content: '\f1cc'; }

.zmdi-sort-amount-asc:before {
  content: '\f1cd'; }

.zmdi-sort-amount-desc:before {
  content: '\f1ce'; }

.zmdi-sort-asc:before {
  content: '\f1cf'; }

.zmdi-sort-desc:before {
  content: '\f1d0'; }

.zmdi-spellcheck:before {
  content: '\f1d1'; }

.zmdi-storage:before {
  content: '\f1d2'; }

.zmdi-store-24:before {
  content: '\f1d3'; }

.zmdi-store:before {
  content: '\f1d4'; }

.zmdi-subway:before {
  content: '\f1d5'; }

.zmdi-sun:before {
  content: '\f1d6'; }

.zmdi-tab-unselected:before {
  content: '\f1d7'; }

.zmdi-tab:before {
  content: '\f1d8'; }

.zmdi-tag-close:before {
  content: '\f1d9'; }

.zmdi-tag-more:before {
  content: '\f1da'; }

.zmdi-tag:before {
  content: '\f1db'; }

.zmdi-thumb-down:before {
  content: '\f1dc'; }

.zmdi-thumb-up-down:before {
  content: '\f1dd'; }

.zmdi-thumb-up:before {
  content: '\f1de'; }

.zmdi-ticket-star:before {
  content: '\f1df'; }

.zmdi-toll:before {
  content: '\f1e0'; }

.zmdi-toys:before {
  content: '\f1e1'; }

.zmdi-traffic:before {
  content: '\f1e2'; }

.zmdi-translate:before {
  content: '\f1e3'; }

.zmdi-triangle-down:before {
  content: '\f1e4'; }

.zmdi-triangle-up:before {
  content: '\f1e5'; }

.zmdi-truck:before {
  content: '\f1e6'; }

.zmdi-turning-sign:before {
  content: '\f1e7'; }

.zmdi-wallpaper:before {
  content: '\f1e8'; }

.zmdi-washing-machine:before {
  content: '\f1e9'; }

.zmdi-window-maximize:before {
  content: '\f1ea'; }

.zmdi-window-minimize:before {
  content: '\f1eb'; }

.zmdi-window-restore:before {
  content: '\f1ec'; }

.zmdi-wrench:before {
  content: '\f1ed'; }

.zmdi-zoom-in:before {
  content: '\f1ee'; }

.zmdi-zoom-out:before {
  content: '\f1ef'; }

.zmdi-alert-circle-o:before {
  content: '\f1f0'; }

.zmdi-alert-circle:before {
  content: '\f1f1'; }

.zmdi-alert-octagon:before {
  content: '\f1f2'; }

.zmdi-alert-polygon:before {
  content: '\f1f3'; }

.zmdi-alert-triangle:before {
  content: '\f1f4'; }

.zmdi-help-outline:before {
  content: '\f1f5'; }

.zmdi-help:before {
  content: '\f1f6'; }

.zmdi-info-outline:before {
  content: '\f1f7'; }

.zmdi-info:before {
  content: '\f1f8'; }

.zmdi-notifications-active:before {
  content: '\f1f9'; }

.zmdi-notifications-add:before {
  content: '\f1fa'; }

.zmdi-notifications-none:before {
  content: '\f1fb'; }

.zmdi-notifications-off:before {
  content: '\f1fc'; }

.zmdi-notifications-paused:before {
  content: '\f1fd'; }

.zmdi-notifications:before {
  content: '\f1fe'; }

.zmdi-account-add:before {
  content: '\f1ff'; }

.zmdi-account-box-mail:before {
  content: '\f200'; }

.zmdi-account-box-o:before {
  content: '\f201'; }

.zmdi-account-box-phone:before {
  content: '\f202'; }

.zmdi-account-box:before {
  content: '\f203'; }

.zmdi-account-calendar:before {
  content: '\f204'; }

.zmdi-account-circle:before {
  content: '\f205'; }

.zmdi-account-o:before {
  content: '\f206'; }

.zmdi-account:before {
  content: '\f207'; }

.zmdi-accounts-add:before {
  content: '\f208'; }

.zmdi-accounts-alt:before {
  content: '\f209'; }

.zmdi-accounts-list-alt:before {
  content: '\f20a'; }

.zmdi-accounts-list:before {
  content: '\f20b'; }

.zmdi-accounts-outline:before {
  content: '\f20c'; }

.zmdi-accounts:before {
  content: '\f20d'; }

.zmdi-face:before {
  content: '\f20e'; }

.zmdi-female:before {
  content: '\f20f'; }

.zmdi-male-alt:before {
  content: '\f210'; }

.zmdi-male-female:before {
  content: '\f211'; }

.zmdi-male:before {
  content: '\f212'; }

.zmdi-mood-bad:before {
  content: '\f213'; }

.zmdi-mood:before {
  content: '\f214'; }

.zmdi-run:before {
  content: '\f215'; }

.zmdi-walk:before {
  content: '\f216'; }

.zmdi-cloud-box:before {
  content: '\f217'; }

.zmdi-cloud-circle:before {
  content: '\f218'; }

.zmdi-cloud-done:before {
  content: '\f219'; }

.zmdi-cloud-download:before {
  content: '\f21a'; }

.zmdi-cloud-off:before {
  content: '\f21b'; }

.zmdi-cloud-outline-alt:before {
  content: '\f21c'; }

.zmdi-cloud-outline:before {
  content: '\f21d'; }

.zmdi-cloud-upload:before {
  content: '\f21e'; }

.zmdi-cloud:before {
  content: '\f21f'; }

.zmdi-download:before {
  content: '\f220'; }

.zmdi-file-plus:before {
  content: '\f221'; }

.zmdi-file-text:before {
  content: '\f222'; }

.zmdi-file:before {
  content: '\f223'; }

.zmdi-folder-outline:before {
  content: '\f224'; }

.zmdi-folder-person:before {
  content: '\f225'; }

.zmdi-folder-star-alt:before {
  content: '\f226'; }

.zmdi-folder-star:before {
  content: '\f227'; }

.zmdi-folder:before {
  content: '\f228'; }

.zmdi-gif:before {
  content: '\f229'; }

.zmdi-upload:before {
  content: '\f22a'; }

.zmdi-border-all:before {
  content: '\f22b'; }

.zmdi-border-bottom:before {
  content: '\f22c'; }

.zmdi-border-clear:before {
  content: '\f22d'; }

.zmdi-border-color:before {
  content: '\f22e'; }

.zmdi-border-horizontal:before {
  content: '\f22f'; }

.zmdi-border-inner:before {
  content: '\f230'; }

.zmdi-border-left:before {
  content: '\f231'; }

.zmdi-border-outer:before {
  content: '\f232'; }

.zmdi-border-right:before {
  content: '\f233'; }

.zmdi-border-style:before {
  content: '\f234'; }

.zmdi-border-top:before {
  content: '\f235'; }

.zmdi-border-vertical:before {
  content: '\f236'; }

.zmdi-copy:before {
  content: '\f237'; }

.zmdi-crop:before {
  content: '\f238'; }

.zmdi-format-align-center:before {
  content: '\f239'; }

.zmdi-format-align-justify:before {
  content: '\f23a'; }

.zmdi-format-align-left:before {
  content: '\f23b'; }

.zmdi-format-align-right:before {
  content: '\f23c'; }

.zmdi-format-bold:before {
  content: '\f23d'; }

.zmdi-format-clear-all:before {
  content: '\f23e'; }

.zmdi-format-clear:before {
  content: '\f23f'; }

.zmdi-format-color-fill:before {
  content: '\f240'; }

.zmdi-format-color-reset:before {
  content: '\f241'; }

.zmdi-format-color-text:before {
  content: '\f242'; }

.zmdi-format-indent-decrease:before {
  content: '\f243'; }

.zmdi-format-indent-increase:before {
  content: '\f244'; }

.zmdi-format-italic:before {
  content: '\f245'; }

.zmdi-format-line-spacing:before {
  content: '\f246'; }

.zmdi-format-list-bulleted:before {
  content: '\f247'; }

.zmdi-format-list-numbered:before {
  content: '\f248'; }

.zmdi-format-ltr:before {
  content: '\f249'; }

.zmdi-format-rtl:before {
  content: '\f24a'; }

.zmdi-format-size:before {
  content: '\f24b'; }

.zmdi-format-strikethrough-s:before {
  content: '\f24c'; }

.zmdi-format-strikethrough:before {
  content: '\f24d'; }

.zmdi-format-subject:before {
  content: '\f24e'; }

.zmdi-format-underlined:before {
  content: '\f24f'; }

.zmdi-format-valign-bottom:before {
  content: '\f250'; }

.zmdi-format-valign-center:before {
  content: '\f251'; }

.zmdi-format-valign-top:before {
  content: '\f252'; }

.zmdi-redo:before {
  content: '\f253'; }

.zmdi-select-all:before {
  content: '\f254'; }

.zmdi-space-bar:before {
  content: '\f255'; }

.zmdi-text-format:before {
  content: '\f256'; }

.zmdi-transform:before {
  content: '\f257'; }

.zmdi-undo:before {
  content: '\f258'; }

.zmdi-wrap-text:before {
  content: '\f259'; }

.zmdi-comment-alert:before {
  content: '\f25a'; }

.zmdi-comment-alt-text:before {
  content: '\f25b'; }

.zmdi-comment-alt:before {
  content: '\f25c'; }

.zmdi-comment-edit:before {
  content: '\f25d'; }

.zmdi-comment-image:before {
  content: '\f25e'; }

.zmdi-comment-list:before {
  content: '\f25f'; }

.zmdi-comment-more:before {
  content: '\f260'; }

.zmdi-comment-outline:before {
  content: '\f261'; }

.zmdi-comment-text-alt:before {
  content: '\f262'; }

.zmdi-comment-text:before {
  content: '\f263'; }

.zmdi-comment-video:before {
  content: '\f264'; }

.zmdi-comment:before {
  content: '\f265'; }

.zmdi-comments:before {
  content: '\f266'; }

.zmdi-check-all:before {
  content: '\f267'; }

.zmdi-check-circle-u:before {
  content: '\f268'; }

.zmdi-check-circle:before {
  content: '\f269'; }

.zmdi-check-square:before {
  content: '\f26a'; }

.zmdi-check:before {
  content: '\f26b'; }

.zmdi-circle-o:before {
  content: '\f26c'; }

.zmdi-circle:before {
  content: '\f26d'; }

.zmdi-dot-circle-alt:before {
  content: '\f26e'; }

.zmdi-dot-circle:before {
  content: '\f26f'; }

.zmdi-minus-circle-outline:before {
  content: '\f270'; }

.zmdi-minus-circle:before {
  content: '\f271'; }

.zmdi-minus-square:before {
  content: '\f272'; }

.zmdi-minus:before {
  content: '\f273'; }

.zmdi-plus-circle-o-duplicate:before {
  content: '\f274'; }

.zmdi-plus-circle-o:before {
  content: '\f275'; }

.zmdi-plus-circle:before {
  content: '\f276'; }

.zmdi-plus-square:before {
  content: '\f277'; }

.zmdi-plus:before {
  content: '\f278'; }

.zmdi-square-o:before {
  content: '\f279'; }

.zmdi-star-circle:before {
  content: '\f27a'; }

.zmdi-star-half:before {
  content: '\f27b'; }

.zmdi-star-outline:before {
  content: '\f27c'; }

.zmdi-star:before {
  content: '\f27d'; }

.zmdi-bluetooth-connected:before {
  content: '\f27e'; }

.zmdi-bluetooth-off:before {
  content: '\f27f'; }

.zmdi-bluetooth-search:before {
  content: '\f280'; }

.zmdi-bluetooth-setting:before {
  content: '\f281'; }

.zmdi-bluetooth:before {
  content: '\f282'; }

.zmdi-camera-add:before {
  content: '\f283'; }

.zmdi-camera-alt:before {
  content: '\f284'; }

.zmdi-camera-bw:before {
  content: '\f285'; }

.zmdi-camera-front:before {
  content: '\f286'; }

.zmdi-camera-mic:before {
  content: '\f287'; }

.zmdi-camera-party-mode:before {
  content: '\f288'; }

.zmdi-camera-rear:before {
  content: '\f289'; }

.zmdi-camera-roll:before {
  content: '\f28a'; }

.zmdi-camera-switch:before {
  content: '\f28b'; }

.zmdi-camera:before {
  content: '\f28c'; }

.zmdi-card-alert:before {
  content: '\f28d'; }

.zmdi-card-off:before {
  content: '\f28e'; }

.zmdi-card-sd:before {
  content: '\f28f'; }

.zmdi-card-sim:before {
  content: '\f290'; }

.zmdi-desktop-mac:before {
  content: '\f291'; }

.zmdi-desktop-windows:before {
  content: '\f292'; }

.zmdi-device-hub:before {
  content: '\f293'; }

.zmdi-devices-off:before {
  content: '\f294'; }

.zmdi-devices:before {
  content: '\f295'; }

.zmdi-dock:before {
  content: '\f296'; }

.zmdi-floppy:before {
  content: '\f297'; }

.zmdi-gamepad:before {
  content: '\f298'; }

.zmdi-gps-dot:before {
  content: '\f299'; }

.zmdi-gps-off:before {
  content: '\f29a'; }

.zmdi-gps:before {
  content: '\f29b'; }

.zmdi-headset-mic:before {
  content: '\f29c'; }

.zmdi-headset:before {
  content: '\f29d'; }

.zmdi-input-antenna:before {
  content: '\f29e'; }

.zmdi-input-composite:before {
  content: '\f29f'; }

.zmdi-input-hdmi:before {
  content: '\f2a0'; }

.zmdi-input-power:before {
  content: '\f2a1'; }

.zmdi-input-svideo:before {
  content: '\f2a2'; }

.zmdi-keyboard-hide:before {
  content: '\f2a3'; }

.zmdi-keyboard:before {
  content: '\f2a4'; }

.zmdi-laptop-chromebook:before {
  content: '\f2a5'; }

.zmdi-laptop-mac:before {
  content: '\f2a6'; }

.zmdi-laptop:before {
  content: '\f2a7'; }

.zmdi-mic-off:before {
  content: '\f2a8'; }

.zmdi-mic-outline:before {
  content: '\f2a9'; }

.zmdi-mic-setting:before {
  content: '\f2aa'; }

.zmdi-mic:before {
  content: '\f2ab'; }

.zmdi-mouse:before {
  content: '\f2ac'; }

.zmdi-network-alert:before {
  content: '\f2ad'; }

.zmdi-network-locked:before {
  content: '\f2ae'; }

.zmdi-network-off:before {
  content: '\f2af'; }

.zmdi-network-outline:before {
  content: '\f2b0'; }

.zmdi-network-setting:before {
  content: '\f2b1'; }

.zmdi-network:before {
  content: '\f2b2'; }

.zmdi-phone-bluetooth:before {
  content: '\f2b3'; }

.zmdi-phone-end:before {
  content: '\f2b4'; }

.zmdi-phone-forwarded:before {
  content: '\f2b5'; }

.zmdi-phone-in-talk:before {
  content: '\f2b6'; }

.zmdi-phone-locked:before {
  content: '\f2b7'; }

.zmdi-phone-missed:before {
  content: '\f2b8'; }

.zmdi-phone-msg:before {
  content: '\f2b9'; }

.zmdi-phone-paused:before {
  content: '\f2ba'; }

.zmdi-phone-ring:before {
  content: '\f2bb'; }

.zmdi-phone-setting:before {
  content: '\f2bc'; }

.zmdi-phone-sip:before {
  content: '\f2bd'; }

.zmdi-phone:before {
  content: '\f2be'; }

.zmdi-portable-wifi-changes:before {
  content: '\f2bf'; }

.zmdi-portable-wifi-off:before {
  content: '\f2c0'; }

.zmdi-portable-wifi:before {
  content: '\f2c1'; }

.zmdi-radio:before {
  content: '\f2c2'; }

.zmdi-reader:before {
  content: '\f2c3'; }

.zmdi-remote-control-alt:before {
  content: '\f2c4'; }

.zmdi-remote-control:before {
  content: '\f2c5'; }

.zmdi-router:before {
  content: '\f2c6'; }

.zmdi-scanner:before {
  content: '\f2c7'; }

.zmdi-smartphone-android:before {
  content: '\f2c8'; }

.zmdi-smartphone-download:before {
  content: '\f2c9'; }

.zmdi-smartphone-erase:before {
  content: '\f2ca'; }

.zmdi-smartphone-info:before {
  content: '\f2cb'; }

.zmdi-smartphone-iphone:before {
  content: '\f2cc'; }

.zmdi-smartphone-landscape-lock:before {
  content: '\f2cd'; }

.zmdi-smartphone-landscape:before {
  content: '\f2ce'; }

.zmdi-smartphone-lock:before {
  content: '\f2cf'; }

.zmdi-smartphone-portrait-lock:before {
  content: '\f2d0'; }

.zmdi-smartphone-ring:before {
  content: '\f2d1'; }

.zmdi-smartphone-setting:before {
  content: '\f2d2'; }

.zmdi-smartphone-setup:before {
  content: '\f2d3'; }

.zmdi-smartphone:before {
  content: '\f2d4'; }

.zmdi-speaker:before {
  content: '\f2d5'; }

.zmdi-tablet-android:before {
  content: '\f2d6'; }

.zmdi-tablet-mac:before {
  content: '\f2d7'; }

.zmdi-tablet:before {
  content: '\f2d8'; }

.zmdi-tv-alt-play:before {
  content: '\f2d9'; }

.zmdi-tv-list:before {
  content: '\f2da'; }

.zmdi-tv-play:before {
  content: '\f2db'; }

.zmdi-tv:before {
  content: '\f2dc'; }

.zmdi-usb:before {
  content: '\f2dd'; }

.zmdi-videocam-off:before {
  content: '\f2de'; }

.zmdi-videocam-switch:before {
  content: '\f2df'; }

.zmdi-videocam:before {
  content: '\f2e0'; }

.zmdi-watch:before {
  content: '\f2e1'; }

.zmdi-wifi-alt-2:before {
  content: '\f2e2'; }

.zmdi-wifi-alt:before {
  content: '\f2e3'; }

.zmdi-wifi-info:before {
  content: '\f2e4'; }

.zmdi-wifi-lock:before {
  content: '\f2e5'; }

.zmdi-wifi-off:before {
  content: '\f2e6'; }

.zmdi-wifi-outline:before {
  content: '\f2e7'; }

.zmdi-wifi:before {
  content: '\f2e8'; }

.zmdi-arrow-left-bottom:before {
  content: '\f2e9'; }

.zmdi-arrow-left:before {
  content: '\f2ea'; }

.zmdi-arrow-merge:before {
  content: '\f2eb'; }

.zmdi-arrow-missed:before {
  content: '\f2ec'; }

.zmdi-arrow-right-top:before {
  content: '\f2ed'; }

.zmdi-arrow-right:before {
  content: '\f2ee'; }

.zmdi-arrow-split:before {
  content: '\f2ef'; }

.zmdi-arrows:before {
  content: '\f2f0'; }

.zmdi-caret-down-circle:before {
  content: '\f2f1'; }

.zmdi-caret-down:before {
  content: '\f2f2'; }

.zmdi-caret-left-circle:before {
  content: '\f2f3'; }

.zmdi-caret-left:before {
  content: '\f2f4'; }

.zmdi-caret-right-circle:before {
  content: '\f2f5'; }

.zmdi-caret-right:before {
  content: '\f2f6'; }

.zmdi-caret-up-circle:before {
  content: '\f2f7'; }

.zmdi-caret-up:before {
  content: '\f2f8'; }

.zmdi-chevron-down:before {
  content: '\f2f9'; }

.zmdi-chevron-left:before {
  content: '\f2fa'; }

.zmdi-chevron-right:before {
  content: '\f2fb'; }

.zmdi-chevron-up:before {
  content: '\f2fc'; }

.zmdi-forward:before {
  content: '\f2fd'; }

.zmdi-long-arrow-down:before {
  content: '\f2fe'; }

.zmdi-long-arrow-left:before {
  content: '\f2ff'; }

.zmdi-long-arrow-return:before {
  content: '\f300'; }

.zmdi-long-arrow-right:before {
  content: '\f301'; }

.zmdi-long-arrow-tab:before {
  content: '\f302'; }

.zmdi-long-arrow-up:before {
  content: '\f303'; }

.zmdi-rotate-ccw:before {
  content: '\f304'; }

.zmdi-rotate-cw:before {
  content: '\f305'; }

.zmdi-rotate-left:before {
  content: '\f306'; }

.zmdi-rotate-right:before {
  content: '\f307'; }

.zmdi-square-down:before {
  content: '\f308'; }

.zmdi-square-right:before {
  content: '\f309'; }

.zmdi-swap-alt:before {
  content: '\f30a'; }

.zmdi-swap-vertical-circle:before {
  content: '\f30b'; }

.zmdi-swap-vertical:before {
  content: '\f30c'; }

.zmdi-swap:before {
  content: '\f30d'; }

.zmdi-trending-down:before {
  content: '\f30e'; }

.zmdi-trending-flat:before {
  content: '\f30f'; }

.zmdi-trending-up:before {
  content: '\f310'; }

.zmdi-unfold-less:before {
  content: '\f311'; }

.zmdi-unfold-more:before {
  content: '\f312'; }

.zmdi-apps:before {
  content: '\f313'; }

.zmdi-grid-off:before {
  content: '\f314'; }

.zmdi-grid:before {
  content: '\f315'; }

.zmdi-view-agenda:before {
  content: '\f316'; }

.zmdi-view-array:before {
  content: '\f317'; }

.zmdi-view-carousel:before {
  content: '\f318'; }

.zmdi-view-column:before {
  content: '\f319'; }

.zmdi-view-comfy:before {
  content: '\f31a'; }

.zmdi-view-compact:before {
  content: '\f31b'; }

.zmdi-view-dashboard:before {
  content: '\f31c'; }

.zmdi-view-day:before {
  content: '\f31d'; }

.zmdi-view-headline:before {
  content: '\f31e'; }

.zmdi-view-list-alt:before {
  content: '\f31f'; }

.zmdi-view-list:before {
  content: '\f320'; }

.zmdi-view-module:before {
  content: '\f321'; }

.zmdi-view-quilt:before {
  content: '\f322'; }

.zmdi-view-stream:before {
  content: '\f323'; }

.zmdi-view-subtitles:before {
  content: '\f324'; }

.zmdi-view-toc:before {
  content: '\f325'; }

.zmdi-view-web:before {
  content: '\f326'; }

.zmdi-view-week:before {
  content: '\f327'; }

.zmdi-widgets:before {
  content: '\f328'; }

.zmdi-alarm-check:before {
  content: '\f329'; }

.zmdi-alarm-off:before {
  content: '\f32a'; }

.zmdi-alarm-plus:before {
  content: '\f32b'; }

.zmdi-alarm-snooze:before {
  content: '\f32c'; }

.zmdi-alarm:before {
  content: '\f32d'; }

.zmdi-calendar-alt:before {
  content: '\f32e'; }

.zmdi-calendar-check:before {
  content: '\f32f'; }

.zmdi-calendar-close:before {
  content: '\f330'; }

.zmdi-calendar-note:before {
  content: '\f331'; }

.zmdi-calendar:before {
  content: '\f332'; }

.zmdi-time-countdown:before {
  content: '\f333'; }

.zmdi-time-interval:before {
  content: '\f334'; }

.zmdi-time-restore-setting:before {
  content: '\f335'; }

.zmdi-time-restore:before {
  content: '\f336'; }

.zmdi-time:before {
  content: '\f337'; }

.zmdi-timer-off:before {
  content: '\f338'; }

.zmdi-timer:before {
  content: '\f339'; }

.zmdi-android-alt:before {
  content: '\f33a'; }

.zmdi-android:before {
  content: '\f33b'; }

.zmdi-apple:before {
  content: '\f33c'; }

.zmdi-behance:before {
  content: '\f33d'; }

.zmdi-codepen:before {
  content: '\f33e'; }

.zmdi-dribbble:before {
  content: '\f33f'; }

.zmdi-dropbox:before {
  content: '\f340'; }

.zmdi-evernote:before {
  content: '\f341'; }

.zmdi-facebook-box:before {
  content: '\f342'; }

.zmdi-facebook:before {
  content: '\f343'; }

.zmdi-github-box:before {
  content: '\f344'; }

.zmdi-github:before {
  content: '\f345'; }

.zmdi-google-drive:before {
  content: '\f346'; }

.zmdi-google-earth:before {
  content: '\f347'; }

.zmdi-google-glass:before {
  content: '\f348'; }

.zmdi-google-maps:before {
  content: '\f349'; }

.zmdi-google-pages:before {
  content: '\f34a'; }

.zmdi-google-play:before {
  content: '\f34b'; }

.zmdi-google-plus-box:before {
  content: '\f34c'; }

.zmdi-google-plus:before {
  content: '\f34d'; }

.zmdi-google:before {
  content: '\f34e'; }

.zmdi-instagram:before {
  content: '\f34f'; }

.zmdi-language-css3:before {
  content: '\f350'; }

.zmdi-language-html5:before {
  content: '\f351'; }

.zmdi-language-javascript:before {
  content: '\f352'; }

.zmdi-language-python-alt:before {
  content: '\f353'; }

.zmdi-language-python:before {
  content: '\f354'; }

.zmdi-lastfm:before {
  content: '\f355'; }

.zmdi-linkedin-box:before {
  content: '\f356'; }

.zmdi-paypal:before {
  content: '\f357'; }

.zmdi-pinterest-box:before {
  content: '\f358'; }

.zmdi-pocket:before {
  content: '\f359'; }

.zmdi-polymer:before {
  content: '\f35a'; }

.zmdi-share:before {
  content: '\f35b'; }

.zmdi-stackoverflow:before {
  content: '\f35c'; }

.zmdi-steam-square:before {
  content: '\f35d'; }

.zmdi-steam:before {
  content: '\f35e'; }

.zmdi-twitter-box:before {
  content: '\f35f'; }

.zmdi-twitter:before {
  content: '\f360'; }

.zmdi-vk:before {
  content: '\f361'; }

.zmdi-wikipedia:before {
  content: '\f362'; }

.zmdi-windows:before {
  content: '\f363'; }

.zmdi-aspect-ratio-alt:before {
  content: '\f364'; }

.zmdi-aspect-ratio:before {
  content: '\f365'; }

.zmdi-blur-circular:before {
  content: '\f366'; }

.zmdi-blur-linear:before {
  content: '\f367'; }

.zmdi-blur-off:before {
  content: '\f368'; }

.zmdi-blur:before {
  content: '\f369'; }

.zmdi-brightness-2:before {
  content: '\f36a'; }

.zmdi-brightness-3:before {
  content: '\f36b'; }

.zmdi-brightness-4:before {
  content: '\f36c'; }

.zmdi-brightness-5:before {
  content: '\f36d'; }

.zmdi-brightness-6:before {
  content: '\f36e'; }

.zmdi-brightness-7:before {
  content: '\f36f'; }

.zmdi-brightness-auto:before {
  content: '\f370'; }

.zmdi-brightness-setting:before {
  content: '\f371'; }

.zmdi-broken-image:before {
  content: '\f372'; }

.zmdi-center-focus-strong:before {
  content: '\f373'; }

.zmdi-center-focus-weak:before {
  content: '\f374'; }

.zmdi-compare:before {
  content: '\f375'; }

.zmdi-crop-16-9:before {
  content: '\f376'; }

.zmdi-crop-3-2:before {
  content: '\f377'; }

.zmdi-crop-5-4:before {
  content: '\f378'; }

.zmdi-crop-7-5:before {
  content: '\f379'; }

.zmdi-crop-din:before {
  content: '\f37a'; }

.zmdi-crop-free:before {
  content: '\f37b'; }

.zmdi-crop-landscape:before {
  content: '\f37c'; }

.zmdi-crop-portrait:before {
  content: '\f37d'; }

.zmdi-crop-square:before {
  content: '\f37e'; }

.zmdi-exposure-alt:before {
  content: '\f37f'; }

.zmdi-exposure:before {
  content: '\f380'; }

.zmdi-filter-b-and-w:before {
  content: '\f381'; }

.zmdi-filter-center-focus:before {
  content: '\f382'; }

.zmdi-filter-frames:before {
  content: '\f383'; }

.zmdi-filter-tilt-shift:before {
  content: '\f384'; }

.zmdi-gradient:before {
  content: '\f385'; }

.zmdi-grain:before {
  content: '\f386'; }

.zmdi-graphic-eq:before {
  content: '\f387'; }

.zmdi-hdr-off:before {
  content: '\f388'; }

.zmdi-hdr-strong:before {
  content: '\f389'; }

.zmdi-hdr-weak:before {
  content: '\f38a'; }

.zmdi-hdr:before {
  content: '\f38b'; }

.zmdi-iridescent:before {
  content: '\f38c'; }

.zmdi-leak-off:before {
  content: '\f38d'; }

.zmdi-leak:before {
  content: '\f38e'; }

.zmdi-looks:before {
  content: '\f38f'; }

.zmdi-loupe:before {
  content: '\f390'; }

.zmdi-panorama-horizontal:before {
  content: '\f391'; }

.zmdi-panorama-vertical:before {
  content: '\f392'; }

.zmdi-panorama-wide-angle:before {
  content: '\f393'; }

.zmdi-photo-size-select-large:before {
  content: '\f394'; }

.zmdi-photo-size-select-small:before {
  content: '\f395'; }

.zmdi-picture-in-picture:before {
  content: '\f396'; }

.zmdi-slideshow:before {
  content: '\f397'; }

.zmdi-texture:before {
  content: '\f398'; }

.zmdi-tonality:before {
  content: '\f399'; }

.zmdi-vignette:before {
  content: '\f39a'; }

.zmdi-wb-auto:before {
  content: '\f39b'; }

.zmdi-eject-alt:before {
  content: '\f39c'; }

.zmdi-eject:before {
  content: '\f39d'; }

.zmdi-equalizer:before {
  content: '\f39e'; }

.zmdi-fast-forward:before {
  content: '\f39f'; }

.zmdi-fast-rewind:before {
  content: '\f3a0'; }

.zmdi-forward-10:before {
  content: '\f3a1'; }

.zmdi-forward-30:before {
  content: '\f3a2'; }

.zmdi-forward-5:before {
  content: '\f3a3'; }

.zmdi-hearing:before {
  content: '\f3a4'; }

.zmdi-pause-circle-outline:before {
  content: '\f3a5'; }

.zmdi-pause-circle:before {
  content: '\f3a6'; }

.zmdi-pause:before {
  content: '\f3a7'; }

.zmdi-play-circle-outline:before {
  content: '\f3a8'; }

.zmdi-play-circle:before {
  content: '\f3a9'; }

.zmdi-play:before {
  content: '\f3aa'; }

.zmdi-playlist-audio:before {
  content: '\f3ab'; }

.zmdi-playlist-plus:before {
  content: '\f3ac'; }

.zmdi-repeat-one:before {
  content: '\f3ad'; }

.zmdi-repeat:before {
  content: '\f3ae'; }

.zmdi-replay-10:before {
  content: '\f3af'; }

.zmdi-replay-30:before {
  content: '\f3b0'; }

.zmdi-replay-5:before {
  content: '\f3b1'; }

.zmdi-replay:before {
  content: '\f3b2'; }

.zmdi-shuffle:before {
  content: '\f3b3'; }

.zmdi-skip-next:before {
  content: '\f3b4'; }

.zmdi-skip-previous:before {
  content: '\f3b5'; }

.zmdi-stop:before {
  content: '\f3b6'; }

.zmdi-surround-sound:before {
  content: '\f3b7'; }

.zmdi-tune:before {
  content: '\f3b8'; }

.zmdi-volume-down:before {
  content: '\f3b9'; }

.zmdi-volume-mute:before {
  content: '\f3ba'; }

.zmdi-volume-off:before {
  content: '\f3bb'; }

.zmdi-volume-up:before {
  content: '\f3bc'; }

.zmdi-n-1-square:before {
  content: '\f3bd'; }

.zmdi-n-2-square:before {
  content: '\f3be'; }

.zmdi-n-3-square:before {
  content: '\f3bf'; }

.zmdi-n-4-square:before {
  content: '\f3c0'; }

.zmdi-n-5-square:before {
  content: '\f3c1'; }

.zmdi-n-6-square:before {
  content: '\f3c2'; }

.zmdi-neg-1:before {
  content: '\f3c3'; }

.zmdi-neg-2:before {
  content: '\f3c4'; }

.zmdi-plus-1:before {
  content: '\f3c5'; }

.zmdi-plus-2:before {
  content: '\f3c6'; }

.zmdi-sec-10:before {
  content: '\f3c7'; }

.zmdi-sec-3:before {
  content: '\f3c8'; }

.zmdi-zero:before {
  content: '\f3c9'; }

.zmdi-airline-seat-flat-angled:before {
  content: '\f3ca'; }

.zmdi-airline-seat-flat:before {
  content: '\f3cb'; }

.zmdi-airline-seat-individual-suite:before {
  content: '\f3cc'; }

.zmdi-airline-seat-legroom-extra:before {
  content: '\f3cd'; }

.zmdi-airline-seat-legroom-normal:before {
  content: '\f3ce'; }

.zmdi-airline-seat-legroom-reduced:before {
  content: '\f3cf'; }

.zmdi-airline-seat-recline-extra:before {
  content: '\f3d0'; }

.zmdi-airline-seat-recline-normal:before {
  content: '\f3d1'; }

.zmdi-airplay:before {
  content: '\f3d2'; }

.zmdi-closed-caption:before {
  content: '\f3d3'; }

.zmdi-confirmation-number:before {
  content: '\f3d4'; }

.zmdi-developer-board:before {
  content: '\f3d5'; }

.zmdi-disc-full:before {
  content: '\f3d6'; }

.zmdi-explicit:before {
  content: '\f3d7'; }

.zmdi-flight-land:before {
  content: '\f3d8'; }

.zmdi-flight-takeoff:before {
  content: '\f3d9'; }

.zmdi-flip-to-back:before {
  content: '\f3da'; }

.zmdi-flip-to-front:before {
  content: '\f3db'; }

.zmdi-group-work:before {
  content: '\f3dc'; }

.zmdi-hd:before {
  content: '\f3dd'; }

.zmdi-hq:before {
  content: '\f3de'; }

.zmdi-markunread-mailbox:before {
  content: '\f3df'; }

.zmdi-memory:before {
  content: '\f3e0'; }

.zmdi-nfc:before {
  content: '\f3e1'; }

.zmdi-play-for-work:before {
  content: '\f3e2'; }

.zmdi-power-input:before {
  content: '\f3e3'; }

.zmdi-present-to-all:before {
  content: '\f3e4'; }

.zmdi-satellite:before {
  content: '\f3e5'; }

.zmdi-tap-and-play:before {
  content: '\f3e6'; }

.zmdi-vibration:before {
  content: '\f3e7'; }

.zmdi-voicemail:before {
  content: '\f3e8'; }

.zmdi-group:before {
  content: '\f3e9'; }

.zmdi-rss:before {
  content: '\f3ea'; }

.zmdi-shape:before {
  content: '\f3eb'; }

.zmdi-spinner:before {
  content: '\f3ec'; }

.zmdi-ungroup:before {
  content: '\f3ed'; }

.zmdi-500px:before {
  content: '\f3ee'; }

.zmdi-8tracks:before {
  content: '\f3ef'; }

.zmdi-amazon:before {
  content: '\f3f0'; }

.zmdi-blogger:before {
  content: '\f3f1'; }

.zmdi-delicious:before {
  content: '\f3f2'; }

.zmdi-disqus:before {
  content: '\f3f3'; }

.zmdi-flattr:before {
  content: '\f3f4'; }

.zmdi-flickr:before {
  content: '\f3f5'; }

.zmdi-github-alt:before {
  content: '\f3f6'; }

.zmdi-google-old:before {
  content: '\f3f7'; }

.zmdi-linkedin:before {
  content: '\f3f8'; }

.zmdi-odnoklassniki:before {
  content: '\f3f9'; }

.zmdi-outlook:before {
  content: '\f3fa'; }

.zmdi-paypal-alt:before {
  content: '\f3fb'; }

.zmdi-pinterest:before {
  content: '\f3fc'; }

.zmdi-playstation:before {
  content: '\f3fd'; }

.zmdi-reddit:before {
  content: '\f3fe'; }

.zmdi-skype:before {
  content: '\f3ff'; }

.zmdi-slideshare:before {
  content: '\f400'; }

.zmdi-soundcloud:before {
  content: '\f401'; }

.zmdi-tumblr:before {
  content: '\f402'; }

.zmdi-twitch:before {
  content: '\f403'; }

.zmdi-vimeo:before {
  content: '\f404'; }

.zmdi-whatsapp:before {
  content: '\f405'; }

.zmdi-xbox:before {
  content: '\f406'; }

.zmdi-yahoo:before {
  content: '\f407'; }

.zmdi-youtube-play:before {
  content: '\f408'; }

.zmdi-youtube:before {
  content: '\f409'; }

.zmdi-3d-rotation:before {
  content: '\f101'; }

.zmdi-airplane-off:before {
  content: '\f102'; }

.zmdi-airplane:before {
  content: '\f103'; }

.zmdi-album:before {
  content: '\f104'; }

.zmdi-archive:before {
  content: '\f105'; }

.zmdi-assignment-account:before {
  content: '\f106'; }

.zmdi-assignment-alert:before {
  content: '\f107'; }

.zmdi-assignment-check:before {
  content: '\f108'; }

.zmdi-assignment-o:before {
  content: '\f109'; }

.zmdi-assignment-return:before {
  content: '\f10a'; }

.zmdi-assignment-returned:before {
  content: '\f10b'; }

.zmdi-assignment:before {
  content: '\f10c'; }

.zmdi-attachment-alt:before {
  content: '\f10d'; }

.zmdi-attachment:before {
  content: '\f10e'; }

.zmdi-audio:before {
  content: '\f10f'; }

.zmdi-badge-check:before {
  content: '\f110'; }

.zmdi-balance-wallet:before {
  content: '\f111'; }

.zmdi-balance:before {
  content: '\f112'; }

.zmdi-battery-alert:before {
  content: '\f113'; }

.zmdi-battery-flash:before {
  content: '\f114'; }

.zmdi-battery-unknown:before {
  content: '\f115'; }

.zmdi-battery:before {
  content: '\f116'; }

.zmdi-bike:before {
  content: '\f117'; }

.zmdi-block-alt:before {
  content: '\f118'; }

.zmdi-block:before {
  content: '\f119'; }

.zmdi-boat:before {
  content: '\f11a'; }

.zmdi-book-image:before {
  content: '\f11b'; }

.zmdi-book:before {
  content: '\f11c'; }

.zmdi-bookmark-outline:before {
  content: '\f11d'; }

.zmdi-bookmark:before {
  content: '\f11e'; }

.zmdi-brush:before {
  content: '\f11f'; }

.zmdi-bug:before {
  content: '\f120'; }

.zmdi-bus:before {
  content: '\f121'; }

.zmdi-cake:before {
  content: '\f122'; }

.zmdi-car-taxi:before {
  content: '\f123'; }

.zmdi-car-wash:before {
  content: '\f124'; }

.zmdi-car:before {
  content: '\f125'; }

.zmdi-card-giftcard:before {
  content: '\f126'; }

.zmdi-card-membership:before {
  content: '\f127'; }

.zmdi-card-travel:before {
  content: '\f128'; }

.zmdi-card:before {
  content: '\f129'; }

.zmdi-case-check:before {
  content: '\f12a'; }

.zmdi-case-download:before {
  content: '\f12b'; }

.zmdi-case-play:before {
  content: '\f12c'; }

.zmdi-case:before {
  content: '\f12d'; }

.zmdi-cast-connected:before {
  content: '\f12e'; }

.zmdi-cast:before {
  content: '\f12f'; }

.zmdi-chart-donut:before {
  content: '\f130'; }

.zmdi-chart:before {
  content: '\f131'; }

.zmdi-city-alt:before {
  content: '\f132'; }

.zmdi-city:before {
  content: '\f133'; }

.zmdi-close-circle-o:before {
  content: '\f134'; }

.zmdi-close-circle:before {
  content: '\f135'; }

.zmdi-close:before {
  content: '\f136'; }

.zmdi-cocktail:before {
  content: '\f137'; }

.zmdi-code-setting:before {
  content: '\f138'; }

.zmdi-code-smartphone:before {
  content: '\f139'; }

.zmdi-code:before {
  content: '\f13a'; }

.zmdi-coffee:before {
  content: '\f13b'; }

.zmdi-collection-bookmark:before {
  content: '\f13c'; }

.zmdi-collection-case-play:before {
  content: '\f13d'; }

.zmdi-collection-folder-image:before {
  content: '\f13e'; }

.zmdi-collection-image-o:before {
  content: '\f13f'; }

.zmdi-collection-image:before {
  content: '\f140'; }

.zmdi-collection-item-1:before {
  content: '\f141'; }

.zmdi-collection-item-2:before {
  content: '\f142'; }

.zmdi-collection-item-3:before {
  content: '\f143'; }

.zmdi-collection-item-4:before {
  content: '\f144'; }

.zmdi-collection-item-5:before {
  content: '\f145'; }

.zmdi-collection-item-6:before {
  content: '\f146'; }

.zmdi-collection-item-7:before {
  content: '\f147'; }

.zmdi-collection-item-8:before {
  content: '\f148'; }

.zmdi-collection-item-9-plus:before {
  content: '\f149'; }

.zmdi-collection-item-9:before {
  content: '\f14a'; }

.zmdi-collection-item:before {
  content: '\f14b'; }

.zmdi-collection-music:before {
  content: '\f14c'; }

.zmdi-collection-pdf:before {
  content: '\f14d'; }

.zmdi-collection-plus:before {
  content: '\f14e'; }

.zmdi-collection-speaker:before {
  content: '\f14f'; }

.zmdi-collection-text:before {
  content: '\f150'; }

.zmdi-collection-video:before {
  content: '\f151'; }

.zmdi-compass:before {
  content: '\f152'; }

.zmdi-cutlery:before {
  content: '\f153'; }

.zmdi-delete:before {
  content: '\f154'; }

.zmdi-dialpad:before {
  content: '\f155'; }

.zmdi-dns:before {
  content: '\f156'; }

.zmdi-drink:before {
  content: '\f157'; }

.zmdi-edit:before {
  content: '\f158'; }

.zmdi-email-open:before {
  content: '\f159'; }

.zmdi-email:before {
  content: '\f15a'; }

.zmdi-eye-off:before {
  content: '\f15b'; }

.zmdi-eye:before {
  content: '\f15c'; }

.zmdi-eyedropper:before {
  content: '\f15d'; }

.zmdi-favorite-outline:before {
  content: '\f15e'; }

.zmdi-favorite:before {
  content: '\f15f'; }

.zmdi-filter-list:before {
  content: '\f160'; }

.zmdi-fire:before {
  content: '\f161'; }

.zmdi-flag:before {
  content: '\f162'; }

.zmdi-flare:before {
  content: '\f163'; }

.zmdi-flash-auto:before {
  content: '\f164'; }

.zmdi-flash-off:before {
  content: '\f165'; }

.zmdi-flash:before {
  content: '\f166'; }

.zmdi-flip:before {
  content: '\f167'; }

.zmdi-flower-alt:before {
  content: '\f168'; }

.zmdi-flower:before {
  content: '\f169'; }

.zmdi-font:before {
  content: '\f16a'; }

.zmdi-fullscreen-alt:before {
  content: '\f16b'; }

.zmdi-fullscreen-exit:before {
  content: '\f16c'; }

.zmdi-fullscreen:before {
  content: '\f16d'; }

.zmdi-functions:before {
  content: '\f16e'; }

.zmdi-gas-station:before {
  content: '\f16f'; }

.zmdi-gesture:before {
  content: '\f170'; }

.zmdi-globe-alt:before {
  content: '\f171'; }

.zmdi-globe-lock:before {
  content: '\f172'; }

.zmdi-globe:before {
  content: '\f173'; }

.zmdi-graduation-cap:before {
  content: '\f174'; }

.zmdi-home:before {
  content: '\f175'; }

.zmdi-hospital-alt:before {
  content: '\f176'; }

.zmdi-hospital:before {
  content: '\f177'; }

.zmdi-hotel:before {
  content: '\f178'; }

.zmdi-hourglass-alt:before {
  content: '\f179'; }

.zmdi-hourglass-outline:before {
  content: '\f17a'; }

.zmdi-hourglass:before {
  content: '\f17b'; }

.zmdi-http:before {
  content: '\f17c'; }

.zmdi-image-alt:before {
  content: '\f17d'; }

.zmdi-image-o:before {
  content: '\f17e'; }

.zmdi-image:before {
  content: '\f17f'; }

.zmdi-inbox:before {
  content: '\f180'; }

.zmdi-invert-colors-off:before {
  content: '\f181'; }

.zmdi-invert-colors:before {
  content: '\f182'; }

.zmdi-key:before {
  content: '\f183'; }

.zmdi-label-alt-outline:before {
  content: '\f184'; }

.zmdi-label-alt:before {
  content: '\f185'; }

.zmdi-label-heart:before {
  content: '\f186'; }

.zmdi-label:before {
  content: '\f187'; }

.zmdi-labels:before {
  content: '\f188'; }

.zmdi-lamp:before {
  content: '\f189'; }

.zmdi-landscape:before {
  content: '\f18a'; }

.zmdi-layers-off:before {
  content: '\f18b'; }

.zmdi-layers:before {
  content: '\f18c'; }

.zmdi-library:before {
  content: '\f18d'; }

.zmdi-link:before {
  content: '\f18e'; }

.zmdi-lock-open:before {
  content: '\f18f'; }

.zmdi-lock-outline:before {
  content: '\f190'; }

.zmdi-lock:before {
  content: '\f191'; }

.zmdi-mail-reply-all:before {
  content: '\f192'; }

.zmdi-mail-reply:before {
  content: '\f193'; }

.zmdi-mail-send:before {
  content: '\f194'; }

.zmdi-mall:before {
  content: '\f195'; }

.zmdi-map:before {
  content: '\f196'; }

.zmdi-menu:before {
  content: '\f197'; }

.zmdi-money-box:before {
  content: '\f198'; }

.zmdi-money-off:before {
  content: '\f199'; }

.zmdi-money:before {
  content: '\f19a'; }

.zmdi-more-vert:before {
  content: '\f19b'; }

.zmdi-more:before {
  content: '\f19c'; }

.zmdi-movie-alt:before {
  content: '\f19d'; }

.zmdi-movie:before {
  content: '\f19e'; }

.zmdi-nature-people:before {
  content: '\f19f'; }

.zmdi-nature:before {
  content: '\f1a0'; }

.zmdi-navigation:before {
  content: '\f1a1'; }

.zmdi-open-in-browser:before {
  content: '\f1a2'; }

.zmdi-open-in-new:before {
  content: '\f1a3'; }

.zmdi-palette:before {
  content: '\f1a4'; }

.zmdi-parking:before {
  content: '\f1a5'; }

.zmdi-pin-account:before {
  content: '\f1a6'; }

.zmdi-pin-assistant:before {
  content: '\f1a7'; }

.zmdi-pin-drop:before {
  content: '\f1a8'; }

.zmdi-pin-help:before {
  content: '\f1a9'; }

.zmdi-pin-off:before {
  content: '\f1aa'; }

.zmdi-pin:before {
  content: '\f1ab'; }

.zmdi-pizza:before {
  content: '\f1ac'; }

.zmdi-plaster:before {
  content: '\f1ad'; }

.zmdi-power-setting:before {
  content: '\f1ae'; }

.zmdi-power:before {
  content: '\f1af'; }

.zmdi-print:before {
  content: '\f1b0'; }

.zmdi-puzzle-piece:before {
  content: '\f1b1'; }

.zmdi-quote:before {
  content: '\f1b2'; }

.zmdi-railway:before {
  content: '\f1b3'; }

.zmdi-receipt:before {
  content: '\f1b4'; }

.zmdi-refresh-alt:before {
  content: '\f1b5'; }

.zmdi-refresh-sync-alert:before {
  content: '\f1b6'; }

.zmdi-refresh-sync-off:before {
  content: '\f1b7'; }

.zmdi-refresh-sync:before {
  content: '\f1b8'; }

.zmdi-refresh:before {
  content: '\f1b9'; }

.zmdi-roller:before {
  content: '\f1ba'; }

.zmdi-ruler:before {
  content: '\f1bb'; }

.zmdi-scissors:before {
  content: '\f1bc'; }

.zmdi-screen-rotation-lock:before {
  content: '\f1bd'; }

.zmdi-screen-rotation:before {
  content: '\f1be'; }

.zmdi-search-for:before {
  content: '\f1bf'; }

.zmdi-search-in-file:before {
  content: '\f1c0'; }

.zmdi-search-in-page:before {
  content: '\f1c1'; }

.zmdi-search-replace:before {
  content: '\f1c2'; }

.zmdi-search:before {
  content: '\f1c3'; }

.zmdi-seat:before {
  content: '\f1c4'; }

.zmdi-settings-square:before {
  content: '\f1c5'; }

.zmdi-settings:before {
  content: '\f1c6'; }

.zmdi-shield-check:before {
  content: '\f1c7'; }

.zmdi-shield-security:before {
  content: '\f1c8'; }

.zmdi-shopping-basket:before {
  content: '\f1c9'; }

.zmdi-shopping-cart-plus:before {
  content: '\f1ca'; }

.zmdi-shopping-cart:before {
  content: '\f1cb'; }

.zmdi-sign-in:before {
  content: '\f1cc'; }

.zmdi-sort-amount-asc:before {
  content: '\f1cd'; }

.zmdi-sort-amount-desc:before {
  content: '\f1ce'; }

.zmdi-sort-asc:before {
  content: '\f1cf'; }

.zmdi-sort-desc:before {
  content: '\f1d0'; }

.zmdi-spellcheck:before {
  content: '\f1d1'; }

.zmdi-storage:before {
  content: '\f1d2'; }

.zmdi-store-24:before {
  content: '\f1d3'; }

.zmdi-store:before {
  content: '\f1d4'; }

.zmdi-subway:before {
  content: '\f1d5'; }

.zmdi-sun:before {
  content: '\f1d6'; }

.zmdi-tab-unselected:before {
  content: '\f1d7'; }

.zmdi-tab:before {
  content: '\f1d8'; }

.zmdi-tag-close:before {
  content: '\f1d9'; }

.zmdi-tag-more:before {
  content: '\f1da'; }

.zmdi-tag:before {
  content: '\f1db'; }

.zmdi-thumb-down:before {
  content: '\f1dc'; }

.zmdi-thumb-up-down:before {
  content: '\f1dd'; }

.zmdi-thumb-up:before {
  content: '\f1de'; }

.zmdi-ticket-star:before {
  content: '\f1df'; }

.zmdi-toll:before {
  content: '\f1e0'; }

.zmdi-toys:before {
  content: '\f1e1'; }

.zmdi-traffic:before {
  content: '\f1e2'; }

.zmdi-translate:before {
  content: '\f1e3'; }

.zmdi-triangle-down:before {
  content: '\f1e4'; }

.zmdi-triangle-up:before {
  content: '\f1e5'; }

.zmdi-truck:before {
  content: '\f1e6'; }

.zmdi-turning-sign:before {
  content: '\f1e7'; }

.zmdi-wallpaper:before {
  content: '\f1e8'; }

.zmdi-washing-machine:before {
  content: '\f1e9'; }

.zmdi-window-maximize:before {
  content: '\f1ea'; }

.zmdi-window-minimize:before {
  content: '\f1eb'; }

.zmdi-window-restore:before {
  content: '\f1ec'; }

.zmdi-wrench:before {
  content: '\f1ed'; }

.zmdi-zoom-in:before {
  content: '\f1ee'; }

.zmdi-zoom-out:before {
  content: '\f1ef'; }

.zmdi-alert-circle-o:before {
  content: '\f1f0'; }

.zmdi-alert-circle:before {
  content: '\f1f1'; }

.zmdi-alert-octagon:before {
  content: '\f1f2'; }

.zmdi-alert-polygon:before {
  content: '\f1f3'; }

.zmdi-alert-triangle:before {
  content: '\f1f4'; }

.zmdi-help-outline:before {
  content: '\f1f5'; }

.zmdi-help:before {
  content: '\f1f6'; }

.zmdi-info-outline:before {
  content: '\f1f7'; }

.zmdi-info:before {
  content: '\f1f8'; }

.zmdi-notifications-active:before {
  content: '\f1f9'; }

.zmdi-notifications-add:before {
  content: '\f1fa'; }

.zmdi-notifications-none:before {
  content: '\f1fb'; }

.zmdi-notifications-off:before {
  content: '\f1fc'; }

.zmdi-notifications-paused:before {
  content: '\f1fd'; }

.zmdi-notifications:before {
  content: '\f1fe'; }

.zmdi-account-add:before {
  content: '\f1ff'; }

.zmdi-account-box-mail:before {
  content: '\f200'; }

.zmdi-account-box-o:before {
  content: '\f201'; }

.zmdi-account-box-phone:before {
  content: '\f202'; }

.zmdi-account-box:before {
  content: '\f203'; }

.zmdi-account-calendar:before {
  content: '\f204'; }

.zmdi-account-circle:before {
  content: '\f205'; }

.zmdi-account-o:before {
  content: '\f206'; }

.zmdi-account:before {
  content: '\f207'; }

.zmdi-accounts-add:before {
  content: '\f208'; }

.zmdi-accounts-alt:before {
  content: '\f209'; }

.zmdi-accounts-list-alt:before {
  content: '\f20a'; }

.zmdi-accounts-list:before {
  content: '\f20b'; }

.zmdi-accounts-outline:before {
  content: '\f20c'; }

.zmdi-accounts:before {
  content: '\f20d'; }

.zmdi-face:before {
  content: '\f20e'; }

.zmdi-female:before {
  content: '\f20f'; }

.zmdi-male-alt:before {
  content: '\f210'; }

.zmdi-male-female:before {
  content: '\f211'; }

.zmdi-male:before {
  content: '\f212'; }

.zmdi-mood-bad:before {
  content: '\f213'; }

.zmdi-mood:before {
  content: '\f214'; }

.zmdi-run:before {
  content: '\f215'; }

.zmdi-walk:before {
  content: '\f216'; }

.zmdi-cloud-box:before {
  content: '\f217'; }

.zmdi-cloud-circle:before {
  content: '\f218'; }

.zmdi-cloud-done:before {
  content: '\f219'; }

.zmdi-cloud-download:before {
  content: '\f21a'; }

.zmdi-cloud-off:before {
  content: '\f21b'; }

.zmdi-cloud-outline-alt:before {
  content: '\f21c'; }

.zmdi-cloud-outline:before {
  content: '\f21d'; }

.zmdi-cloud-upload:before {
  content: '\f21e'; }

.zmdi-cloud:before {
  content: '\f21f'; }

.zmdi-download:before {
  content: '\f220'; }

.zmdi-file-plus:before {
  content: '\f221'; }

.zmdi-file-text:before {
  content: '\f222'; }

.zmdi-file:before {
  content: '\f223'; }

.zmdi-folder-outline:before {
  content: '\f224'; }

.zmdi-folder-person:before {
  content: '\f225'; }

.zmdi-folder-star-alt:before {
  content: '\f226'; }

.zmdi-folder-star:before {
  content: '\f227'; }

.zmdi-folder:before {
  content: '\f228'; }

.zmdi-gif:before {
  content: '\f229'; }

.zmdi-upload:before {
  content: '\f22a'; }

.zmdi-border-all:before {
  content: '\f22b'; }

.zmdi-border-bottom:before {
  content: '\f22c'; }

.zmdi-border-clear:before {
  content: '\f22d'; }

.zmdi-border-color:before {
  content: '\f22e'; }

.zmdi-border-horizontal:before {
  content: '\f22f'; }

.zmdi-border-inner:before {
  content: '\f230'; }

.zmdi-border-left:before {
  content: '\f231'; }

.zmdi-border-outer:before {
  content: '\f232'; }

.zmdi-border-right:before {
  content: '\f233'; }

.zmdi-border-style:before {
  content: '\f234'; }

.zmdi-border-top:before {
  content: '\f235'; }

.zmdi-border-vertical:before {
  content: '\f236'; }

.zmdi-copy:before {
  content: '\f237'; }

.zmdi-crop:before {
  content: '\f238'; }

.zmdi-format-align-center:before {
  content: '\f239'; }

.zmdi-format-align-justify:before {
  content: '\f23a'; }

.zmdi-format-align-left:before {
  content: '\f23b'; }

.zmdi-format-align-right:before {
  content: '\f23c'; }

.zmdi-format-bold:before {
  content: '\f23d'; }

.zmdi-format-clear-all:before {
  content: '\f23e'; }

.zmdi-format-clear:before {
  content: '\f23f'; }

.zmdi-format-color-fill:before {
  content: '\f240'; }

.zmdi-format-color-reset:before {
  content: '\f241'; }

.zmdi-format-color-text:before {
  content: '\f242'; }

.zmdi-format-indent-decrease:before {
  content: '\f243'; }

.zmdi-format-indent-increase:before {
  content: '\f244'; }

.zmdi-format-italic:before {
  content: '\f245'; }

.zmdi-format-line-spacing:before {
  content: '\f246'; }

.zmdi-format-list-bulleted:before {
  content: '\f247'; }

.zmdi-format-list-numbered:before {
  content: '\f248'; }

.zmdi-format-ltr:before {
  content: '\f249'; }

.zmdi-format-rtl:before {
  content: '\f24a'; }

.zmdi-format-size:before {
  content: '\f24b'; }

.zmdi-format-strikethrough-s:before {
  content: '\f24c'; }

.zmdi-format-strikethrough:before {
  content: '\f24d'; }

.zmdi-format-subject:before {
  content: '\f24e'; }

.zmdi-format-underlined:before {
  content: '\f24f'; }

.zmdi-format-valign-bottom:before {
  content: '\f250'; }

.zmdi-format-valign-center:before {
  content: '\f251'; }

.zmdi-format-valign-top:before {
  content: '\f252'; }

.zmdi-redo:before {
  content: '\f253'; }

.zmdi-select-all:before {
  content: '\f254'; }

.zmdi-space-bar:before {
  content: '\f255'; }

.zmdi-text-format:before {
  content: '\f256'; }

.zmdi-transform:before {
  content: '\f257'; }

.zmdi-undo:before {
  content: '\f258'; }

.zmdi-wrap-text:before {
  content: '\f259'; }

.zmdi-comment-alert:before {
  content: '\f25a'; }

.zmdi-comment-alt-text:before {
  content: '\f25b'; }

.zmdi-comment-alt:before {
  content: '\f25c'; }

.zmdi-comment-edit:before {
  content: '\f25d'; }

.zmdi-comment-image:before {
  content: '\f25e'; }

.zmdi-comment-list:before {
  content: '\f25f'; }

.zmdi-comment-more:before {
  content: '\f260'; }

.zmdi-comment-outline:before {
  content: '\f261'; }

.zmdi-comment-text-alt:before {
  content: '\f262'; }

.zmdi-comment-text:before {
  content: '\f263'; }

.zmdi-comment-video:before {
  content: '\f264'; }

.zmdi-comment:before {
  content: '\f265'; }

.zmdi-comments:before {
  content: '\f266'; }

.zmdi-check-all:before {
  content: '\f267'; }

.zmdi-check-circle-u:before {
  content: '\f268'; }

.zmdi-check-circle:before {
  content: '\f269'; }

.zmdi-check-square:before {
  content: '\f26a'; }

.zmdi-check:before {
  content: '\f26b'; }

.zmdi-circle-o:before {
  content: '\f26c'; }

.zmdi-circle:before {
  content: '\f26d'; }

.zmdi-dot-circle-alt:before {
  content: '\f26e'; }

.zmdi-dot-circle:before {
  content: '\f26f'; }

.zmdi-minus-circle-outline:before {
  content: '\f270'; }

.zmdi-minus-circle:before {
  content: '\f271'; }

.zmdi-minus-square:before {
  content: '\f272'; }

.zmdi-minus:before {
  content: '\f273'; }

.zmdi-plus-circle-o-duplicate:before {
  content: '\f274'; }

.zmdi-plus-circle-o:before {
  content: '\f275'; }

.zmdi-plus-circle:before {
  content: '\f276'; }

.zmdi-plus-square:before {
  content: '\f277'; }

.zmdi-plus:before {
  content: '\f278'; }

.zmdi-square-o:before {
  content: '\f279'; }

.zmdi-star-circle:before {
  content: '\f27a'; }

.zmdi-star-half:before {
  content: '\f27b'; }

.zmdi-star-outline:before {
  content: '\f27c'; }

.zmdi-star:before {
  content: '\f27d'; }

.zmdi-bluetooth-connected:before {
  content: '\f27e'; }

.zmdi-bluetooth-off:before {
  content: '\f27f'; }

.zmdi-bluetooth-search:before {
  content: '\f280'; }

.zmdi-bluetooth-setting:before {
  content: '\f281'; }

.zmdi-bluetooth:before {
  content: '\f282'; }

.zmdi-camera-add:before {
  content: '\f283'; }

.zmdi-camera-alt:before {
  content: '\f284'; }

.zmdi-camera-bw:before {
  content: '\f285'; }

.zmdi-camera-front:before {
  content: '\f286'; }

.zmdi-camera-mic:before {
  content: '\f287'; }

.zmdi-camera-party-mode:before {
  content: '\f288'; }

.zmdi-camera-rear:before {
  content: '\f289'; }

.zmdi-camera-roll:before {
  content: '\f28a'; }

.zmdi-camera-switch:before {
  content: '\f28b'; }

.zmdi-camera:before {
  content: '\f28c'; }

.zmdi-card-alert:before {
  content: '\f28d'; }

.zmdi-card-off:before {
  content: '\f28e'; }

.zmdi-card-sd:before {
  content: '\f28f'; }

.zmdi-card-sim:before {
  content: '\f290'; }

.zmdi-desktop-mac:before {
  content: '\f291'; }

.zmdi-desktop-windows:before {
  content: '\f292'; }

.zmdi-device-hub:before {
  content: '\f293'; }

.zmdi-devices-off:before {
  content: '\f294'; }

.zmdi-devices:before {
  content: '\f295'; }

.zmdi-dock:before {
  content: '\f296'; }

.zmdi-floppy:before {
  content: '\f297'; }

.zmdi-gamepad:before {
  content: '\f298'; }

.zmdi-gps-dot:before {
  content: '\f299'; }

.zmdi-gps-off:before {
  content: '\f29a'; }

.zmdi-gps:before {
  content: '\f29b'; }

.zmdi-headset-mic:before {
  content: '\f29c'; }

.zmdi-headset:before {
  content: '\f29d'; }

.zmdi-input-antenna:before {
  content: '\f29e'; }

.zmdi-input-composite:before {
  content: '\f29f'; }

.zmdi-input-hdmi:before {
  content: '\f2a0'; }

.zmdi-input-power:before {
  content: '\f2a1'; }

.zmdi-input-svideo:before {
  content: '\f2a2'; }

.zmdi-keyboard-hide:before {
  content: '\f2a3'; }

.zmdi-keyboard:before {
  content: '\f2a4'; }

.zmdi-laptop-chromebook:before {
  content: '\f2a5'; }

.zmdi-laptop-mac:before {
  content: '\f2a6'; }

.zmdi-laptop:before {
  content: '\f2a7'; }

.zmdi-mic-off:before {
  content: '\f2a8'; }

.zmdi-mic-outline:before {
  content: '\f2a9'; }

.zmdi-mic-setting:before {
  content: '\f2aa'; }

.zmdi-mic:before {
  content: '\f2ab'; }

.zmdi-mouse:before {
  content: '\f2ac'; }

.zmdi-network-alert:before {
  content: '\f2ad'; }

.zmdi-network-locked:before {
  content: '\f2ae'; }

.zmdi-network-off:before {
  content: '\f2af'; }

.zmdi-network-outline:before {
  content: '\f2b0'; }

.zmdi-network-setting:before {
  content: '\f2b1'; }

.zmdi-network:before {
  content: '\f2b2'; }

.zmdi-phone-bluetooth:before {
  content: '\f2b3'; }

.zmdi-phone-end:before {
  content: '\f2b4'; }

.zmdi-phone-forwarded:before {
  content: '\f2b5'; }

.zmdi-phone-in-talk:before {
  content: '\f2b6'; }

.zmdi-phone-locked:before {
  content: '\f2b7'; }

.zmdi-phone-missed:before {
  content: '\f2b8'; }

.zmdi-phone-msg:before {
  content: '\f2b9'; }

.zmdi-phone-paused:before {
  content: '\f2ba'; }

.zmdi-phone-ring:before {
  content: '\f2bb'; }

.zmdi-phone-setting:before {
  content: '\f2bc'; }

.zmdi-phone-sip:before {
  content: '\f2bd'; }

.zmdi-phone:before {
  content: '\f2be'; }

.zmdi-portable-wifi-changes:before {
  content: '\f2bf'; }

.zmdi-portable-wifi-off:before {
  content: '\f2c0'; }

.zmdi-portable-wifi:before {
  content: '\f2c1'; }

.zmdi-radio:before {
  content: '\f2c2'; }

.zmdi-reader:before {
  content: '\f2c3'; }

.zmdi-remote-control-alt:before {
  content: '\f2c4'; }

.zmdi-remote-control:before {
  content: '\f2c5'; }

.zmdi-router:before {
  content: '\f2c6'; }

.zmdi-scanner:before {
  content: '\f2c7'; }

.zmdi-smartphone-android:before {
  content: '\f2c8'; }

.zmdi-smartphone-download:before {
  content: '\f2c9'; }

.zmdi-smartphone-erase:before {
  content: '\f2ca'; }

.zmdi-smartphone-info:before {
  content: '\f2cb'; }

.zmdi-smartphone-iphone:before {
  content: '\f2cc'; }

.zmdi-smartphone-landscape-lock:before {
  content: '\f2cd'; }

.zmdi-smartphone-landscape:before {
  content: '\f2ce'; }

.zmdi-smartphone-lock:before {
  content: '\f2cf'; }

.zmdi-smartphone-portrait-lock:before {
  content: '\f2d0'; }

.zmdi-smartphone-ring:before {
  content: '\f2d1'; }

.zmdi-smartphone-setting:before {
  content: '\f2d2'; }

.zmdi-smartphone-setup:before {
  content: '\f2d3'; }

.zmdi-smartphone:before {
  content: '\f2d4'; }

.zmdi-speaker:before {
  content: '\f2d5'; }

.zmdi-tablet-android:before {
  content: '\f2d6'; }

.zmdi-tablet-mac:before {
  content: '\f2d7'; }

.zmdi-tablet:before {
  content: '\f2d8'; }

.zmdi-tv-alt-play:before {
  content: '\f2d9'; }

.zmdi-tv-list:before {
  content: '\f2da'; }

.zmdi-tv-play:before {
  content: '\f2db'; }

.zmdi-tv:before {
  content: '\f2dc'; }

.zmdi-usb:before {
  content: '\f2dd'; }

.zmdi-videocam-off:before {
  content: '\f2de'; }

.zmdi-videocam-switch:before {
  content: '\f2df'; }

.zmdi-videocam:before {
  content: '\f2e0'; }

.zmdi-watch:before {
  content: '\f2e1'; }

.zmdi-wifi-alt-2:before {
  content: '\f2e2'; }

.zmdi-wifi-alt:before {
  content: '\f2e3'; }

.zmdi-wifi-info:before {
  content: '\f2e4'; }

.zmdi-wifi-lock:before {
  content: '\f2e5'; }

.zmdi-wifi-off:before {
  content: '\f2e6'; }

.zmdi-wifi-outline:before {
  content: '\f2e7'; }

.zmdi-wifi:before {
  content: '\f2e8'; }

.zmdi-arrow-left-bottom:before {
  content: '\f2e9'; }

.zmdi-arrow-left:before {
  content: '\f2ea'; }

.zmdi-arrow-merge:before {
  content: '\f2eb'; }

.zmdi-arrow-missed:before {
  content: '\f2ec'; }

.zmdi-arrow-right-top:before {
  content: '\f2ed'; }

.zmdi-arrow-right:before {
  content: '\f2ee'; }

.zmdi-arrow-split:before {
  content: '\f2ef'; }

.zmdi-arrows:before {
  content: '\f2f0'; }

.zmdi-caret-down-circle:before {
  content: '\f2f1'; }

.zmdi-caret-down:before {
  content: '\f2f2'; }

.zmdi-caret-left-circle:before {
  content: '\f2f3'; }

.zmdi-caret-left:before {
  content: '\f2f4'; }

.zmdi-caret-right-circle:before {
  content: '\f2f5'; }

.zmdi-caret-right:before {
  content: '\f2f6'; }

.zmdi-caret-up-circle:before {
  content: '\f2f7'; }

.zmdi-caret-up:before {
  content: '\f2f8'; }

.zmdi-chevron-down:before {
  content: '\f2f9'; }

.zmdi-chevron-left:before {
  content: '\f2fa'; }

.zmdi-chevron-right:before {
  content: '\f2fb'; }

.zmdi-chevron-up:before {
  content: '\f2fc'; }

.zmdi-forward:before {
  content: '\f2fd'; }

.zmdi-long-arrow-down:before {
  content: '\f2fe'; }

.zmdi-long-arrow-left:before {
  content: '\f2ff'; }

.zmdi-long-arrow-return:before {
  content: '\f300'; }

.zmdi-long-arrow-right:before {
  content: '\f301'; }

.zmdi-long-arrow-tab:before {
  content: '\f302'; }

.zmdi-long-arrow-up:before {
  content: '\f303'; }

.zmdi-rotate-ccw:before {
  content: '\f304'; }

.zmdi-rotate-cw:before {
  content: '\f305'; }

.zmdi-rotate-left:before {
  content: '\f306'; }

.zmdi-rotate-right:before {
  content: '\f307'; }

.zmdi-square-down:before {
  content: '\f308'; }

.zmdi-square-right:before {
  content: '\f309'; }

.zmdi-swap-alt:before {
  content: '\f30a'; }

.zmdi-swap-vertical-circle:before {
  content: '\f30b'; }

.zmdi-swap-vertical:before {
  content: '\f30c'; }

.zmdi-swap:before {
  content: '\f30d'; }

.zmdi-trending-down:before {
  content: '\f30e'; }

.zmdi-trending-flat:before {
  content: '\f30f'; }

.zmdi-trending-up:before {
  content: '\f310'; }

.zmdi-unfold-less:before {
  content: '\f311'; }

.zmdi-unfold-more:before {
  content: '\f312'; }

.zmdi-apps:before {
  content: '\f313'; }

.zmdi-grid-off:before {
  content: '\f314'; }

.zmdi-grid:before {
  content: '\f315'; }

.zmdi-view-agenda:before {
  content: '\f316'; }

.zmdi-view-array:before {
  content: '\f317'; }

.zmdi-view-carousel:before {
  content: '\f318'; }

.zmdi-view-column:before {
  content: '\f319'; }

.zmdi-view-comfy:before {
  content: '\f31a'; }

.zmdi-view-compact:before {
  content: '\f31b'; }

.zmdi-view-dashboard:before {
  content: '\f31c'; }

.zmdi-view-day:before {
  content: '\f31d'; }

.zmdi-view-headline:before {
  content: '\f31e'; }

.zmdi-view-list-alt:before {
  content: '\f31f'; }

.zmdi-view-list:before {
  content: '\f320'; }

.zmdi-view-module:before {
  content: '\f321'; }

.zmdi-view-quilt:before {
  content: '\f322'; }

.zmdi-view-stream:before {
  content: '\f323'; }

.zmdi-view-subtitles:before {
  content: '\f324'; }

.zmdi-view-toc:before {
  content: '\f325'; }

.zmdi-view-web:before {
  content: '\f326'; }

.zmdi-view-week:before {
  content: '\f327'; }

.zmdi-widgets:before {
  content: '\f328'; }

.zmdi-alarm-check:before {
  content: '\f329'; }

.zmdi-alarm-off:before {
  content: '\f32a'; }

.zmdi-alarm-plus:before {
  content: '\f32b'; }

.zmdi-alarm-snooze:before {
  content: '\f32c'; }

.zmdi-alarm:before {
  content: '\f32d'; }

.zmdi-calendar-alt:before {
  content: '\f32e'; }

.zmdi-calendar-check:before {
  content: '\f32f'; }

.zmdi-calendar-close:before {
  content: '\f330'; }

.zmdi-calendar-note:before {
  content: '\f331'; }

.zmdi-calendar:before {
  content: '\f332'; }

.zmdi-time-countdown:before {
  content: '\f333'; }

.zmdi-time-interval:before {
  content: '\f334'; }

.zmdi-time-restore-setting:before {
  content: '\f335'; }

.zmdi-time-restore:before {
  content: '\f336'; }

.zmdi-time:before {
  content: '\f337'; }

.zmdi-timer-off:before {
  content: '\f338'; }

.zmdi-timer:before {
  content: '\f339'; }

.zmdi-android-alt:before {
  content: '\f33a'; }

.zmdi-android:before {
  content: '\f33b'; }

.zmdi-apple:before {
  content: '\f33c'; }

.zmdi-behance:before {
  content: '\f33d'; }

.zmdi-codepen:before {
  content: '\f33e'; }

.zmdi-dribbble:before {
  content: '\f33f'; }

.zmdi-dropbox:before {
  content: '\f340'; }

.zmdi-evernote:before {
  content: '\f341'; }

.zmdi-facebook-box:before {
  content: '\f342'; }

.zmdi-facebook:before {
  content: '\f343'; }

.zmdi-github-box:before {
  content: '\f344'; }

.zmdi-github:before {
  content: '\f345'; }

.zmdi-google-drive:before {
  content: '\f346'; }

.zmdi-google-earth:before {
  content: '\f347'; }

.zmdi-google-glass:before {
  content: '\f348'; }

.zmdi-google-maps:before {
  content: '\f349'; }

.zmdi-google-pages:before {
  content: '\f34a'; }

.zmdi-google-play:before {
  content: '\f34b'; }

.zmdi-google-plus-box:before {
  content: '\f34c'; }

.zmdi-google-plus:before {
  content: '\f34d'; }

.zmdi-google:before {
  content: '\f34e'; }

.zmdi-instagram:before {
  content: '\f34f'; }

.zmdi-language-css3:before {
  content: '\f350'; }

.zmdi-language-html5:before {
  content: '\f351'; }

.zmdi-language-javascript:before {
  content: '\f352'; }

.zmdi-language-python-alt:before {
  content: '\f353'; }

.zmdi-language-python:before {
  content: '\f354'; }

.zmdi-lastfm:before {
  content: '\f355'; }

.zmdi-linkedin-box:before {
  content: '\f356'; }

.zmdi-paypal:before {
  content: '\f357'; }

.zmdi-pinterest-box:before {
  content: '\f358'; }

.zmdi-pocket:before {
  content: '\f359'; }

.zmdi-polymer:before {
  content: '\f35a'; }

.zmdi-share:before {
  content: '\f35b'; }

.zmdi-stackoverflow:before {
  content: '\f35c'; }

.zmdi-steam-square:before {
  content: '\f35d'; }

.zmdi-steam:before {
  content: '\f35e'; }

.zmdi-twitter-box:before {
  content: '\f35f'; }

.zmdi-twitter:before {
  content: '\f360'; }

.zmdi-vk:before {
  content: '\f361'; }

.zmdi-wikipedia:before {
  content: '\f362'; }

.zmdi-windows:before {
  content: '\f363'; }

.zmdi-aspect-ratio-alt:before {
  content: '\f364'; }

.zmdi-aspect-ratio:before {
  content: '\f365'; }

.zmdi-blur-circular:before {
  content: '\f366'; }

.zmdi-blur-linear:before {
  content: '\f367'; }

.zmdi-blur-off:before {
  content: '\f368'; }

.zmdi-blur:before {
  content: '\f369'; }

.zmdi-brightness-2:before {
  content: '\f36a'; }

.zmdi-brightness-3:before {
  content: '\f36b'; }

.zmdi-brightness-4:before {
  content: '\f36c'; }

.zmdi-brightness-5:before {
  content: '\f36d'; }

.zmdi-brightness-6:before {
  content: '\f36e'; }

.zmdi-brightness-7:before {
  content: '\f36f'; }

.zmdi-brightness-auto:before {
  content: '\f370'; }

.zmdi-brightness-setting:before {
  content: '\f371'; }

.zmdi-broken-image:before {
  content: '\f372'; }

.zmdi-center-focus-strong:before {
  content: '\f373'; }

.zmdi-center-focus-weak:before {
  content: '\f374'; }

.zmdi-compare:before {
  content: '\f375'; }

.zmdi-crop-16-9:before {
  content: '\f376'; }

.zmdi-crop-3-2:before {
  content: '\f377'; }

.zmdi-crop-5-4:before {
  content: '\f378'; }

.zmdi-crop-7-5:before {
  content: '\f379'; }

.zmdi-crop-din:before {
  content: '\f37a'; }

.zmdi-crop-free:before {
  content: '\f37b'; }

.zmdi-crop-landscape:before {
  content: '\f37c'; }

.zmdi-crop-portrait:before {
  content: '\f37d'; }

.zmdi-crop-square:before {
  content: '\f37e'; }

.zmdi-exposure-alt:before {
  content: '\f37f'; }

.zmdi-exposure:before {
  content: '\f380'; }

.zmdi-filter-b-and-w:before {
  content: '\f381'; }

.zmdi-filter-center-focus:before {
  content: '\f382'; }

.zmdi-filter-frames:before {
  content: '\f383'; }

.zmdi-filter-tilt-shift:before {
  content: '\f384'; }

.zmdi-gradient:before {
  content: '\f385'; }

.zmdi-grain:before {
  content: '\f386'; }

.zmdi-graphic-eq:before {
  content: '\f387'; }

.zmdi-hdr-off:before {
  content: '\f388'; }

.zmdi-hdr-strong:before {
  content: '\f389'; }

.zmdi-hdr-weak:before {
  content: '\f38a'; }

.zmdi-hdr:before {
  content: '\f38b'; }

.zmdi-iridescent:before {
  content: '\f38c'; }

.zmdi-leak-off:before {
  content: '\f38d'; }

.zmdi-leak:before {
  content: '\f38e'; }

.zmdi-looks:before {
  content: '\f38f'; }

.zmdi-loupe:before {
  content: '\f390'; }

.zmdi-panorama-horizontal:before {
  content: '\f391'; }

.zmdi-panorama-vertical:before {
  content: '\f392'; }

.zmdi-panorama-wide-angle:before {
  content: '\f393'; }

.zmdi-photo-size-select-large:before {
  content: '\f394'; }

.zmdi-photo-size-select-small:before {
  content: '\f395'; }

.zmdi-picture-in-picture:before {
  content: '\f396'; }

.zmdi-slideshow:before {
  content: '\f397'; }

.zmdi-texture:before {
  content: '\f398'; }

.zmdi-tonality:before {
  content: '\f399'; }

.zmdi-vignette:before {
  content: '\f39a'; }

.zmdi-wb-auto:before {
  content: '\f39b'; }

.zmdi-eject-alt:before {
  content: '\f39c'; }

.zmdi-eject:before {
  content: '\f39d'; }

.zmdi-equalizer:before {
  content: '\f39e'; }

.zmdi-fast-forward:before {
  content: '\f39f'; }

.zmdi-fast-rewind:before {
  content: '\f3a0'; }

.zmdi-forward-10:before {
  content: '\f3a1'; }

.zmdi-forward-30:before {
  content: '\f3a2'; }

.zmdi-forward-5:before {
  content: '\f3a3'; }

.zmdi-hearing:before {
  content: '\f3a4'; }

.zmdi-pause-circle-outline:before {
  content: '\f3a5'; }

.zmdi-pause-circle:before {
  content: '\f3a6'; }

.zmdi-pause:before {
  content: '\f3a7'; }

.zmdi-play-circle-outline:before {
  content: '\f3a8'; }

.zmdi-play-circle:before {
  content: '\f3a9'; }

.zmdi-play:before {
  content: '\f3aa'; }

.zmdi-playlist-audio:before {
  content: '\f3ab'; }

.zmdi-playlist-plus:before {
  content: '\f3ac'; }

.zmdi-repeat-one:before {
  content: '\f3ad'; }

.zmdi-repeat:before {
  content: '\f3ae'; }

.zmdi-replay-10:before {
  content: '\f3af'; }

.zmdi-replay-30:before {
  content: '\f3b0'; }

.zmdi-replay-5:before {
  content: '\f3b1'; }

.zmdi-replay:before {
  content: '\f3b2'; }

.zmdi-shuffle:before {
  content: '\f3b3'; }

.zmdi-skip-next:before {
  content: '\f3b4'; }

.zmdi-skip-previous:before {
  content: '\f3b5'; }

.zmdi-stop:before {
  content: '\f3b6'; }

.zmdi-surround-sound:before {
  content: '\f3b7'; }

.zmdi-tune:before {
  content: '\f3b8'; }

.zmdi-volume-down:before {
  content: '\f3b9'; }

.zmdi-volume-mute:before {
  content: '\f3ba'; }

.zmdi-volume-off:before {
  content: '\f3bb'; }

.zmdi-volume-up:before {
  content: '\f3bc'; }

.zmdi-n-1-square:before {
  content: '\f3bd'; }

.zmdi-n-2-square:before {
  content: '\f3be'; }

.zmdi-n-3-square:before {
  content: '\f3bf'; }

.zmdi-n-4-square:before {
  content: '\f3c0'; }

.zmdi-n-5-square:before {
  content: '\f3c1'; }

.zmdi-n-6-square:before {
  content: '\f3c2'; }

.zmdi-neg-1:before {
  content: '\f3c3'; }

.zmdi-neg-2:before {
  content: '\f3c4'; }

.zmdi-plus-1:before {
  content: '\f3c5'; }

.zmdi-plus-2:before {
  content: '\f3c6'; }

.zmdi-sec-10:before {
  content: '\f3c7'; }

.zmdi-sec-3:before {
  content: '\f3c8'; }

.zmdi-zero:before {
  content: '\f3c9'; }

.zmdi-airline-seat-flat-angled:before {
  content: '\f3ca'; }

.zmdi-airline-seat-flat:before {
  content: '\f3cb'; }

.zmdi-airline-seat-individual-suite:before {
  content: '\f3cc'; }

.zmdi-airline-seat-legroom-extra:before {
  content: '\f3cd'; }

.zmdi-airline-seat-legroom-normal:before {
  content: '\f3ce'; }

.zmdi-airline-seat-legroom-reduced:before {
  content: '\f3cf'; }

.zmdi-airline-seat-recline-extra:before {
  content: '\f3d0'; }

.zmdi-airline-seat-recline-normal:before {
  content: '\f3d1'; }

.zmdi-airplay:before {
  content: '\f3d2'; }

.zmdi-closed-caption:before {
  content: '\f3d3'; }

.zmdi-confirmation-number:before {
  content: '\f3d4'; }

.zmdi-developer-board:before {
  content: '\f3d5'; }

.zmdi-disc-full:before {
  content: '\f3d6'; }

.zmdi-explicit:before {
  content: '\f3d7'; }

.zmdi-flight-land:before {
  content: '\f3d8'; }

.zmdi-flight-takeoff:before {
  content: '\f3d9'; }

.zmdi-flip-to-back:before {
  content: '\f3da'; }

.zmdi-flip-to-front:before {
  content: '\f3db'; }

.zmdi-group-work:before {
  content: '\f3dc'; }

.zmdi-hd:before {
  content: '\f3dd'; }

.zmdi-hq:before {
  content: '\f3de'; }

.zmdi-markunread-mailbox:before {
  content: '\f3df'; }

.zmdi-memory:before {
  content: '\f3e0'; }

.zmdi-nfc:before {
  content: '\f3e1'; }

.zmdi-play-for-work:before {
  content: '\f3e2'; }

.zmdi-power-input:before {
  content: '\f3e3'; }

.zmdi-present-to-all:before {
  content: '\f3e4'; }

.zmdi-satellite:before {
  content: '\f3e5'; }

.zmdi-tap-and-play:before {
  content: '\f3e6'; }

.zmdi-vibration:before {
  content: '\f3e7'; }

.zmdi-voicemail:before {
  content: '\f3e8'; }

.zmdi-group:before {
  content: '\f3e9'; }

.zmdi-rss:before {
  content: '\f3ea'; }

.zmdi-shape:before {
  content: '\f3eb'; }

.zmdi-spinner:before {
  content: '\f3ec'; }

.zmdi-ungroup:before {
  content: '\f3ed'; }

.zmdi-500px:before {
  content: '\f3ee'; }

.zmdi-8tracks:before {
  content: '\f3ef'; }

.zmdi-amazon:before {
  content: '\f3f0'; }

.zmdi-blogger:before {
  content: '\f3f1'; }

.zmdi-delicious:before {
  content: '\f3f2'; }

.zmdi-disqus:before {
  content: '\f3f3'; }

.zmdi-flattr:before {
  content: '\f3f4'; }

.zmdi-flickr:before {
  content: '\f3f5'; }

.zmdi-github-alt:before {
  content: '\f3f6'; }

.zmdi-google-old:before {
  content: '\f3f7'; }

.zmdi-linkedin:before {
  content: '\f3f8'; }

.zmdi-odnoklassniki:before {
  content: '\f3f9'; }

.zmdi-outlook:before {
  content: '\f3fa'; }

.zmdi-paypal-alt:before {
  content: '\f3fb'; }

.zmdi-pinterest:before {
  content: '\f3fc'; }

.zmdi-playstation:before {
  content: '\f3fd'; }

.zmdi-reddit:before {
  content: '\f3fe'; }

.zmdi-skype:before {
  content: '\f3ff'; }

.zmdi-slideshare:before {
  content: '\f400'; }

.zmdi-soundcloud:before {
  content: '\f401'; }

.zmdi-tumblr:before {
  content: '\f402'; }

.zmdi-twitch:before {
  content: '\f403'; }

.zmdi-vimeo:before {
  content: '\f404'; }

.zmdi-whatsapp:before {
  content: '\f405'; }

.zmdi-xbox:before {
  content: '\f406'; }

.zmdi-yahoo:before {
  content: '\f407'; }

.zmdi-youtube-play:before {
  content: '\f408'; }

.zmdi-youtube:before {
  content: '\f409'; }

.zmdi-import-export:before {
  content: '\f30c'; }

.zmdi-swap-vertical-:before {
  content: '\f30c'; }

.zmdi-airplanemode-inactive:before {
  content: '\f102'; }

.zmdi-airplanemode-active:before {
  content: '\f103'; }

.zmdi-rate-review:before {
  content: '\f103'; }

.zmdi-comment-sign:before {
  content: '\f25a'; }

.zmdi-network-warning:before {
  content: '\f2ad'; }

.zmdi-shopping-cart-add:before {
  content: '\f1ca'; }

.zmdi-file-add:before {
  content: '\f221'; }

.zmdi-network-wifi-scan:before {
  content: '\f2e4'; }

.zmdi-collection-add:before {
  content: '\f14e'; }

.zmdi-format-playlist-add:before {
  content: '\f3ac'; }

.zmdi-format-queue-music:before {
  content: '\f3ab'; }

.zmdi-plus-box:before {
  content: '\f277'; }

.zmdi-tag-backspace:before {
  content: '\f1d9'; }

.zmdi-alarm-add:before {
  content: '\f32b'; }

.zmdi-battery-charging:before {
  content: '\f114'; }

.zmdi-daydream-setting:before {
  content: '\f217'; }

.zmdi-more-horiz:before {
  content: '\f19c'; }

.zmdi-book-photo:before {
  content: '\f11b'; }

.zmdi-incandescent:before {
  content: '\f189'; }

.zmdi-wb-iridescent:before {
  content: '\f38c'; }

.zmdi-calendar-remove:before {
  content: '\f330'; }

.zmdi-refresh-sync-disabled:before {
  content: '\f1b7'; }

.zmdi-refresh-sync-problem:before {
  content: '\f1b6'; }

.zmdi-crop-original:before {
  content: '\f17e'; }

.zmdi-power-off:before {
  content: '\f1af'; }

.zmdi-power-off-setting:before {
  content: '\f1ae'; }

.zmdi-leak-remove:before {
  content: '\f38d'; }

.zmdi-star-border:before {
  content: '\f27c'; }

.zmdi-brightness-low:before {
  content: '\f36d'; }

.zmdi-brightness-medium:before {
  content: '\f36e'; }

.zmdi-brightness-high:before {
  content: '\f36f'; }

.zmdi-smartphone-portrait:before {
  content: '\f2d4'; }

.zmdi-live-tv:before {
  content: '\f2d9'; }

.zmdi-format-textdirection-l-to-r:before {
  content: '\f249'; }

.zmdi-format-textdirection-r-to-l:before {
  content: '\f24a'; }

.zmdi-arrow-back:before {
  content: '\f2ea'; }

.zmdi-arrow-forward:before {
  content: '\f2ee'; }

.zmdi-arrow-in:before {
  content: '\f2e9'; }

.zmdi-arrow-out:before {
  content: '\f2ed'; }

.zmdi-rotate-90-degrees-ccw:before {
  content: '\f304'; }

.zmdi-adb:before {
  content: '\f33a'; }

.zmdi-network-wifi:before {
  content: '\f2e8'; }

.zmdi-network-wifi-alt:before {
  content: '\f2e3'; }

.zmdi-network-wifi-lock:before {
  content: '\f2e5'; }

.zmdi-network-wifi-off:before {
  content: '\f2e6'; }

.zmdi-network-wifi-outline:before {
  content: '\f2e7'; }

.zmdi-network-wifi-info:before {
  content: '\f2e4'; }

.zmdi-layers-clear:before {
  content: '\f18b'; }

.zmdi-colorize:before {
  content: '\f15d'; }

.zmdi-format-paint:before {
  content: '\f1ba'; }

.zmdi-format-quote:before {
  content: '\f1b2'; }

.zmdi-camera-monochrome-photos:before {
  content: '\f285'; }

.zmdi-sort-by-alpha:before {
  content: '\f1cf'; }

.zmdi-folder-shared:before {
  content: '\f225'; }

.zmdi-folder-special:before {
  content: '\f226'; }

.zmdi-comment-dots:before {
  content: '\f260'; }

.zmdi-reorder:before {
  content: '\f31e'; }

.zmdi-dehaze:before {
  content: '\f197'; }

.zmdi-sort:before {
  content: '\f1ce'; }

.zmdi-pages:before {
  content: '\f34a'; }

.zmdi-stack-overflow:before {
  content: '\f35c'; }

.zmdi-calendar-account:before {
  content: '\f204'; }

.zmdi-paste:before {
  content: '\f109'; }

.zmdi-cut:before {
  content: '\f1bc'; }

.zmdi-save:before {
  content: '\f297'; }

.zmdi-smartphone-code:before {
  content: '\f139'; }

.zmdi-directions-bike:before {
  content: '\f117'; }

.zmdi-directions-boat:before {
  content: '\f11a'; }

.zmdi-directions-bus:before {
  content: '\f121'; }

.zmdi-directions-car:before {
  content: '\f125'; }

.zmdi-directions-railway:before {
  content: '\f1b3'; }

.zmdi-directions-run:before {
  content: '\f215'; }

.zmdi-directions-subway:before {
  content: '\f1d5'; }

.zmdi-directions-walk:before {
  content: '\f216'; }

.zmdi-local-hotel:before {
  content: '\f178'; }

.zmdi-local-activity:before {
  content: '\f1df'; }

.zmdi-local-play:before {
  content: '\f1df'; }

.zmdi-local-airport:before {
  content: '\f103'; }

.zmdi-local-atm:before {
  content: '\f198'; }

.zmdi-local-bar:before {
  content: '\f137'; }

.zmdi-local-cafe:before {
  content: '\f13b'; }

.zmdi-local-car-wash:before {
  content: '\f124'; }

.zmdi-local-convenience-store:before {
  content: '\f1d3'; }

.zmdi-local-dining:before {
  content: '\f153'; }

.zmdi-local-drink:before {
  content: '\f157'; }

.zmdi-local-florist:before {
  content: '\f168'; }

.zmdi-local-gas-station:before {
  content: '\f16f'; }

.zmdi-local-grocery-store:before {
  content: '\f1cb'; }

.zmdi-local-hospital:before {
  content: '\f177'; }

.zmdi-local-laundry-service:before {
  content: '\f1e9'; }

.zmdi-local-library:before {
  content: '\f18d'; }

.zmdi-local-mall:before {
  content: '\f195'; }

.zmdi-local-movies:before {
  content: '\f19d'; }

.zmdi-local-offer:before {
  content: '\f187'; }

.zmdi-local-parking:before {
  content: '\f1a5'; }

.zmdi-local-parking:before {
  content: '\f1a5'; }

.zmdi-local-pharmacy:before {
  content: '\f176'; }

.zmdi-local-phone:before {
  content: '\f2be'; }

.zmdi-local-pizza:before {
  content: '\f1ac'; }

.zmdi-local-post-office:before {
  content: '\f15a'; }

.zmdi-local-printshop:before {
  content: '\f1b0'; }

.zmdi-local-see:before {
  content: '\f28c'; }

.zmdi-local-shipping:before {
  content: '\f1e6'; }

.zmdi-local-store:before {
  content: '\f1d4'; }

.zmdi-local-taxi:before {
  content: '\f123'; }

.zmdi-local-wc:before {
  content: '\f211'; }

.zmdi-my-location:before {
  content: '\f299'; }

.zmdi-directions:before {
  content: '\f1e7'; }

/* line 3, ../../styles/common/jquery.carousel-3d.scss */
[data-carousel-3d] [data-prev-button] {
  cursor: pointer;
  height: 25px;
  left: 0px;
  margin-top: -13px;
  position: absolute;
  top: 50%;
  -webkit-transition: background 0.3s ease 0s;
  transition: background 0.3s ease 0s;
  width: 26px; }

[data-carousel-3d] [data-prev-button]:before {
  height: 25px;
  width: 16px;
  left: 0;
  top: 0;
  position: absolute;
  color: #2d3e50;
  content: "";
  font-family: "Material-Design-Iconic-Font";
  font-size: 50px !important;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

[data-carousel-3d] [data-prev-button]:hover:before, [data-carousel-3d] [data-next-button]:hover:before {
  color: #7a9757; }

/* line 10, ../../styles/common/jquery.carousel-3d.scss */
[data-carousel-3d] [data-next-button] {
  cursor: pointer;
  height: 25px;
  margin-top: -13px;
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transition: background 0.3s ease 0s;
  transition: background 0.3s ease 0s;
  width: 22px; }

[data-carousel-3d] [data-next-button]:before {
  height: 25px;
  width: 16px;
  content: "";
  right: 0;
  top: 0;
  position: absolute;
  color: #2d3e50;
  content: "";
  font-family: "Material-Design-Iconic-Font";
  font-size: 50px !important; }

.screenshots-blue [data-carousel-3d] [data-prev-button]:hover:before, .screenshots-blue [data-carousel-3d] [data-next-button]:hover:before {
  color: #05C2F9; }

.screenshots-yellow [data-carousel-3d] [data-prev-button]:hover:before, .screenshots-yellow [data-carousel-3d] [data-next-button]:hover:before {
  color: #FDC007; }

/* line 22, ../../styles/common/jquery.carousel-3d.scss */
[data-carousel-3d] {
  position: relative; }

/* line 26, ../../styles/common/jquery.carousel-3d.scss */
[data-carousel-3d] > [data-children-wrapper] {
  perspective: 3000px;
  -ms-perspective: 3000px;
  -moz-perspective: 3000px;
  -webkit-perspective: 3000px; }

/* line 32, ../../styles/common/jquery.carousel-3d.scss */
[data-carousel-3d] > [data-children-wrapper] > [data-child] {
  transition: 1s;
  -ms-transition: 1s;
  -moz-transition: 1s;
  -webkit-transition: 1s; }

/* line 43, ../../styles/common/jquery.carousel-3d.scss */
[data-carousel-3d] {
  box-sizing: border-box;
  width: 100%;
  height: 100%; }

/* line 50, ../../styles/common/jquery.carousel-3d.scss */
[data-carousel-3d] > [data-children-wrapper] > [data-child] {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%; }

/* line 59, ../../styles/common/jquery.carousel-3d.scss */
[data-carousel-3d] > [data-children-wrapper] > [data-child] > [data-child-frame] > [data-content-wrapper] {
  box-sizing: border-box;
  transform-origin: 0px 0px;
  -ms-transform-origin: 0px 0px;
  -moz-transform-origin: 0px 0px;
  -webkit-transform-origin: 0px 0px; }

/* line 75, ../../styles/common/jquery.carousel-3d.scss */
[data-carousel-3d]:before {
  padding-top: 40%;
  display: block;
  content: ''; }

/* line 82, ../../styles/common/jquery.carousel-3d.scss */
[data-carousel-3d] [data-children-wrapper] {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 26%;
  left: 26%; }

/* line 9, ../../styles/theme/jquery.carousel-3d.default.scss */
/* line 19, ../../styles/theme/jquery.carousel-3d.default.scss */
/* line 53, ../../styles/theme/jquery.carousel-3d.default.scss */
/*# sourceMappingURL=jquery.carousel-3d.default.css.map */
/*==========================
  Mobile Menu 
============================*/
a.meanmenu-reveal {
  display: none; }

.mean-container .mean-bar {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  float: left;
  height: 46px;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 999999; }

.mean-container a.meanmenu-reveal {
  color: #7a9757;
  cursor: pointer;
  display: block;
  float: right;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 1px;
  font-weight: 700;
  height: 22px;
  line-height: 22px;
  padding: 13px 0 17px;
  right: 0;
  text-decoration: none;
  text-indent: -9999em;
  top: 0;
  width: 22px; }

.header-blue .mean-container a.meanmenu-reveal {
  color: #05C2F9; }

.header-yellow .mean-container a.meanmenu-reveal {
  color: #FEC107; }

.mean-container a.meanmenu-reveal span {
  display: block;
  background: #fff;
  height: 3px;
  margin-top: 3px; }

.mean-container .mean-nav {
  float: left;
  width: 100%;
  background: #0c1923; }

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none; }

.mean-container .mean-nav ul li {
  background: #fff none repeat scroll 0 0;
  float: left;
  position: relative;
  width: 100%;
  border-top: 1px solid #ddd; }

.mean-container .mean-nav ul li a {
  background: #fff none repeat scroll 0 0;
  color: #2d3e50;
  display: block;
  float: left;
  font-size: 12px;
  margin: 0;
  padding: 1em 5%;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  width: 90%;
  font-weight: bold; }

.mobile-menu-area {
  background: #262626; }

.mean-container .mean-nav ul li li a {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  opacity: 0.75;
  padding: 1em 10%;
  text-shadow: none !important;
  visibility: visible;
  width: 80%;
  font-weight: normal;
  text-transform: capitalize;
  color: #444; }

.mean-container .mean-nav ul li.mean-last a {
  border-bottom: 0;
  margin-bottom: 0; }

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 1em 15%; }

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 1em 20%; }

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 1em 25%; }

.mean-container .mean-nav ul li a:hover {
  background: #fff none repeat scroll 0 0;
  color: #7A9757; }

.header-blue .mean-container .mean-nav ul li a:hover {
  color: #05C2F9; }

.header-yellow .mean-container .mean-nav ul li a:hover {
  color: #FEC107; }

.mean-container .mean-nav ul li a.mean-expand {
  width: 26px;
  height: 15px;
  margin-top: 1px;
  padding: 12px !important;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  background: rgba(255, 255, 255, 0.1);
  border: 0 !important;
  background: #F8F8F8; }

.mean-container .mean-nav ul li a.mean-expand:hover {
  background: #f8f8f8; }

.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both; }

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0; }

.mean-container .mean-bar, .mean-container .mean-bar * {
  box-sizing: content-box; }

.mean-remove {
  display: none !important; }

/*========================
  Font Et Line
=========================*/
@font-face {
  font-family: 'et-line';
  src: url(/static/media/et-line.26ec3c7d.eot);
  src: url(/static/media/et-line.26ec3c7d.eot?#iefix) format("embedded-opentype"), url(/static/media/et-line.b01ff252.woff) format("woff"), url(/static/media/et-line.98126e3e.ttf) format("truetype"), url(/static/media/et-line.2bea85e7.svg#et-line) format("svg");
  font-weight: normal;
  font-style: normal; }

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
  font-family: 'et-line';
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block; }

/* Use the following CSS code if you want to have a class per icon */
/*
  Instead of a list of all class selectors,
  you can use the generic selector below, but it's slower:
  [class*="icon-"] {
  */
.icon-mobile, .icon-laptop, .icon-desktop, .icon-tablet, .icon-phone, .icon-document, .icon-documents, .icon-search, .icon-clipboard, .icon-newspaper, .icon-notebook, .icon-book-open, .icon-browser, .icon-calendar, .icon-presentation, .icon-picture, .icon-pictures, .icon-video, .icon-camera, .icon-printer, .icon-toolbox, .icon-briefcase, .icon-wallet, .icon-gift, .icon-bargraph, .icon-grid, .icon-expand, .icon-focus, .icon-edit, .icon-adjustments, .icon-ribbon, .icon-hourglass, .icon-lock, .icon-megaphone, .icon-shield, .icon-trophy, .icon-flag, .icon-map, .icon-puzzle, .icon-basket, .icon-envelope, .icon-streetsign, .icon-telescope, .icon-gears, .icon-key, .icon-paperclip, .icon-attachment, .icon-pricetags, .icon-lightbulb, .icon-layers, .icon-pencil, .icon-tools, .icon-tools-2, .icon-scissors, .icon-paintbrush, .icon-magnifying-glass, .icon-circle-compass, .icon-linegraph, .icon-mic, .icon-strategy, .icon-beaker, .icon-caution, .icon-recycle, .icon-anchor, .icon-profile-male, .icon-profile-female, .icon-bike, .icon-wine, .icon-hotairballoon, .icon-globe, .icon-genius, .icon-map-pin, .icon-dial, .icon-chat, .icon-heart, .icon-cloud, .icon-upload, .icon-download, .icon-target, .icon-hazardous, .icon-piechart, .icon-speedometer, .icon-global, .icon-compass, .icon-lifesaver, .icon-clock, .icon-aperture, .icon-quote, .icon-scope, .icon-alarmclock, .icon-refresh, .icon-happy, .icon-sad, .icon-facebook, .icon-twitter, .icon-googleplus, .icon-rss, .icon-tumblr, .icon-linkedin, .icon-dribbble {
  font-family: 'et-line';
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block; }

.icon-mobile:before {
  content: "\e000"; }

.icon-laptop:before {
  content: "\e001"; }

.icon-desktop:before {
  content: "\e002"; }

.icon-tablet:before {
  content: "\e003"; }

.icon-phone:before {
  content: "\e004"; }

.icon-document:before {
  content: "\e005"; }

.icon-documents:before {
  content: "\e006"; }

.icon-search:before {
  content: "\e007"; }

.icon-clipboard:before {
  content: "\e008"; }

.icon-newspaper:before {
  content: "\e009"; }

.icon-notebook:before {
  content: "\e00a"; }

.icon-book-open:before {
  content: "\e00b"; }

.icon-browser:before {
  content: "\e00c"; }

.icon-calendar:before {
  content: "\e00d"; }

.icon-presentation:before {
  content: "\e00e"; }

.icon-picture:before {
  content: "\e00f"; }

.icon-pictures:before {
  content: "\e010"; }

.icon-video:before {
  content: "\e011"; }

.icon-camera:before {
  content: "\e012"; }

.icon-printer:before {
  content: "\e013"; }

.icon-toolbox:before {
  content: "\e014"; }

.icon-briefcase:before {
  content: "\e015"; }

.icon-wallet:before {
  content: "\e016"; }

.icon-gift:before {
  content: "\e017"; }

.icon-bargraph:before {
  content: "\e018"; }

.icon-grid:before {
  content: "\e019"; }

.icon-expand:before {
  content: "\e01a"; }

.icon-focus:before {
  content: "\e01b"; }

.icon-edit:before {
  content: "\e01c"; }

.icon-adjustments:before {
  content: "\e01d"; }

.icon-ribbon:before {
  content: "\e01e"; }

.icon-hourglass:before {
  content: "\e01f"; }

.icon-lock:before {
  content: "\e020"; }

.icon-megaphone:before {
  content: "\e021"; }

.icon-shield:before {
  content: "\e022"; }

.icon-trophy:before {
  content: "\e023"; }

.icon-flag:before {
  content: "\e024"; }

.icon-map:before {
  content: "\e025"; }

.icon-puzzle:before {
  content: "\e026"; }

.icon-basket:before {
  content: "\e027"; }

.icon-envelope:before {
  content: "\e028"; }

.icon-streetsign:before {
  content: "\e029"; }

.icon-telescope:before {
  content: "\e02a"; }

.icon-gears:before {
  content: "\e02b"; }

.icon-key:before {
  content: "\e02c"; }

.icon-paperclip:before {
  content: "\e02d"; }

.icon-attachment:before {
  content: "\e02e"; }

.icon-pricetags:before {
  content: "\e02f"; }

.icon-lightbulb:before {
  content: "\e030"; }

.icon-layers:before {
  content: "\e031"; }

.icon-pencil:before {
  content: "\e032"; }

.icon-tools:before {
  content: "\e033"; }

.icon-tools-2:before {
  content: "\e034"; }

.icon-scissors:before {
  content: "\e035"; }

.icon-paintbrush:before {
  content: "\e036"; }

.icon-magnifying-glass:before {
  content: "\e037"; }

.icon-circle-compass:before {
  content: "\e038"; }

.icon-linegraph:before {
  content: "\e039"; }

.icon-mic:before {
  content: "\e03a"; }

.icon-strategy:before {
  content: "\e03b"; }

.icon-beaker:before {
  content: "\e03c"; }

.icon-caution:before {
  content: "\e03d"; }

.icon-recycle:before {
  content: "\e03e"; }

.icon-anchor:before {
  content: "\e03f"; }

.icon-profile-male:before {
  content: "\e040"; }

.icon-profile-female:before {
  content: "\e041"; }

.icon-bike:before {
  content: "\e042"; }

.icon-wine:before {
  content: "\e043"; }

.icon-hotairballoon:before {
  content: "\e044"; }

.icon-globe:before {
  content: "\e045"; }

.icon-genius:before {
  content: "\e046"; }

.icon-map-pin:before {
  content: "\e047"; }

.icon-dial:before {
  content: "\e048"; }

.icon-chat:before {
  content: "\e049"; }

.icon-heart:before {
  content: "\e04a"; }

.icon-cloud:before {
  content: "\e04b"; }

.icon-upload:before {
  content: "\e04c"; }

.icon-download:before {
  content: "\e04d"; }

.icon-target:before {
  content: "\e04e"; }

.icon-hazardous:before {
  content: "\e04f"; }

.icon-piechart:before {
  content: "\e050"; }

.icon-speedometer:before {
  content: "\e051"; }

.icon-global:before {
  content: "\e052"; }

.icon-compass:before {
  content: "\e053"; }

.icon-lifesaver:before {
  content: "\e054"; }

.icon-clock:before {
  content: "\e055"; }

.icon-aperture:before {
  content: "\e056"; }

.icon-quote:before {
  content: "\e057"; }

.icon-scope:before {
  content: "\e058"; }

.icon-alarmclock:before {
  content: "\e059"; }

.icon-refresh:before {
  content: "\e05a"; }

.icon-happy:before {
  content: "\e05b"; }

.icon-sad:before {
  content: "\e05c"; }

.icon-facebook:before {
  content: "\e05d"; }

.icon-twitter:before {
  content: "\e05e"; }

.icon-googleplus:before {
  content: "\e05f"; }

.icon-rss:before {
  content: "\e060"; }

.icon-tumblr:before {
  content: "\e061"; }

.icon-linkedin:before {
  content: "\e062"; }

.icon-dribbble:before {
  content: "\e063"; }

/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ extend
	|	|___ animations
	|	|___ shortcode
	|	|___ spacing
	|
	|
	|___Header Styles
	|	|___ header
	|
	|
	|__Element Styles
	|	|___ button 
	|	|___ section-title 
	|	|___ service
	|	|___ feature
	|	|___ pricing
	|	|___ testimonial
	|	|___ screenshot
	|	|___ blog
	|
	|__Template Styles
	|	|___ banner 
	|	|___ about 
	|	|___ download 
	|	|___ breadcaump 
	|	|___ blogdetails 
	|	|___ footer 
	|	|___ landing 
	|	|___ color 
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/
/**************************************
    Default Styles
***************************************/
/*===============================
    Font Family 
=================================*/
/*===============================
    Color Variation 
=================================*/
/* container 450px*/
/* container 300px*/
@media only screen and (max-width: 575px) {
  .container {
    max-width: 450px; } }

@media only screen and (max-width: 479px) {
  .container {
    max-width: 320px; } }

@media (max-width: 1920px) and (min-width: 1400px) {
  .container {
    max-width: 1200px; } }

/**
 * Reset Styels
 */
* {
  box-sizing: border-box; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html,
button,
input,
select,
textarea {
  font-family: "Source Sans Pro", Helvetica, sans-serif; }

a {
  color: #ca3c08;
  text-decoration: none; }

a:visited {
  color: #ac0404; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  color: #ea9629;
  outline: 0; }

a:hover {
  text-decoration: underline; }

address {
  font-style: italic;
  margin: 0 0 24px; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

p {
  margin: 0 0 24px; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 14px;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; }

pre {
  background: #f5f5f5;
  color: #666;
  font-family: monospace;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

blockquote,
q {
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

blockquote {
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  margin: 24px 40px; }

blockquote blockquote {
  margin-right: 0; }

blockquote cite,
blockquote small {
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase; }

blockquote em,
blockquote i {
  font-style: normal;
  font-weight: 300; }

blockquote strong,
blockquote b {
  font-weight: 400; }

small {
  font-size: smaller; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

dl {
  margin: 0 20px; }

dt {
  font-weight: bold; }

dd {
  margin: 0 0 20px; }

menu,
ol,
ul {
  margin: 16px 0;
  padding: 0 0 0 40px; }

ul {
  list-style-type: square; }

nav ul,
nav ol {
  list-style: none;
  list-style-image: none; }

li > ul,
li > ol {
  margin: 0; }

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  min-width: inherit;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline; }

button,
input {
  line-height: normal; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  cursor: pointer; }

button[disabled],
input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  appearance: textfield;
  padding-right: 2px;
  width: 270px; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-bottom: 1px solid #ededed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
  line-height: 2;
  margin: 0 0 20px;
  width: 100%; }

caption,
th,
td {
  font-weight: normal;
  text-align: left; }

caption {
  font-size: 16px;
  margin: 20px 0; }

th {
  font-weight: bold;
  text-transform: uppercase; }

td {
  border-top: 1px solid #ededed;
  padding: 6px 10px 6px 0; }

del {
  color: #333; }

ins {
  background: #fff9c0;
  text-decoration: none; }

hr {
  background-size: 4px 4px;
  border: 0;
  height: 1px;
  margin: 0 0 24px; }

/**
 * Typography
 */
* {
  box-sizing: border-box; }

img {
  max-width: 100%; }

html {
  overflow: hidden;
  overflow-y: auto; }

body {
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", sans-serif;
  color: #2d3e50;
  font-weight: 400; }

a {
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
dl,
dd,
menu,
ol,
ul,
table,
caption,
hr {
  margin: 0;
  margin-bottom: 15px; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Raleway", sans-serif;
  line-height: 1.4074;
  color: #2f2f2f; }

h1,
.h1 {
  font-size: 64px; }

h2,
.h2 {
  font-size: 54px; }

h3,
.h3 {
  font-size: 24px; }

h4,
.h4 {
  font-size: 20px; }

h5,
.h5 {
  font-size: 18px; }

h6,
.h6 {
  font-size: 16px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 60px; }
  h2,
  .h2 {
    font-size: 52px; }
  h3,
  .h3 {
    font-size: 22px; } }

@media only screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 58px; }
  h2,
  .h2 {
    font-size: 50px; }
  h3,
  .h3 {
    font-size: 22px; } }

@media only screen and (max-width: 575px) {
  h1,
  .h1 {
    font-size: 56px; } }

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: 700; }

h4,
.h4,
h5,
.h5 {
  font-weight: 600; }

h6,
.h6 {
  font-weight: 500; }

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none; }

a:visited {
  color: inherit; }

input,
button,
select,
textarea {
  background: transparent;
  border: 1px solid #d4d4d4;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  color: #2d3e50; }
  input:focus, input:active,
  button:focus,
  button:active,
  select:focus,
  select:active,
  textarea:focus,
  textarea:active {
    outline: none;
    border-color: #05C2F9; }

input,
select,
textarea {
  width: 100%;
  font-size: 14px; }

input,
select {
  height: 40px;
  padding: 0 15px; }

/*=====================
Common Css 
=======================*/
.textBlack {
  color: #000000; }

.page-list li a {
  color: #535353; }

.bodyColor {
  color: #535353; }

.color-1 {
  color: #8956e2; }

.color-2 {
  color: #11ccd3; }

.color-3 {
  color: #f4769a; }

.color-4 {
  color: #2f21b3; }

.bgcolor-4 {
  background: #2f21b3; }

.page-list {
  padding: 0;
  margin: 0;
  list-style: none; }

.liststyle {
  padding: 0;
  margin: 0;
  list-style: none; }

.page-list li a {
  -webkit-transition: all .5s;
  transition: all .5s; }

.fontWeight900 {
  font-weight: 900 !important; }

.fontWeight800 {
  font-weight: 800 !important; }

.fontWeight700 {
  font-weight: 700 !important; }

.fontWeight600 {
  font-weight: 600 !important; }

.fontWeight500 {
  font-weight: 500 !important; }

.fontWeight400 {
  font-weight: 400 !important; }

.fontWeight300 {
  font-weight: 300 !important; }

.textUpper {
  text-transform: uppercase; }

.textCap {
  text-transform: capitalize; }

p:last-child {
  margin-bottom: 0; }

.font-1 {
  font-family: "Lato", sans-serif; }

.font-2 {
  font-family: "Raleway", sans-serif; }

.bg_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

/*==============================
 *  Utilities
=================================*/
/*====================================
    Google Font  
===================================*/
/*----------------------------------------
01. font-family: 'Raleway', sans-serif;
02. font-family: 'Lato', sans-serif;

-------------------------------------*/
.hidden {
  display: none; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.poss_relative {
  position: relative; }

.poss_absolute {
  position: absolute; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.wrapper {
  position: relative; }

/* Define Colors */
.theme-color {
  color: #05C2F9; }

/*===============================
    Background Color 
=================================*/
.bg_color--1 {
  background: #fff; }

.bg_color--2 {
  background: #05C2F9; }

/*===========================
    Background Image 
=============================*/
.bg_image--1 {
  background-image: url(/assets/images/bg/bg-image-1.jpg); }

.bg_image--2 {
  background-image: url(/assets/images/bg/bg-image-2.jpg); }

.bg_image--3 {
  background-image: url(/assets/images/bg/bg-image-3.jpg); }

.bg_image--4 {
  background-image: url(/assets/images/bg/bg-image-4.jpg); }

.bg_image--5 {
  background-image: url(/assets/images/bg/bg-image-5.jpg); }

.bg_image--6 {
  background-image: url(/assets/images/bg/bg-image-6.jpg); }

.bg_image--7 {
  background-image: url(/assets/images/bg/bg-image-7.jpg); }

.bg_image--8 {
  background-image: url(/assets/images/bg/bg-image-8.jpg); }

.bg_image--9 {
  background-image: url(/assets/images/bg/bg-image-9.jpg); }

.bg_image--10 {
  background-image: url(/assets/images/bg/bg-image-10.jpg); }

.bg_image--11 {
  background-image: url(/assets/images/bg/bg-image-11.jpg); }

.bg_image--12 {
  background-image: url(/assets/images/bg/bg-image-12.jpg); }

.bg_image--13 {
  background-image: url(/assets/images/bg/bg-image-13.jpg); }

.bg_image--14 {
  background-image: url(/assets/images/bg/bg-image-14.jpg); }

.bg_image--15 {
  background-image: url(/assets/images/bg/bg-image-15.jpg); }

.bg_image--16 {
  background-image: url(/assets/images/bg/bg-image-16.jpg); }

.bg_image--17 {
  background-image: url(/assets/images/bg/bg-image-17.jpg); }

.bg_image--18 {
  background-image: url(/assets/images/bg/bg-image-18.jpg); }

.bg_image--19 {
  background-image: url(/assets/images/bg/bg-image-19.jpg); }

.bg_image--20 {
  background-image: url(/assets/images/bg/bg-image-20.jpg); }

.bg_image--21 {
  background-image: url(/assets/images/bg/bg-image-21.jpg); }

.bg_image--22 {
  background-image: url(/assets/images/bg/bg-image-22.jpg); }

.bg_image--23 {
  background-image: url(/assets/images/bg/bg-image-23.jpg); }

.bg_image--24 {
  background-image: url(/assets/images/bg/bg-image-24.jpg); }

.bg_image--25 {
  background-image: url(/assets/images/bg/bg-image-25.jpg); }

.bg_image--26 {
  background-image: url(/assets/images/bg/bg-image-26.jpg); }

.bg_image--27 {
  background-image: url(/assets/images/bg/bg-image-27.jpg); }

.bg_image--28 {
  background-image: url(/assets/images/bg/bg-image-28.jpg); }

.bg_image--29 {
  background-image: url(/assets/images/bg/bg-image-29.jpg); }

.bg_image--30 {
  background-image: url(/assets/images/bg/bg-image-30.jpg); }

.font--1 {
  font-size: 1px !important; }

.font--2 {
  font-size: 2px !important; }

.font--3 {
  font-size: 3px !important; }

.font--4 {
  font-size: 4px !important; }

.font--5 {
  font-size: 5px !important; }

.font--6 {
  font-size: 6px !important; }

.font--7 {
  font-size: 7px !important; }

.font--8 {
  font-size: 8px !important; }

.font--9 {
  font-size: 9px !important; }

.font--10 {
  font-size: 10px !important; }

.font--11 {
  font-size: 11px !important; }

.font--12 {
  font-size: 12px !important; }

.font--13 {
  font-size: 13px !important; }

.font--14 {
  font-size: 14px !important; }

.font--15 {
  font-size: 15px !important; }

.font--16 {
  font-size: 16px !important; }

.font--17 {
  font-size: 17px !important; }

.font--18 {
  font-size: 18px !important; }

.font--19 {
  font-size: 19px !important; }

.font--20 {
  font-size: 20px !important; }

.font--21 {
  font-size: 21px !important; }

.font--22 {
  font-size: 22px !important; }

.font--23 {
  font-size: 23px !important; }

.font--24 {
  font-size: 24px !important; }

.font--25 {
  font-size: 25px !important; }

.font--26 {
  font-size: 26px !important; }

.font--27 {
  font-size: 27px !important; }

.font--28 {
  font-size: 28px !important; }

.font--29 {
  font-size: 29px !important; }

.font--30 {
  font-size: 30px !important; }

.font--31 {
  font-size: 31px !important; }

.font--32 {
  font-size: 32px !important; }

.font--33 {
  font-size: 33px !important; }

.font--34 {
  font-size: 34px !important; }

.font--35 {
  font-size: 35px !important; }

.font--36 {
  font-size: 36px !important; }

.font--37 {
  font-size: 37px !important; }

.font--38 {
  font-size: 38px !important; }

.font--39 {
  font-size: 39px !important; }

.font--40 {
  font-size: 40px !important; }

.font--41 {
  font-size: 41px !important; }

.font--42 {
  font-size: 42px !important; }

.font--43 {
  font-size: 43px !important; }

.font--44 {
  font-size: 44px !important; }

.font--45 {
  font-size: 45px !important; }

.font--46 {
  font-size: 46px !important; }

.font--47 {
  font-size: 47px !important; }

.font--48 {
  font-size: 48px !important; }

.font--49 {
  font-size: 49px !important; }

.font--50 {
  font-size: 50px !important; }

.font--51 {
  font-size: 51px !important; }

.font--52 {
  font-size: 52px !important; }

.font--53 {
  font-size: 53px !important; }

.font--54 {
  font-size: 54px !important; }

.font--55 {
  font-size: 55px !important; }

.font--56 {
  font-size: 56px !important; }

.font--57 {
  font-size: 57px !important; }

.font--58 {
  font-size: 58px !important; }

.font--59 {
  font-size: 59px !important; }

.font--60 {
  font-size: 60px !important; }

.font--61 {
  font-size: 61px !important; }

.font--62 {
  font-size: 62px !important; }

.font--63 {
  font-size: 63px !important; }

.font--64 {
  font-size: 64px !important; }

.font--65 {
  font-size: 65px !important; }

.font--66 {
  font-size: 66px !important; }

.font--67 {
  font-size: 67px !important; }

.font--68 {
  font-size: 68px !important; }

.font--69 {
  font-size: 69px !important; }

.font--70 {
  font-size: 70px !important; }

.font--71 {
  font-size: 71px !important; }

.font--72 {
  font-size: 72px !important; }

.font--73 {
  font-size: 73px !important; }

.font--74 {
  font-size: 74px !important; }

.font--75 {
  font-size: 75px !important; }

.font--76 {
  font-size: 76px !important; }

.font--77 {
  font-size: 77px !important; }

.font--78 {
  font-size: 78px !important; }

.font--79 {
  font-size: 79px !important; }

.font--80 {
  font-size: 80px !important; }

/*=========================
    Text specialized 
==========================*/
.text-italic {
  font-style: italic; }

.text-normal {
  font-style: normal; }

.text-underline {
  font-style: underline; }

/* Height and width */
.fullscreen {
  min-height: 100vh;
  width: 100%; }

/*===================
Custom Row
======================*/
.row--0 {
  margin-left: -0px;
  margin-right: -0px; }
  .row--0 > [class*="col"] {
    padding-left: 0px;
    padding-right: 0px; }

.row--5 {
  margin-left: -5px;
  margin-right: -5px; }
  .row--5 > [class*="col"] {
    padding-left: 5px;
    padding-right: 5px; }

.row--10 {
  margin-left: -10px;
  margin-right: -10px; }
  .row--10 > [class*="col"] {
    padding-left: 10px;
    padding-right: 10px; }

.row--20 {
  margin-left: -20px;
  margin-right: -20px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--20 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 20px;
    padding-right: 20px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

/*===========================
    Input Placeholder
=============================*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

/*=============================
	Overlay styles 
==============================*/
[data-overlay],
[data-black-overlay],
[data-white-overlay] {
  position: relative; }

[data-overlay] > div,
[data-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-white-overlay] > div,
[data-white-overlay] > * {
  position: relative;
  z-index: 1; }

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1; }

[data-overlay]:before {
  background-color: #05C2F9; }

[data-black-overlay]:before {
  background-color: #000000; }

[data-white-overlay]:before {
  background-color: #ffffff; }

[data-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before {
  opacity: 0.1; }

[data-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before {
  opacity: 0.2; }

[data-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before {
  opacity: 0.3; }

[data-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before {
  opacity: 0.4; }

[data-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before {
  opacity: 0.5; }

[data-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before {
  opacity: 0.6; }

[data-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before {
  opacity: 0.7; }

[data-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before {
  opacity: 0.8; }

[data-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before {
  opacity: 0.9; }

[data-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before {
  opacity: 1; }

/*------------------------------
    Scroll Up 
--------------------------------*/
#scrollUp {
  width: 40px;
  height: 40px;
  background-color: #05C2F9;
  border: 3px solid #eeeeee;
  color: #2f2f2f;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important; }
  @media only screen and (max-width: 767px) {
    #scrollUp {
      display: none !important; } }
  #scrollUp i {
    display: block;
    line-height: 34px;
    font-size: 22px; }
  #scrollUp:hover i {
    -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }

.form-message {
  margin-bottom: 0;
  text-align: center; }
  .form-message.error {
    margin-top: 20px;
    color: #f80707; }
  .form-message.success {
    margin-top: 20px;
    color: #0d8d2d; }

/*=========================
    Section Separation 
==========================*/
.letterspacing--2 {
  letter-spacing: 2px; }

.pra {
  font-size: 18px;
  line-height: 2em; }
  @media only screen and (max-width: 767px) {
    .pra {
      font-size: 14px;
      line-height: 24px; } }

.pl--0 {
  padding-left: 0; }

.pr--0 {
  padding-right: 0; }

.pt--0 {
  padding-top: 0; }

.pb--0 {
  padding-bottom: 0; }

.mr--0 {
  margin-right: 0; }

.ml--0 {
  margin-left: 0; }

.mt--0 {
  margin-top: 0; }

.mb--0 {
  margin-bottom: 0; }

.pt--260 {
  padding-top: 260px; }

.pt--250 {
  padding-top: 250px; }

.plr--270 {
  padding: 0 270px; }

.plr--300 {
  padding: 0 300px; }

.plr--340 {
  padding: 0 340px; }

.ptb--300 {
  padding: 300px 0; }

.ptb--340 {
  padding: 340px 0; }

.ptb--450 {
  padding: 450px 0; }

.plr_dec--15 {
  margin: 0 -15px; }

.pt--300 {
  padding-top: 300px; }

.ptb--5 {
  padding: 5px 0; }

.plr--5 {
  padding: 0 5px; }

.pt--5 {
  padding-top: 5px; }

.pb--5 {
  padding-bottom: 5px; }

.pl--5 {
  padding-left: 5px; }

.pr--5 {
  padding-right: 5px; }

.mt--5 {
  margin-top: 5px !important; }

.mb--5 {
  margin-bottom: 5px; }

.mr--5 {
  margin-right: 5px; }

.ml--5 {
  margin-left: 5px; }

.ptb--10 {
  padding: 10px 0; }

.plr--10 {
  padding: 0 10px; }

.pt--10 {
  padding-top: 10px; }

.pb--10 {
  padding-bottom: 10px; }

.pl--10 {
  padding-left: 10px; }

.pr--10 {
  padding-right: 10px; }

.mt--10 {
  margin-top: 10px !important; }

.mb--10 {
  margin-bottom: 10px; }

.mr--10 {
  margin-right: 10px; }

.ml--10 {
  margin-left: 10px; }

.ptb--15 {
  padding: 15px 0; }

.plr--15 {
  padding: 0 15px; }

.pt--15 {
  padding-top: 15px; }

.pb--15 {
  padding-bottom: 15px; }

.pl--15 {
  padding-left: 15px; }

.pr--15 {
  padding-right: 15px; }

.mt--15 {
  margin-top: 15px !important; }

.mb--15 {
  margin-bottom: 15px; }

.mr--15 {
  margin-right: 15px; }

.ml--15 {
  margin-left: 15px; }

.ptb--20 {
  padding: 20px 0; }

.plr--20 {
  padding: 0 20px; }

.pt--20 {
  padding-top: 20px; }

.pb--20 {
  padding-bottom: 20px; }

.pl--20 {
  padding-left: 20px; }

.pr--20 {
  padding-right: 20px; }

.mt--20 {
  margin-top: 20px !important; }

.mb--20 {
  margin-bottom: 20px; }

.mr--20 {
  margin-right: 20px; }

.ml--20 {
  margin-left: 20px; }

.ptb--25 {
  padding: 25px 0; }

.plr--25 {
  padding: 0 25px; }

.pt--25 {
  padding-top: 25px; }

.pb--25 {
  padding-bottom: 25px; }

.pl--25 {
  padding-left: 25px; }

.pr--25 {
  padding-right: 25px; }

.mt--25 {
  margin-top: 25px !important; }

.mb--25 {
  margin-bottom: 25px; }

.mr--25 {
  margin-right: 25px; }

.ml--25 {
  margin-left: 25px; }

.ptb--30 {
  padding: 30px 0; }

.plr--30 {
  padding: 0 30px; }

.pt--30 {
  padding-top: 30px; }

.pb--30 {
  padding-bottom: 30px; }

.pl--30 {
  padding-left: 30px; }

.pr--30 {
  padding-right: 30px; }

.mt--30 {
  margin-top: 30px !important; }

.mb--30 {
  margin-bottom: 30px; }

.mr--30 {
  margin-right: 30px; }

.ml--30 {
  margin-left: 30px; }

.ptb--35 {
  padding: 35px 0; }

.plr--35 {
  padding: 0 35px; }

.pt--35 {
  padding-top: 35px; }

.pb--35 {
  padding-bottom: 35px; }

.pl--35 {
  padding-left: 35px; }

.pr--35 {
  padding-right: 35px; }

.mt--35 {
  margin-top: 35px !important; }

.mb--35 {
  margin-bottom: 35px; }

.mr--35 {
  margin-right: 35px; }

.ml--35 {
  margin-left: 35px; }

.ptb--40 {
  padding: 40px 0; }

.plr--40 {
  padding: 0 40px; }

.pt--40 {
  padding-top: 40px; }

.pb--40 {
  padding-bottom: 40px; }

.pl--40 {
  padding-left: 40px; }

.pr--40 {
  padding-right: 40px; }

.mt--40 {
  margin-top: 40px !important; }

.mb--40 {
  margin-bottom: 40px; }

.mr--40 {
  margin-right: 40px; }

.ml--40 {
  margin-left: 40px; }

.ptb--45 {
  padding: 45px 0; }

.plr--45 {
  padding: 0 45px; }

.pt--45 {
  padding-top: 45px; }

.pb--45 {
  padding-bottom: 45px; }

.pl--45 {
  padding-left: 45px; }

.pr--45 {
  padding-right: 45px; }

.mt--45 {
  margin-top: 45px !important; }

.mb--45 {
  margin-bottom: 45px; }

.mr--45 {
  margin-right: 45px; }

.ml--45 {
  margin-left: 45px; }

.ptb--50 {
  padding: 50px 0; }

.plr--50 {
  padding: 0 50px; }

.pt--50 {
  padding-top: 50px; }

.pb--50 {
  padding-bottom: 50px; }

.pl--50 {
  padding-left: 50px; }

.pr--50 {
  padding-right: 50px; }

.mt--50 {
  margin-top: 50px !important; }

.mb--50 {
  margin-bottom: 50px; }

.mr--50 {
  margin-right: 50px; }

.ml--50 {
  margin-left: 50px; }

.ptb--55 {
  padding: 55px 0; }

.plr--55 {
  padding: 0 55px; }

.pt--55 {
  padding-top: 55px; }

.pb--55 {
  padding-bottom: 55px; }

.pl--55 {
  padding-left: 55px; }

.pr--55 {
  padding-right: 55px; }

.mt--55 {
  margin-top: 55px !important; }

.mb--55 {
  margin-bottom: 55px; }

.mr--55 {
  margin-right: 55px; }

.ml--55 {
  margin-left: 55px; }

.ptb--60 {
  padding: 60px 0; }

.plr--60 {
  padding: 0 60px; }

.pt--60 {
  padding-top: 60px; }

.pb--60 {
  padding-bottom: 60px; }

.pl--60 {
  padding-left: 60px; }

.pr--60 {
  padding-right: 60px; }

.mt--60 {
  margin-top: 60px !important; }

.mb--60 {
  margin-bottom: 60px; }

.mr--60 {
  margin-right: 60px; }

.ml--60 {
  margin-left: 60px; }

.ptb--65 {
  padding: 65px 0; }

.plr--65 {
  padding: 0 65px; }

.pt--65 {
  padding-top: 65px; }

.pb--65 {
  padding-bottom: 65px; }

.pl--65 {
  padding-left: 65px; }

.pr--65 {
  padding-right: 65px; }

.mt--65 {
  margin-top: 65px !important; }

.mb--65 {
  margin-bottom: 65px; }

.mr--65 {
  margin-right: 65px; }

.ml--65 {
  margin-left: 65px; }

.ptb--70 {
  padding: 70px 0; }

.plr--70 {
  padding: 0 70px; }

.pt--70 {
  padding-top: 70px; }

.pb--70 {
  padding-bottom: 70px; }

.pl--70 {
  padding-left: 70px; }

.pr--70 {
  padding-right: 70px; }

.mt--70 {
  margin-top: 70px !important; }

.mb--70 {
  margin-bottom: 70px; }

.mr--70 {
  margin-right: 70px; }

.ml--70 {
  margin-left: 70px; }

.ptb--75 {
  padding: 75px 0; }

.plr--75 {
  padding: 0 75px; }

.pt--75 {
  padding-top: 75px; }

.pb--75 {
  padding-bottom: 75px; }

.pl--75 {
  padding-left: 75px; }

.pr--75 {
  padding-right: 75px; }

.mt--75 {
  margin-top: 75px !important; }

.mb--75 {
  margin-bottom: 75px; }

.mr--75 {
  margin-right: 75px; }

.ml--75 {
  margin-left: 75px; }

.ptb--80 {
  padding: 80px 0; }

.plr--80 {
  padding: 0 80px; }

.pt--80 {
  padding-top: 80px; }

.pb--80 {
  padding-bottom: 80px; }

.pl--80 {
  padding-left: 80px; }

.pr--80 {
  padding-right: 80px; }

.mt--80 {
  margin-top: 80px !important; }

.mb--80 {
  margin-bottom: 80px; }

.mr--80 {
  margin-right: 80px; }

.ml--80 {
  margin-left: 80px; }

.ptb--85 {
  padding: 85px 0; }

.plr--85 {
  padding: 0 85px; }

.pt--85 {
  padding-top: 85px; }

.pb--85 {
  padding-bottom: 85px; }

.pl--85 {
  padding-left: 85px; }

.pr--85 {
  padding-right: 85px; }

.mt--85 {
  margin-top: 85px !important; }

.mb--85 {
  margin-bottom: 85px; }

.mr--85 {
  margin-right: 85px; }

.ml--85 {
  margin-left: 85px; }

.ptb--90 {
  padding: 90px 0; }

.plr--90 {
  padding: 0 90px; }

.pt--90 {
  padding-top: 90px; }

.pb--90 {
  padding-bottom: 90px; }

.pl--90 {
  padding-left: 90px; }

.pr--90 {
  padding-right: 90px; }

.mt--90 {
  margin-top: 90px !important; }

.mb--90 {
  margin-bottom: 90px; }

.mr--90 {
  margin-right: 90px; }

.ml--90 {
  margin-left: 90px; }

.ptb--95 {
  padding: 95px 0; }

.plr--95 {
  padding: 0 95px; }

.pt--95 {
  padding-top: 95px; }

.pb--95 {
  padding-bottom: 95px; }

.pl--95 {
  padding-left: 95px; }

.pr--95 {
  padding-right: 95px; }

.mt--95 {
  margin-top: 95px !important; }

.mb--95 {
  margin-bottom: 95px; }

.mr--95 {
  margin-right: 95px; }

.ml--95 {
  margin-left: 95px; }

.ptb--100 {
  padding: 100px 0; }

.plr--100 {
  padding: 0 100px; }

.pt--100 {
  padding-top: 100px; }

.pb--100 {
  padding-bottom: 100px; }

.pl--100 {
  padding-left: 100px; }

.pr--100 {
  padding-right: 100px; }

.mt--100 {
  margin-top: 100px !important; }

.mb--100 {
  margin-bottom: 100px; }

.mr--100 {
  margin-right: 100px; }

.ml--100 {
  margin-left: 100px; }

.ptb--105 {
  padding: 105px 0; }

.plr--105 {
  padding: 0 105px; }

.pt--105 {
  padding-top: 105px; }

.pb--105 {
  padding-bottom: 105px; }

.pl--105 {
  padding-left: 105px; }

.pr--105 {
  padding-right: 105px; }

.mt--105 {
  margin-top: 105px !important; }

.mb--105 {
  margin-bottom: 105px; }

.mr--105 {
  margin-right: 105px; }

.ml--105 {
  margin-left: 105px; }

.ptb--110 {
  padding: 110px 0; }

.plr--110 {
  padding: 0 110px; }

.pt--110 {
  padding-top: 110px; }

.pb--110 {
  padding-bottom: 110px; }

.pl--110 {
  padding-left: 110px; }

.pr--110 {
  padding-right: 110px; }

.mt--110 {
  margin-top: 110px !important; }

.mb--110 {
  margin-bottom: 110px; }

.mr--110 {
  margin-right: 110px; }

.ml--110 {
  margin-left: 110px; }

.ptb--115 {
  padding: 115px 0; }

.plr--115 {
  padding: 0 115px; }

.pt--115 {
  padding-top: 115px; }

.pb--115 {
  padding-bottom: 115px; }

.pl--115 {
  padding-left: 115px; }

.pr--115 {
  padding-right: 115px; }

.mt--115 {
  margin-top: 115px !important; }

.mb--115 {
  margin-bottom: 115px; }

.mr--115 {
  margin-right: 115px; }

.ml--115 {
  margin-left: 115px; }

.ptb--120 {
  padding: 120px 0; }

.plr--120 {
  padding: 0 120px; }

.pt--120 {
  padding-top: 120px; }

.pb--120 {
  padding-bottom: 120px; }

.pl--120 {
  padding-left: 120px; }

.pr--120 {
  padding-right: 120px; }

.mt--120 {
  margin-top: 120px !important; }

.mb--120 {
  margin-bottom: 120px; }

.mr--120 {
  margin-right: 120px; }

.ml--120 {
  margin-left: 120px; }

.ptb--125 {
  padding: 125px 0; }

.plr--125 {
  padding: 0 125px; }

.pt--125 {
  padding-top: 125px; }

.pb--125 {
  padding-bottom: 125px; }

.pl--125 {
  padding-left: 125px; }

.pr--125 {
  padding-right: 125px; }

.mt--125 {
  margin-top: 125px !important; }

.mb--125 {
  margin-bottom: 125px; }

.mr--125 {
  margin-right: 125px; }

.ml--125 {
  margin-left: 125px; }

.ptb--130 {
  padding: 130px 0; }

.plr--130 {
  padding: 0 130px; }

.pt--130 {
  padding-top: 130px; }

.pb--130 {
  padding-bottom: 130px; }

.pl--130 {
  padding-left: 130px; }

.pr--130 {
  padding-right: 130px; }

.mt--130 {
  margin-top: 130px !important; }

.mb--130 {
  margin-bottom: 130px; }

.mr--130 {
  margin-right: 130px; }

.ml--130 {
  margin-left: 130px; }

.ptb--135 {
  padding: 135px 0; }

.plr--135 {
  padding: 0 135px; }

.pt--135 {
  padding-top: 135px; }

.pb--135 {
  padding-bottom: 135px; }

.pl--135 {
  padding-left: 135px; }

.pr--135 {
  padding-right: 135px; }

.mt--135 {
  margin-top: 135px !important; }

.mb--135 {
  margin-bottom: 135px; }

.mr--135 {
  margin-right: 135px; }

.ml--135 {
  margin-left: 135px; }

.ptb--140 {
  padding: 140px 0; }

.plr--140 {
  padding: 0 140px; }

.pt--140 {
  padding-top: 140px; }

.pb--140 {
  padding-bottom: 140px; }

.pl--140 {
  padding-left: 140px; }

.pr--140 {
  padding-right: 140px; }

.mt--140 {
  margin-top: 140px !important; }

.mb--140 {
  margin-bottom: 140px; }

.mr--140 {
  margin-right: 140px; }

.ml--140 {
  margin-left: 140px; }

.ptb--145 {
  padding: 145px 0; }

.plr--145 {
  padding: 0 145px; }

.pt--145 {
  padding-top: 145px; }

.pb--145 {
  padding-bottom: 145px; }

.pl--145 {
  padding-left: 145px; }

.pr--145 {
  padding-right: 145px; }

.mt--145 {
  margin-top: 145px !important; }

.mb--145 {
  margin-bottom: 145px; }

.mr--145 {
  margin-right: 145px; }

.ml--145 {
  margin-left: 145px; }

.ptb--150 {
  padding: 150px 0; }

.plr--150 {
  padding: 0 150px; }

.pt--150 {
  padding-top: 150px; }

.pb--150 {
  padding-bottom: 150px; }

.pl--150 {
  padding-left: 150px; }

.pr--150 {
  padding-right: 150px; }

.mt--150 {
  margin-top: 150px !important; }

.mb--150 {
  margin-bottom: 150px; }

.mr--150 {
  margin-right: 150px; }

.ml--150 {
  margin-left: 150px; }

.ptb--155 {
  padding: 155px 0; }

.plr--155 {
  padding: 0 155px; }

.pt--155 {
  padding-top: 155px; }

.pb--155 {
  padding-bottom: 155px; }

.pl--155 {
  padding-left: 155px; }

.pr--155 {
  padding-right: 155px; }

.mt--155 {
  margin-top: 155px !important; }

.mb--155 {
  margin-bottom: 155px; }

.mr--155 {
  margin-right: 155px; }

.ml--155 {
  margin-left: 155px; }

.ptb--160 {
  padding: 160px 0; }

.plr--160 {
  padding: 0 160px; }

.pt--160 {
  padding-top: 160px; }

.pb--160 {
  padding-bottom: 160px; }

.pl--160 {
  padding-left: 160px; }

.pr--160 {
  padding-right: 160px; }

.mt--160 {
  margin-top: 160px !important; }

.mb--160 {
  margin-bottom: 160px; }

.mr--160 {
  margin-right: 160px; }

.ml--160 {
  margin-left: 160px; }

.ptb--165 {
  padding: 165px 0; }

.plr--165 {
  padding: 0 165px; }

.pt--165 {
  padding-top: 165px; }

.pb--165 {
  padding-bottom: 165px; }

.pl--165 {
  padding-left: 165px; }

.pr--165 {
  padding-right: 165px; }

.mt--165 {
  margin-top: 165px !important; }

.mb--165 {
  margin-bottom: 165px; }

.mr--165 {
  margin-right: 165px; }

.ml--165 {
  margin-left: 165px; }

.ptb--170 {
  padding: 170px 0; }

.plr--170 {
  padding: 0 170px; }

.pt--170 {
  padding-top: 170px; }

.pb--170 {
  padding-bottom: 170px; }

.pl--170 {
  padding-left: 170px; }

.pr--170 {
  padding-right: 170px; }

.mt--170 {
  margin-top: 170px !important; }

.mb--170 {
  margin-bottom: 170px; }

.mr--170 {
  margin-right: 170px; }

.ml--170 {
  margin-left: 170px; }

.ptb--175 {
  padding: 175px 0; }

.plr--175 {
  padding: 0 175px; }

.pt--175 {
  padding-top: 175px; }

.pb--175 {
  padding-bottom: 175px; }

.pl--175 {
  padding-left: 175px; }

.pr--175 {
  padding-right: 175px; }

.mt--175 {
  margin-top: 175px !important; }

.mb--175 {
  margin-bottom: 175px; }

.mr--175 {
  margin-right: 175px; }

.ml--175 {
  margin-left: 175px; }

.ptb--180 {
  padding: 180px 0; }

.plr--180 {
  padding: 0 180px; }

.pt--180 {
  padding-top: 180px; }

.pb--180 {
  padding-bottom: 180px; }

.pl--180 {
  padding-left: 180px; }

.pr--180 {
  padding-right: 180px; }

.mt--180 {
  margin-top: 180px !important; }

.mb--180 {
  margin-bottom: 180px; }

.mr--180 {
  margin-right: 180px; }

.ml--180 {
  margin-left: 180px; }

.ptb--185 {
  padding: 185px 0; }

.plr--185 {
  padding: 0 185px; }

.pt--185 {
  padding-top: 185px; }

.pb--185 {
  padding-bottom: 185px; }

.pl--185 {
  padding-left: 185px; }

.pr--185 {
  padding-right: 185px; }

.mt--185 {
  margin-top: 185px !important; }

.mb--185 {
  margin-bottom: 185px; }

.mr--185 {
  margin-right: 185px; }

.ml--185 {
  margin-left: 185px; }

.ptb--190 {
  padding: 190px 0; }

.plr--190 {
  padding: 0 190px; }

.pt--190 {
  padding-top: 190px; }

.pb--190 {
  padding-bottom: 190px; }

.pl--190 {
  padding-left: 190px; }

.pr--190 {
  padding-right: 190px; }

.mt--190 {
  margin-top: 190px !important; }

.mb--190 {
  margin-bottom: 190px; }

.mr--190 {
  margin-right: 190px; }

.ml--190 {
  margin-left: 190px; }

.ptb--195 {
  padding: 195px 0; }

.plr--195 {
  padding: 0 195px; }

.pt--195 {
  padding-top: 195px; }

.pb--195 {
  padding-bottom: 195px; }

.pl--195 {
  padding-left: 195px; }

.pr--195 {
  padding-right: 195px; }

.mt--195 {
  margin-top: 195px !important; }

.mb--195 {
  margin-bottom: 195px; }

.mr--195 {
  margin-right: 195px; }

.ml--195 {
  margin-left: 195px; }

.ptb--200 {
  padding: 200px 0; }

.plr--200 {
  padding: 0 200px; }

.pt--200 {
  padding-top: 200px; }

.pb--200 {
  padding-bottom: 200px; }

.pl--200 {
  padding-left: 200px; }

.pr--200 {
  padding-right: 200px; }

.mt--200 {
  margin-top: 200px !important; }

.mb--200 {
  margin-bottom: 200px; }

.mr--200 {
  margin-right: 200px; }

.ml--200 {
  margin-left: 200px; }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .ptb_lp--5 {
    padding: 5px 0; }
  .plr_lp--5 {
    padding: 0 5px; }
  .pt_lp--5 {
    padding-top: 5px; }
  .pb_lp--5 {
    padding-bottom: 5px; }
  .pl_lp--5 {
    padding-left: 5px; }
  .pr_lp--5 {
    padding-right: 5px; }
  .mt_lp--5 {
    margin-top: 5px; }
  .mb_lp--5 {
    margin-bottom: 5px; }
  .ptb_lp--10 {
    padding: 10px 0; }
  .plr_lp--10 {
    padding: 0 10px; }
  .pt_lp--10 {
    padding-top: 10px; }
  .pb_lp--10 {
    padding-bottom: 10px; }
  .pl_lp--10 {
    padding-left: 10px; }
  .pr_lp--10 {
    padding-right: 10px; }
  .mt_lp--10 {
    margin-top: 10px; }
  .mb_lp--10 {
    margin-bottom: 10px; }
  .ptb_lp--15 {
    padding: 15px 0; }
  .plr_lp--15 {
    padding: 0 15px; }
  .pt_lp--15 {
    padding-top: 15px; }
  .pb_lp--15 {
    padding-bottom: 15px; }
  .pl_lp--15 {
    padding-left: 15px; }
  .pr_lp--15 {
    padding-right: 15px; }
  .mt_lp--15 {
    margin-top: 15px; }
  .mb_lp--15 {
    margin-bottom: 15px; }
  .ptb_lp--20 {
    padding: 20px 0; }
  .plr_lp--20 {
    padding: 0 20px; }
  .pt_lp--20 {
    padding-top: 20px; }
  .pb_lp--20 {
    padding-bottom: 20px; }
  .pl_lp--20 {
    padding-left: 20px; }
  .pr_lp--20 {
    padding-right: 20px; }
  .mt_lp--20 {
    margin-top: 20px; }
  .mb_lp--20 {
    margin-bottom: 20px; }
  .ptb_lp--25 {
    padding: 25px 0; }
  .plr_lp--25 {
    padding: 0 25px; }
  .pt_lp--25 {
    padding-top: 25px; }
  .pb_lp--25 {
    padding-bottom: 25px; }
  .pl_lp--25 {
    padding-left: 25px; }
  .pr_lp--25 {
    padding-right: 25px; }
  .mt_lp--25 {
    margin-top: 25px; }
  .mb_lp--25 {
    margin-bottom: 25px; }
  .ptb_lp--30 {
    padding: 30px 0; }
  .plr_lp--30 {
    padding: 0 30px; }
  .pt_lp--30 {
    padding-top: 30px; }
  .pb_lp--30 {
    padding-bottom: 30px; }
  .pl_lp--30 {
    padding-left: 30px; }
  .pr_lp--30 {
    padding-right: 30px; }
  .mt_lp--30 {
    margin-top: 30px; }
  .mb_lp--30 {
    margin-bottom: 30px; }
  .ptb_lp--35 {
    padding: 35px 0; }
  .plr_lp--35 {
    padding: 0 35px; }
  .pt_lp--35 {
    padding-top: 35px; }
  .pb_lp--35 {
    padding-bottom: 35px; }
  .pl_lp--35 {
    padding-left: 35px; }
  .pr_lp--35 {
    padding-right: 35px; }
  .mt_lp--35 {
    margin-top: 35px; }
  .mb_lp--35 {
    margin-bottom: 35px; }
  .ptb_lp--40 {
    padding: 40px 0; }
  .plr_lp--40 {
    padding: 0 40px; }
  .pt_lp--40 {
    padding-top: 40px; }
  .pb_lp--40 {
    padding-bottom: 40px; }
  .pl_lp--40 {
    padding-left: 40px; }
  .pr_lp--40 {
    padding-right: 40px; }
  .mt_lp--40 {
    margin-top: 40px; }
  .mb_lp--40 {
    margin-bottom: 40px; }
  .ptb_lp--45 {
    padding: 45px 0; }
  .plr_lp--45 {
    padding: 0 45px; }
  .pt_lp--45 {
    padding-top: 45px; }
  .pb_lp--45 {
    padding-bottom: 45px; }
  .pl_lp--45 {
    padding-left: 45px; }
  .pr_lp--45 {
    padding-right: 45px; }
  .mt_lp--45 {
    margin-top: 45px; }
  .mb_lp--45 {
    margin-bottom: 45px; }
  .ptb_lp--50 {
    padding: 50px 0; }
  .plr_lp--50 {
    padding: 0 50px; }
  .pt_lp--50 {
    padding-top: 50px; }
  .pb_lp--50 {
    padding-bottom: 50px; }
  .pl_lp--50 {
    padding-left: 50px; }
  .pr_lp--50 {
    padding-right: 50px; }
  .mt_lp--50 {
    margin-top: 50px; }
  .mb_lp--50 {
    margin-bottom: 50px; }
  .ptb_lp--55 {
    padding: 55px 0; }
  .plr_lp--55 {
    padding: 0 55px; }
  .pt_lp--55 {
    padding-top: 55px; }
  .pb_lp--55 {
    padding-bottom: 55px; }
  .pl_lp--55 {
    padding-left: 55px; }
  .pr_lp--55 {
    padding-right: 55px; }
  .mt_lp--55 {
    margin-top: 55px; }
  .mb_lp--55 {
    margin-bottom: 55px; }
  .ptb_lp--60 {
    padding: 60px 0; }
  .plr_lp--60 {
    padding: 0 60px; }
  .pt_lp--60 {
    padding-top: 60px; }
  .pb_lp--60 {
    padding-bottom: 60px; }
  .pl_lp--60 {
    padding-left: 60px; }
  .pr_lp--60 {
    padding-right: 60px; }
  .mt_lp--60 {
    margin-top: 60px; }
  .mb_lp--60 {
    margin-bottom: 60px; }
  .ptb_lp--65 {
    padding: 65px 0; }
  .plr_lp--65 {
    padding: 0 65px; }
  .pt_lp--65 {
    padding-top: 65px; }
  .pb_lp--65 {
    padding-bottom: 65px; }
  .pl_lp--65 {
    padding-left: 65px; }
  .pr_lp--65 {
    padding-right: 65px; }
  .mt_lp--65 {
    margin-top: 65px; }
  .mb_lp--65 {
    margin-bottom: 65px; }
  .ptb_lp--70 {
    padding: 70px 0; }
  .plr_lp--70 {
    padding: 0 70px; }
  .pt_lp--70 {
    padding-top: 70px; }
  .pb_lp--70 {
    padding-bottom: 70px; }
  .pl_lp--70 {
    padding-left: 70px; }
  .pr_lp--70 {
    padding-right: 70px; }
  .mt_lp--70 {
    margin-top: 70px; }
  .mb_lp--70 {
    margin-bottom: 70px; }
  .ptb_lp--75 {
    padding: 75px 0; }
  .plr_lp--75 {
    padding: 0 75px; }
  .pt_lp--75 {
    padding-top: 75px; }
  .pb_lp--75 {
    padding-bottom: 75px; }
  .pl_lp--75 {
    padding-left: 75px; }
  .pr_lp--75 {
    padding-right: 75px; }
  .mt_lp--75 {
    margin-top: 75px; }
  .mb_lp--75 {
    margin-bottom: 75px; }
  .ptb_lp--80 {
    padding: 80px 0; }
  .plr_lp--80 {
    padding: 0 80px; }
  .pt_lp--80 {
    padding-top: 80px; }
  .pb_lp--80 {
    padding-bottom: 80px; }
  .pl_lp--80 {
    padding-left: 80px; }
  .pr_lp--80 {
    padding-right: 80px; }
  .mt_lp--80 {
    margin-top: 80px; }
  .mb_lp--80 {
    margin-bottom: 80px; }
  .ptb_lp--85 {
    padding: 85px 0; }
  .plr_lp--85 {
    padding: 0 85px; }
  .pt_lp--85 {
    padding-top: 85px; }
  .pb_lp--85 {
    padding-bottom: 85px; }
  .pl_lp--85 {
    padding-left: 85px; }
  .pr_lp--85 {
    padding-right: 85px; }
  .mt_lp--85 {
    margin-top: 85px; }
  .mb_lp--85 {
    margin-bottom: 85px; }
  .ptb_lp--90 {
    padding: 90px 0; }
  .plr_lp--90 {
    padding: 0 90px; }
  .pt_lp--90 {
    padding-top: 90px; }
  .pb_lp--90 {
    padding-bottom: 90px; }
  .pl_lp--90 {
    padding-left: 90px; }
  .pr_lp--90 {
    padding-right: 90px; }
  .mt_lp--90 {
    margin-top: 90px; }
  .mb_lp--90 {
    margin-bottom: 90px; }
  .ptb_lp--95 {
    padding: 95px 0; }
  .plr_lp--95 {
    padding: 0 95px; }
  .pt_lp--95 {
    padding-top: 95px; }
  .pb_lp--95 {
    padding-bottom: 95px; }
  .pl_lp--95 {
    padding-left: 95px; }
  .pr_lp--95 {
    padding-right: 95px; }
  .mt_lp--95 {
    margin-top: 95px; }
  .mb_lp--95 {
    margin-bottom: 95px; }
  .ptb_lp--100 {
    padding: 100px 0; }
  .plr_lp--100 {
    padding: 0 100px; }
  .pt_lp--100 {
    padding-top: 100px; }
  .pb_lp--100 {
    padding-bottom: 100px; }
  .pl_lp--100 {
    padding-left: 100px; }
  .pr_lp--100 {
    padding-right: 100px; }
  .mt_lp--100 {
    margin-top: 100px; }
  .mb_lp--100 {
    margin-bottom: 100px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ptb_lg--5 {
    padding: 5px 0; }
  .plr_lg--5 {
    padding: 0 5px; }
  .pt_lg--5 {
    padding-top: 5px; }
  .pb_lg--5 {
    padding-bottom: 5px; }
  .pl_lg--5 {
    padding-left: 5px; }
  .pr_lg--5 {
    padding-right: 5px; }
  .mt_lg--5 {
    margin-top: 5px; }
  .mb_lg--5 {
    margin-bottom: 5px; }
  .ptb_lg--10 {
    padding: 10px 0; }
  .plr_lg--10 {
    padding: 0 10px; }
  .pt_lg--10 {
    padding-top: 10px; }
  .pb_lg--10 {
    padding-bottom: 10px; }
  .pl_lg--10 {
    padding-left: 10px; }
  .pr_lg--10 {
    padding-right: 10px; }
  .mt_lg--10 {
    margin-top: 10px; }
  .mb_lg--10 {
    margin-bottom: 10px; }
  .ptb_lg--15 {
    padding: 15px 0; }
  .plr_lg--15 {
    padding: 0 15px; }
  .pt_lg--15 {
    padding-top: 15px; }
  .pb_lg--15 {
    padding-bottom: 15px; }
  .pl_lg--15 {
    padding-left: 15px; }
  .pr_lg--15 {
    padding-right: 15px; }
  .mt_lg--15 {
    margin-top: 15px; }
  .mb_lg--15 {
    margin-bottom: 15px; }
  .ptb_lg--20 {
    padding: 20px 0; }
  .plr_lg--20 {
    padding: 0 20px; }
  .pt_lg--20 {
    padding-top: 20px; }
  .pb_lg--20 {
    padding-bottom: 20px; }
  .pl_lg--20 {
    padding-left: 20px; }
  .pr_lg--20 {
    padding-right: 20px; }
  .mt_lg--20 {
    margin-top: 20px; }
  .mb_lg--20 {
    margin-bottom: 20px; }
  .ptb_lg--25 {
    padding: 25px 0; }
  .plr_lg--25 {
    padding: 0 25px; }
  .pt_lg--25 {
    padding-top: 25px; }
  .pb_lg--25 {
    padding-bottom: 25px; }
  .pl_lg--25 {
    padding-left: 25px; }
  .pr_lg--25 {
    padding-right: 25px; }
  .mt_lg--25 {
    margin-top: 25px; }
  .mb_lg--25 {
    margin-bottom: 25px; }
  .ptb_lg--30 {
    padding: 30px 0; }
  .plr_lg--30 {
    padding: 0 30px; }
  .pt_lg--30 {
    padding-top: 30px; }
  .pb_lg--30 {
    padding-bottom: 30px; }
  .pl_lg--30 {
    padding-left: 30px; }
  .pr_lg--30 {
    padding-right: 30px; }
  .mt_lg--30 {
    margin-top: 30px; }
  .mb_lg--30 {
    margin-bottom: 30px; }
  .ptb_lg--35 {
    padding: 35px 0; }
  .plr_lg--35 {
    padding: 0 35px; }
  .pt_lg--35 {
    padding-top: 35px; }
  .pb_lg--35 {
    padding-bottom: 35px; }
  .pl_lg--35 {
    padding-left: 35px; }
  .pr_lg--35 {
    padding-right: 35px; }
  .mt_lg--35 {
    margin-top: 35px; }
  .mb_lg--35 {
    margin-bottom: 35px; }
  .ptb_lg--40 {
    padding: 40px 0; }
  .plr_lg--40 {
    padding: 0 40px; }
  .pt_lg--40 {
    padding-top: 40px; }
  .pb_lg--40 {
    padding-bottom: 40px; }
  .pl_lg--40 {
    padding-left: 40px; }
  .pr_lg--40 {
    padding-right: 40px; }
  .mt_lg--40 {
    margin-top: 40px; }
  .mb_lg--40 {
    margin-bottom: 40px; }
  .ptb_lg--45 {
    padding: 45px 0; }
  .plr_lg--45 {
    padding: 0 45px; }
  .pt_lg--45 {
    padding-top: 45px; }
  .pb_lg--45 {
    padding-bottom: 45px; }
  .pl_lg--45 {
    padding-left: 45px; }
  .pr_lg--45 {
    padding-right: 45px; }
  .mt_lg--45 {
    margin-top: 45px; }
  .mb_lg--45 {
    margin-bottom: 45px; }
  .ptb_lg--50 {
    padding: 50px 0; }
  .plr_lg--50 {
    padding: 0 50px; }
  .pt_lg--50 {
    padding-top: 50px; }
  .pb_lg--50 {
    padding-bottom: 50px; }
  .pl_lg--50 {
    padding-left: 50px; }
  .pr_lg--50 {
    padding-right: 50px; }
  .mt_lg--50 {
    margin-top: 50px; }
  .mb_lg--50 {
    margin-bottom: 50px; }
  .ptb_lg--55 {
    padding: 55px 0; }
  .plr_lg--55 {
    padding: 0 55px; }
  .pt_lg--55 {
    padding-top: 55px; }
  .pb_lg--55 {
    padding-bottom: 55px; }
  .pl_lg--55 {
    padding-left: 55px; }
  .pr_lg--55 {
    padding-right: 55px; }
  .mt_lg--55 {
    margin-top: 55px; }
  .mb_lg--55 {
    margin-bottom: 55px; }
  .ptb_lg--60 {
    padding: 60px 0; }
  .plr_lg--60 {
    padding: 0 60px; }
  .pt_lg--60 {
    padding-top: 60px; }
  .pb_lg--60 {
    padding-bottom: 60px; }
  .pl_lg--60 {
    padding-left: 60px; }
  .pr_lg--60 {
    padding-right: 60px; }
  .mt_lg--60 {
    margin-top: 60px; }
  .mb_lg--60 {
    margin-bottom: 60px; }
  .ptb_lg--65 {
    padding: 65px 0; }
  .plr_lg--65 {
    padding: 0 65px; }
  .pt_lg--65 {
    padding-top: 65px; }
  .pb_lg--65 {
    padding-bottom: 65px; }
  .pl_lg--65 {
    padding-left: 65px; }
  .pr_lg--65 {
    padding-right: 65px; }
  .mt_lg--65 {
    margin-top: 65px; }
  .mb_lg--65 {
    margin-bottom: 65px; }
  .ptb_lg--70 {
    padding: 70px 0; }
  .plr_lg--70 {
    padding: 0 70px; }
  .pt_lg--70 {
    padding-top: 70px; }
  .pb_lg--70 {
    padding-bottom: 70px; }
  .pl_lg--70 {
    padding-left: 70px; }
  .pr_lg--70 {
    padding-right: 70px; }
  .mt_lg--70 {
    margin-top: 70px; }
  .mb_lg--70 {
    margin-bottom: 70px; }
  .ptb_lg--75 {
    padding: 75px 0; }
  .plr_lg--75 {
    padding: 0 75px; }
  .pt_lg--75 {
    padding-top: 75px; }
  .pb_lg--75 {
    padding-bottom: 75px; }
  .pl_lg--75 {
    padding-left: 75px; }
  .pr_lg--75 {
    padding-right: 75px; }
  .mt_lg--75 {
    margin-top: 75px; }
  .mb_lg--75 {
    margin-bottom: 75px; }
  .ptb_lg--80 {
    padding: 80px 0; }
  .plr_lg--80 {
    padding: 0 80px; }
  .pt_lg--80 {
    padding-top: 80px; }
  .pb_lg--80 {
    padding-bottom: 80px; }
  .pl_lg--80 {
    padding-left: 80px; }
  .pr_lg--80 {
    padding-right: 80px; }
  .mt_lg--80 {
    margin-top: 80px; }
  .mb_lg--80 {
    margin-bottom: 80px; }
  .ptb_lg--85 {
    padding: 85px 0; }
  .plr_lg--85 {
    padding: 0 85px; }
  .pt_lg--85 {
    padding-top: 85px; }
  .pb_lg--85 {
    padding-bottom: 85px; }
  .pl_lg--85 {
    padding-left: 85px; }
  .pr_lg--85 {
    padding-right: 85px; }
  .mt_lg--85 {
    margin-top: 85px; }
  .mb_lg--85 {
    margin-bottom: 85px; }
  .ptb_lg--90 {
    padding: 90px 0; }
  .plr_lg--90 {
    padding: 0 90px; }
  .pt_lg--90 {
    padding-top: 90px; }
  .pb_lg--90 {
    padding-bottom: 90px; }
  .pl_lg--90 {
    padding-left: 90px; }
  .pr_lg--90 {
    padding-right: 90px; }
  .mt_lg--90 {
    margin-top: 90px; }
  .mb_lg--90 {
    margin-bottom: 90px; }
  .ptb_lg--95 {
    padding: 95px 0; }
  .plr_lg--95 {
    padding: 0 95px; }
  .pt_lg--95 {
    padding-top: 95px; }
  .pb_lg--95 {
    padding-bottom: 95px; }
  .pl_lg--95 {
    padding-left: 95px; }
  .pr_lg--95 {
    padding-right: 95px; }
  .mt_lg--95 {
    margin-top: 95px; }
  .mb_lg--95 {
    margin-bottom: 95px; }
  .ptb_lg--100 {
    padding: 100px 0; }
  .plr_lg--100 {
    padding: 0 100px; }
  .pt_lg--100 {
    padding-top: 100px; }
  .pb_lg--100 {
    padding-bottom: 100px; }
  .pl_lg--100 {
    padding-left: 100px; }
  .pr_lg--100 {
    padding-right: 100px; }
  .mt_lg--100 {
    margin-top: 100px; }
  .mb_lg--100 {
    margin-bottom: 100px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb_md--0 {
    padding: 0; }
  .pl_md--0 {
    padding-left: 0; }
  .pr_md--0 {
    padding-right: 0; }
  .pt_md--0 {
    padding-top: 0; }
  .pb_md--0 {
    padding-bottom: 0; }
  .mr_md--0 {
    margin-right: 0; }
  .ml_md--0 {
    margin-left: 0; }
  .mt_md--0 {
    margin-top: 0; }
  .mb_md--0 {
    margin-bottom: 0; }
  .ptb_md--250 {
    padding: 250px 0; }
  .ptb_md--5 {
    padding: 5px 0; }
  .plr_md--5 {
    padding: 0 5px; }
  .pt_md--5 {
    padding-top: 5px; }
  .pb_md--5 {
    padding-bottom: 5px; }
  .pl_md--5 {
    padding-left: 5px; }
  .pr_md--5 {
    padding-right: 5px; }
  .mt_md--5 {
    margin-top: 5px; }
  .mb_md--5 {
    margin-bottom: 5px; }
  .ptb_md--10 {
    padding: 10px 0; }
  .plr_md--10 {
    padding: 0 10px; }
  .pt_md--10 {
    padding-top: 10px; }
  .pb_md--10 {
    padding-bottom: 10px; }
  .pl_md--10 {
    padding-left: 10px; }
  .pr_md--10 {
    padding-right: 10px; }
  .mt_md--10 {
    margin-top: 10px; }
  .mb_md--10 {
    margin-bottom: 10px; }
  .ptb_md--15 {
    padding: 15px 0; }
  .plr_md--15 {
    padding: 0 15px; }
  .pt_md--15 {
    padding-top: 15px; }
  .pb_md--15 {
    padding-bottom: 15px; }
  .pl_md--15 {
    padding-left: 15px; }
  .pr_md--15 {
    padding-right: 15px; }
  .mt_md--15 {
    margin-top: 15px; }
  .mb_md--15 {
    margin-bottom: 15px; }
  .ptb_md--20 {
    padding: 20px 0; }
  .plr_md--20 {
    padding: 0 20px; }
  .pt_md--20 {
    padding-top: 20px; }
  .pb_md--20 {
    padding-bottom: 20px; }
  .pl_md--20 {
    padding-left: 20px; }
  .pr_md--20 {
    padding-right: 20px; }
  .mt_md--20 {
    margin-top: 20px; }
  .mb_md--20 {
    margin-bottom: 20px; }
  .ptb_md--25 {
    padding: 25px 0; }
  .plr_md--25 {
    padding: 0 25px; }
  .pt_md--25 {
    padding-top: 25px; }
  .pb_md--25 {
    padding-bottom: 25px; }
  .pl_md--25 {
    padding-left: 25px; }
  .pr_md--25 {
    padding-right: 25px; }
  .mt_md--25 {
    margin-top: 25px; }
  .mb_md--25 {
    margin-bottom: 25px; }
  .ptb_md--30 {
    padding: 30px 0; }
  .plr_md--30 {
    padding: 0 30px; }
  .pt_md--30 {
    padding-top: 30px; }
  .pb_md--30 {
    padding-bottom: 30px; }
  .pl_md--30 {
    padding-left: 30px; }
  .pr_md--30 {
    padding-right: 30px; }
  .mt_md--30 {
    margin-top: 30px; }
  .mb_md--30 {
    margin-bottom: 30px; }
  .ptb_md--35 {
    padding: 35px 0; }
  .plr_md--35 {
    padding: 0 35px; }
  .pt_md--35 {
    padding-top: 35px; }
  .pb_md--35 {
    padding-bottom: 35px; }
  .pl_md--35 {
    padding-left: 35px; }
  .pr_md--35 {
    padding-right: 35px; }
  .mt_md--35 {
    margin-top: 35px; }
  .mb_md--35 {
    margin-bottom: 35px; }
  .ptb_md--40 {
    padding: 40px 0; }
  .plr_md--40 {
    padding: 0 40px; }
  .pt_md--40 {
    padding-top: 40px; }
  .pb_md--40 {
    padding-bottom: 40px; }
  .pl_md--40 {
    padding-left: 40px; }
  .pr_md--40 {
    padding-right: 40px; }
  .mt_md--40 {
    margin-top: 40px; }
  .mb_md--40 {
    margin-bottom: 40px; }
  .ptb_md--45 {
    padding: 45px 0; }
  .plr_md--45 {
    padding: 0 45px; }
  .pt_md--45 {
    padding-top: 45px; }
  .pb_md--45 {
    padding-bottom: 45px; }
  .pl_md--45 {
    padding-left: 45px; }
  .pr_md--45 {
    padding-right: 45px; }
  .mt_md--45 {
    margin-top: 45px; }
  .mb_md--45 {
    margin-bottom: 45px; }
  .ptb_md--50 {
    padding: 50px 0; }
  .plr_md--50 {
    padding: 0 50px; }
  .pt_md--50 {
    padding-top: 50px; }
  .pb_md--50 {
    padding-bottom: 50px; }
  .pl_md--50 {
    padding-left: 50px; }
  .pr_md--50 {
    padding-right: 50px; }
  .mt_md--50 {
    margin-top: 50px; }
  .mb_md--50 {
    margin-bottom: 50px; }
  .ptb_md--55 {
    padding: 55px 0; }
  .plr_md--55 {
    padding: 0 55px; }
  .pt_md--55 {
    padding-top: 55px; }
  .pb_md--55 {
    padding-bottom: 55px; }
  .pl_md--55 {
    padding-left: 55px; }
  .pr_md--55 {
    padding-right: 55px; }
  .mt_md--55 {
    margin-top: 55px; }
  .mb_md--55 {
    margin-bottom: 55px; }
  .ptb_md--60 {
    padding: 60px 0; }
  .plr_md--60 {
    padding: 0 60px; }
  .pt_md--60 {
    padding-top: 60px; }
  .pb_md--60 {
    padding-bottom: 60px; }
  .pl_md--60 {
    padding-left: 60px; }
  .pr_md--60 {
    padding-right: 60px; }
  .mt_md--60 {
    margin-top: 60px; }
  .mb_md--60 {
    margin-bottom: 60px; }
  .ptb_md--65 {
    padding: 65px 0; }
  .plr_md--65 {
    padding: 0 65px; }
  .pt_md--65 {
    padding-top: 65px; }
  .pb_md--65 {
    padding-bottom: 65px; }
  .pl_md--65 {
    padding-left: 65px; }
  .pr_md--65 {
    padding-right: 65px; }
  .mt_md--65 {
    margin-top: 65px; }
  .mb_md--65 {
    margin-bottom: 65px; }
  .ptb_md--70 {
    padding: 70px 0; }
  .plr_md--70 {
    padding: 0 70px; }
  .pt_md--70 {
    padding-top: 70px; }
  .pb_md--70 {
    padding-bottom: 70px; }
  .pl_md--70 {
    padding-left: 70px; }
  .pr_md--70 {
    padding-right: 70px; }
  .mt_md--70 {
    margin-top: 70px; }
  .mb_md--70 {
    margin-bottom: 70px; }
  .ptb_md--75 {
    padding: 75px 0; }
  .plr_md--75 {
    padding: 0 75px; }
  .pt_md--75 {
    padding-top: 75px; }
  .pb_md--75 {
    padding-bottom: 75px; }
  .pl_md--75 {
    padding-left: 75px; }
  .pr_md--75 {
    padding-right: 75px; }
  .mt_md--75 {
    margin-top: 75px; }
  .mb_md--75 {
    margin-bottom: 75px; }
  .ptb_md--80 {
    padding: 80px 0; }
  .plr_md--80 {
    padding: 0 80px; }
  .pt_md--80 {
    padding-top: 80px; }
  .pb_md--80 {
    padding-bottom: 80px; }
  .pl_md--80 {
    padding-left: 80px; }
  .pr_md--80 {
    padding-right: 80px; }
  .mt_md--80 {
    margin-top: 80px; }
  .mb_md--80 {
    margin-bottom: 80px; }
  .ptb_md--85 {
    padding: 85px 0; }
  .plr_md--85 {
    padding: 0 85px; }
  .pt_md--85 {
    padding-top: 85px; }
  .pb_md--85 {
    padding-bottom: 85px; }
  .pl_md--85 {
    padding-left: 85px; }
  .pr_md--85 {
    padding-right: 85px; }
  .mt_md--85 {
    margin-top: 85px; }
  .mb_md--85 {
    margin-bottom: 85px; }
  .ptb_md--90 {
    padding: 90px 0; }
  .plr_md--90 {
    padding: 0 90px; }
  .pt_md--90 {
    padding-top: 90px; }
  .pb_md--90 {
    padding-bottom: 90px; }
  .pl_md--90 {
    padding-left: 90px; }
  .pr_md--90 {
    padding-right: 90px; }
  .mt_md--90 {
    margin-top: 90px; }
  .mb_md--90 {
    margin-bottom: 90px; }
  .ptb_md--95 {
    padding: 95px 0; }
  .plr_md--95 {
    padding: 0 95px; }
  .pt_md--95 {
    padding-top: 95px; }
  .pb_md--95 {
    padding-bottom: 95px; }
  .pl_md--95 {
    padding-left: 95px; }
  .pr_md--95 {
    padding-right: 95px; }
  .mt_md--95 {
    margin-top: 95px; }
  .mb_md--95 {
    margin-bottom: 95px; }
  .ptb_md--100 {
    padding: 100px 0; }
  .plr_md--100 {
    padding: 0 100px; }
  .pt_md--100 {
    padding-top: 100px; }
  .pb_md--100 {
    padding-bottom: 100px; }
  .pl_md--100 {
    padding-left: 100px; }
  .pr_md--100 {
    padding-right: 100px; }
  .mt_md--100 {
    margin-top: 100px; }
  .mb_md--100 {
    margin-bottom: 100px; } }

@media only screen and (max-width: 767px) {
  .ptb_sm--250 {
    padding: 250px 0; }
  .ptb_sm--0 {
    padding: 0; }
  .pl_sm--0 {
    padding-left: 0; }
  .pr_sm--0 {
    padding-right: 0; }
  .pt_sm--0 {
    padding-top: 0; }
  .pb_sm--0 {
    padding-bottom: 0; }
  .mr_sm--0 {
    margin-right: 0; }
  .ml_sm--0 {
    margin-left: 0; }
  .mt_sm--0 {
    margin-top: 0; }
  .mb_sm--0 {
    margin-bottom: 0; }
  .ptb_sm--5 {
    padding: 5px 0; }
  .plr_sm--5 {
    padding: 0 5px; }
  .pt_sm--5 {
    padding-top: 5px; }
  .pb_sm--5 {
    padding-bottom: 5px; }
  .pl_sm--5 {
    padding-left: 5px; }
  .pr_sm--5 {
    padding-right: 5px; }
  .mt_sm--5 {
    margin-top: 5px; }
  .mb_sm--5 {
    margin-bottom: 5px; }
  .ptb_sm--10 {
    padding: 10px 0; }
  .plr_sm--10 {
    padding: 0 10px; }
  .pt_sm--10 {
    padding-top: 10px; }
  .pb_sm--10 {
    padding-bottom: 10px; }
  .pl_sm--10 {
    padding-left: 10px; }
  .pr_sm--10 {
    padding-right: 10px; }
  .mt_sm--10 {
    margin-top: 10px; }
  .mb_sm--10 {
    margin-bottom: 10px; }
  .ptb_sm--15 {
    padding: 15px 0; }
  .plr_sm--15 {
    padding: 0 15px; }
  .pt_sm--15 {
    padding-top: 15px; }
  .pb_sm--15 {
    padding-bottom: 15px; }
  .pl_sm--15 {
    padding-left: 15px; }
  .pr_sm--15 {
    padding-right: 15px; }
  .mt_sm--15 {
    margin-top: 15px; }
  .mb_sm--15 {
    margin-bottom: 15px; }
  .ptb_sm--20 {
    padding: 20px 0; }
  .plr_sm--20 {
    padding: 0 20px; }
  .pt_sm--20 {
    padding-top: 20px; }
  .pb_sm--20 {
    padding-bottom: 20px; }
  .pl_sm--20 {
    padding-left: 20px; }
  .pr_sm--20 {
    padding-right: 20px; }
  .mt_sm--20 {
    margin-top: 20px; }
  .mb_sm--20 {
    margin-bottom: 20px; }
  .ptb_sm--25 {
    padding: 25px 0; }
  .plr_sm--25 {
    padding: 0 25px; }
  .pt_sm--25 {
    padding-top: 25px; }
  .pb_sm--25 {
    padding-bottom: 25px; }
  .pl_sm--25 {
    padding-left: 25px; }
  .pr_sm--25 {
    padding-right: 25px; }
  .mt_sm--25 {
    margin-top: 25px; }
  .mb_sm--25 {
    margin-bottom: 25px; }
  .ptb_sm--30 {
    padding: 30px 0; }
  .plr_sm--30 {
    padding: 0 30px; }
  .pt_sm--30 {
    padding-top: 30px; }
  .pb_sm--30 {
    padding-bottom: 30px; }
  .pl_sm--30 {
    padding-left: 30px; }
  .pr_sm--30 {
    padding-right: 30px; }
  .mt_sm--30 {
    margin-top: 30px; }
  .mb_sm--30 {
    margin-bottom: 30px; }
  .ptb_sm--35 {
    padding: 35px 0; }
  .plr_sm--35 {
    padding: 0 35px; }
  .pt_sm--35 {
    padding-top: 35px; }
  .pb_sm--35 {
    padding-bottom: 35px; }
  .pl_sm--35 {
    padding-left: 35px; }
  .pr_sm--35 {
    padding-right: 35px; }
  .mt_sm--35 {
    margin-top: 35px; }
  .mb_sm--35 {
    margin-bottom: 35px; }
  .ptb_sm--40 {
    padding: 40px 0; }
  .plr_sm--40 {
    padding: 0 40px; }
  .pt_sm--40 {
    padding-top: 40px; }
  .pb_sm--40 {
    padding-bottom: 40px; }
  .pl_sm--40 {
    padding-left: 40px; }
  .pr_sm--40 {
    padding-right: 40px; }
  .mt_sm--40 {
    margin-top: 40px; }
  .mb_sm--40 {
    margin-bottom: 40px; }
  .ptb_sm--45 {
    padding: 45px 0; }
  .plr_sm--45 {
    padding: 0 45px; }
  .pt_sm--45 {
    padding-top: 45px; }
  .pb_sm--45 {
    padding-bottom: 45px; }
  .pl_sm--45 {
    padding-left: 45px; }
  .pr_sm--45 {
    padding-right: 45px; }
  .mt_sm--45 {
    margin-top: 45px; }
  .mb_sm--45 {
    margin-bottom: 45px; }
  .ptb_sm--50 {
    padding: 50px 0; }
  .plr_sm--50 {
    padding: 0 50px; }
  .pt_sm--50 {
    padding-top: 50px; }
  .pb_sm--50 {
    padding-bottom: 50px; }
  .pl_sm--50 {
    padding-left: 50px; }
  .pr_sm--50 {
    padding-right: 50px; }
  .mt_sm--50 {
    margin-top: 50px; }
  .mb_sm--50 {
    margin-bottom: 50px; }
  .ptb_sm--55 {
    padding: 55px 0; }
  .plr_sm--55 {
    padding: 0 55px; }
  .pt_sm--55 {
    padding-top: 55px; }
  .pb_sm--55 {
    padding-bottom: 55px; }
  .pl_sm--55 {
    padding-left: 55px; }
  .pr_sm--55 {
    padding-right: 55px; }
  .mt_sm--55 {
    margin-top: 55px; }
  .mb_sm--55 {
    margin-bottom: 55px; }
  .ptb_sm--60 {
    padding: 60px 0; }
  .plr_sm--60 {
    padding: 0 60px; }
  .pt_sm--60 {
    padding-top: 60px; }
  .pb_sm--60 {
    padding-bottom: 60px; }
  .pl_sm--60 {
    padding-left: 60px; }
  .pr_sm--60 {
    padding-right: 60px; }
  .mt_sm--60 {
    margin-top: 60px; }
  .mb_sm--60 {
    margin-bottom: 60px; }
  .ptb_sm--65 {
    padding: 65px 0; }
  .plr_sm--65 {
    padding: 0 65px; }
  .pt_sm--65 {
    padding-top: 65px; }
  .pb_sm--65 {
    padding-bottom: 65px; }
  .pl_sm--65 {
    padding-left: 65px; }
  .pr_sm--65 {
    padding-right: 65px; }
  .mt_sm--65 {
    margin-top: 65px; }
  .mb_sm--65 {
    margin-bottom: 65px; }
  .ptb_sm--70 {
    padding: 70px 0; }
  .plr_sm--70 {
    padding: 0 70px; }
  .pt_sm--70 {
    padding-top: 70px; }
  .pb_sm--70 {
    padding-bottom: 70px; }
  .pl_sm--70 {
    padding-left: 70px; }
  .pr_sm--70 {
    padding-right: 70px; }
  .mt_sm--70 {
    margin-top: 70px; }
  .mb_sm--70 {
    margin-bottom: 70px; }
  .ptb_sm--75 {
    padding: 75px 0; }
  .plr_sm--75 {
    padding: 0 75px; }
  .pt_sm--75 {
    padding-top: 75px; }
  .pb_sm--75 {
    padding-bottom: 75px; }
  .pl_sm--75 {
    padding-left: 75px; }
  .pr_sm--75 {
    padding-right: 75px; }
  .mt_sm--75 {
    margin-top: 75px; }
  .mb_sm--75 {
    margin-bottom: 75px; }
  .ptb_sm--80 {
    padding: 80px 0; }
  .plr_sm--80 {
    padding: 0 80px; }
  .pt_sm--80 {
    padding-top: 80px; }
  .pb_sm--80 {
    padding-bottom: 80px; }
  .pl_sm--80 {
    padding-left: 80px; }
  .pr_sm--80 {
    padding-right: 80px; }
  .mt_sm--80 {
    margin-top: 80px; }
  .mb_sm--80 {
    margin-bottom: 80px; }
  .ptb_sm--85 {
    padding: 85px 0; }
  .plr_sm--85 {
    padding: 0 85px; }
  .pt_sm--85 {
    padding-top: 85px; }
  .pb_sm--85 {
    padding-bottom: 85px; }
  .pl_sm--85 {
    padding-left: 85px; }
  .pr_sm--85 {
    padding-right: 85px; }
  .mt_sm--85 {
    margin-top: 85px; }
  .mb_sm--85 {
    margin-bottom: 85px; }
  .ptb_sm--90 {
    padding: 90px 0; }
  .plr_sm--90 {
    padding: 0 90px; }
  .pt_sm--90 {
    padding-top: 90px; }
  .pb_sm--90 {
    padding-bottom: 90px; }
  .pl_sm--90 {
    padding-left: 90px; }
  .pr_sm--90 {
    padding-right: 90px; }
  .mt_sm--90 {
    margin-top: 90px; }
  .mb_sm--90 {
    margin-bottom: 90px; }
  .ptb_sm--95 {
    padding: 95px 0; }
  .plr_sm--95 {
    padding: 0 95px; }
  .pt_sm--95 {
    padding-top: 95px; }
  .pb_sm--95 {
    padding-bottom: 95px; }
  .pl_sm--95 {
    padding-left: 95px; }
  .pr_sm--95 {
    padding-right: 95px; }
  .mt_sm--95 {
    margin-top: 95px; }
  .mb_sm--95 {
    margin-bottom: 95px; }
  .ptb_sm--100 {
    padding: 100px 0; }
  .plr_sm--100 {
    padding: 0 100px; }
  .pt_sm--100 {
    padding-top: 100px; }
  .pb_sm--100 {
    padding-bottom: 100px; }
  .pl_sm--100 {
    padding-left: 100px; }
  .pr_sm--100 {
    padding-right: 100px; }
  .mt_sm--100 {
    margin-top: 100px; }
  .mb_sm--100 {
    margin-bottom: 100px; }
  .pl_sm--0 {
    padding-left: 0; }
  .pr_sm--0 {
    padding-right: 0; }
  .pt_sm--0 {
    padding-top: 0; }
  .pb_sm--0 {
    padding-bottom: 0; }
  .mr_sm--0 {
    margin-right: 0; }
  .ml_sm--0 {
    margin-left: 0; }
  .mt_sm--0 {
    margin-top: 0; }
  .mb_sm--0 {
    margin-bottom: 0; } }

@media only screen and (max-width: 575px) {
  .ptb_mobile--5 {
    padding: 5px 0; }
  .plr_mobile--5 {
    padding: 0 5px; }
  .pt_mobile--5 {
    padding-top: 5px; }
  .pb_mobile--5 {
    padding-bottom: 5px; }
  .pl_mobile--5 {
    padding-left: 5px; }
  .pr_mobile--5 {
    padding-right: 5px; }
  .mt_mobile--5 {
    margin-top: 5px; }
  .mb_mobile--5 {
    margin-bottom: 5px; }
  .ptb_mobile--10 {
    padding: 10px 0; }
  .plr_mobile--10 {
    padding: 0 10px; }
  .pt_mobile--10 {
    padding-top: 10px; }
  .pb_mobile--10 {
    padding-bottom: 10px; }
  .pl_mobile--10 {
    padding-left: 10px; }
  .pr_mobile--10 {
    padding-right: 10px; }
  .mt_mobile--10 {
    margin-top: 10px; }
  .mb_mobile--10 {
    margin-bottom: 10px; }
  .ptb_mobile--15 {
    padding: 15px 0; }
  .plr_mobile--15 {
    padding: 0 15px; }
  .pt_mobile--15 {
    padding-top: 15px; }
  .pb_mobile--15 {
    padding-bottom: 15px; }
  .pl_mobile--15 {
    padding-left: 15px; }
  .pr_mobile--15 {
    padding-right: 15px; }
  .mt_mobile--15 {
    margin-top: 15px; }
  .mb_mobile--15 {
    margin-bottom: 15px; }
  .ptb_mobile--20 {
    padding: 20px 0; }
  .plr_mobile--20 {
    padding: 0 20px; }
  .pt_mobile--20 {
    padding-top: 20px; }
  .pb_mobile--20 {
    padding-bottom: 20px; }
  .pl_mobile--20 {
    padding-left: 20px; }
  .pr_mobile--20 {
    padding-right: 20px; }
  .mt_mobile--20 {
    margin-top: 20px; }
  .mb_mobile--20 {
    margin-bottom: 20px; }
  .ptb_mobile--25 {
    padding: 25px 0; }
  .plr_mobile--25 {
    padding: 0 25px; }
  .pt_mobile--25 {
    padding-top: 25px; }
  .pb_mobile--25 {
    padding-bottom: 25px; }
  .pl_mobile--25 {
    padding-left: 25px; }
  .pr_mobile--25 {
    padding-right: 25px; }
  .mt_mobile--25 {
    margin-top: 25px; }
  .mb_mobile--25 {
    margin-bottom: 25px; }
  .ptb_mobile--30 {
    padding: 30px 0; }
  .plr_mobile--30 {
    padding: 0 30px; }
  .pt_mobile--30 {
    padding-top: 30px; }
  .pb_mobile--30 {
    padding-bottom: 30px; }
  .pl_mobile--30 {
    padding-left: 30px; }
  .pr_mobile--30 {
    padding-right: 30px; }
  .mt_mobile--30 {
    margin-top: 30px; }
  .mb_mobile--30 {
    margin-bottom: 30px; }
  .ptb_mobile--35 {
    padding: 35px 0; }
  .plr_mobile--35 {
    padding: 0 35px; }
  .pt_mobile--35 {
    padding-top: 35px; }
  .pb_mobile--35 {
    padding-bottom: 35px; }
  .pl_mobile--35 {
    padding-left: 35px; }
  .pr_mobile--35 {
    padding-right: 35px; }
  .mt_mobile--35 {
    margin-top: 35px; }
  .mb_mobile--35 {
    margin-bottom: 35px; }
  .ptb_mobile--40 {
    padding: 40px 0; }
  .plr_mobile--40 {
    padding: 0 40px; }
  .pt_mobile--40 {
    padding-top: 40px; }
  .pb_mobile--40 {
    padding-bottom: 40px; }
  .pl_mobile--40 {
    padding-left: 40px; }
  .pr_mobile--40 {
    padding-right: 40px; }
  .mt_mobile--40 {
    margin-top: 40px; }
  .mb_mobile--40 {
    margin-bottom: 40px; }
  .ptb_mobile--45 {
    padding: 45px 0; }
  .plr_mobile--45 {
    padding: 0 45px; }
  .pt_mobile--45 {
    padding-top: 45px; }
  .pb_mobile--45 {
    padding-bottom: 45px; }
  .pl_mobile--45 {
    padding-left: 45px; }
  .pr_mobile--45 {
    padding-right: 45px; }
  .mt_mobile--45 {
    margin-top: 45px; }
  .mb_mobile--45 {
    margin-bottom: 45px; }
  .ptb_mobile--50 {
    padding: 50px 0; }
  .plr_mobile--50 {
    padding: 0 50px; }
  .pt_mobile--50 {
    padding-top: 50px; }
  .pb_mobile--50 {
    padding-bottom: 50px; }
  .pl_mobile--50 {
    padding-left: 50px; }
  .pr_mobile--50 {
    padding-right: 50px; }
  .mt_mobile--50 {
    margin-top: 50px; }
  .mb_mobile--50 {
    margin-bottom: 50px; }
  .ptb_mobile--55 {
    padding: 55px 0; }
  .plr_mobile--55 {
    padding: 0 55px; }
  .pt_mobile--55 {
    padding-top: 55px; }
  .pb_mobile--55 {
    padding-bottom: 55px; }
  .pl_mobile--55 {
    padding-left: 55px; }
  .pr_mobile--55 {
    padding-right: 55px; }
  .mt_mobile--55 {
    margin-top: 55px; }
  .mb_mobile--55 {
    margin-bottom: 55px; }
  .ptb_mobile--60 {
    padding: 60px 0; }
  .plr_mobile--60 {
    padding: 0 60px; }
  .pt_mobile--60 {
    padding-top: 60px; }
  .pb_mobile--60 {
    padding-bottom: 60px; }
  .pl_mobile--60 {
    padding-left: 60px; }
  .pr_mobile--60 {
    padding-right: 60px; }
  .mt_mobile--60 {
    margin-top: 60px; }
  .mb_mobile--60 {
    margin-bottom: 60px; }
  .ptb_mobile--65 {
    padding: 65px 0; }
  .plr_mobile--65 {
    padding: 0 65px; }
  .pt_mobile--65 {
    padding-top: 65px; }
  .pb_mobile--65 {
    padding-bottom: 65px; }
  .pl_mobile--65 {
    padding-left: 65px; }
  .pr_mobile--65 {
    padding-right: 65px; }
  .mt_mobile--65 {
    margin-top: 65px; }
  .mb_mobile--65 {
    margin-bottom: 65px; }
  .ptb_mobile--70 {
    padding: 70px 0; }
  .plr_mobile--70 {
    padding: 0 70px; }
  .pt_mobile--70 {
    padding-top: 70px; }
  .pb_mobile--70 {
    padding-bottom: 70px; }
  .pl_mobile--70 {
    padding-left: 70px; }
  .pr_mobile--70 {
    padding-right: 70px; }
  .mt_mobile--70 {
    margin-top: 70px; }
  .mb_mobile--70 {
    margin-bottom: 70px; }
  .ptb_mobile--75 {
    padding: 75px 0; }
  .plr_mobile--75 {
    padding: 0 75px; }
  .pt_mobile--75 {
    padding-top: 75px; }
  .pb_mobile--75 {
    padding-bottom: 75px; }
  .pl_mobile--75 {
    padding-left: 75px; }
  .pr_mobile--75 {
    padding-right: 75px; }
  .mt_mobile--75 {
    margin-top: 75px; }
  .mb_mobile--75 {
    margin-bottom: 75px; }
  .ptb_mobile--80 {
    padding: 80px 0; }
  .plr_mobile--80 {
    padding: 0 80px; }
  .pt_mobile--80 {
    padding-top: 80px; }
  .pb_mobile--80 {
    padding-bottom: 80px; }
  .pl_mobile--80 {
    padding-left: 80px; }
  .pr_mobile--80 {
    padding-right: 80px; }
  .mt_mobile--80 {
    margin-top: 80px; }
  .mb_mobile--80 {
    margin-bottom: 80px; }
  .ptb_mobile--85 {
    padding: 85px 0; }
  .plr_mobile--85 {
    padding: 0 85px; }
  .pt_mobile--85 {
    padding-top: 85px; }
  .pb_mobile--85 {
    padding-bottom: 85px; }
  .pl_mobile--85 {
    padding-left: 85px; }
  .pr_mobile--85 {
    padding-right: 85px; }
  .mt_mobile--85 {
    margin-top: 85px; }
  .mb_mobile--85 {
    margin-bottom: 85px; }
  .ptb_mobile--90 {
    padding: 90px 0; }
  .plr_mobile--90 {
    padding: 0 90px; }
  .pt_mobile--90 {
    padding-top: 90px; }
  .pb_mobile--90 {
    padding-bottom: 90px; }
  .pl_mobile--90 {
    padding-left: 90px; }
  .pr_mobile--90 {
    padding-right: 90px; }
  .mt_mobile--90 {
    margin-top: 90px; }
  .mb_mobile--90 {
    margin-bottom: 90px; }
  .ptb_mobile--95 {
    padding: 95px 0; }
  .plr_mobile--95 {
    padding: 0 95px; }
  .pt_mobile--95 {
    padding-top: 95px; }
  .pb_mobile--95 {
    padding-bottom: 95px; }
  .pl_mobile--95 {
    padding-left: 95px; }
  .pr_mobile--95 {
    padding-right: 95px; }
  .mt_mobile--95 {
    margin-top: 95px; }
  .mb_mobile--95 {
    margin-bottom: 95px; }
  .ptb_mobile--100 {
    padding: 100px 0; }
  .plr_mobile--100 {
    padding: 0 100px; }
  .pt_mobile--100 {
    padding-top: 100px; }
  .pb_mobile--100 {
    padding-bottom: 100px; }
  .pl_mobile--100 {
    padding-left: 100px; }
  .pr_mobile--100 {
    padding-right: 100px; }
  .mt_mobile--100 {
    margin-top: 100px; }
  .mb_mobile--100 {
    margin-bottom: 100px; } }

.slick-gutter-5 {
  margin-left: -5px;
  margin-right: -5px; }
  .slick-gutter-5 .slick-slide {
    padding-left: 5px;
    padding-right: 5px; }

.slick-gutter-10 {
  margin-left: -10px;
  margin-right: -10px; }
  .slick-gutter-10 .slick-slide {
    padding-left: 10px;
    padding-right: 10px; }

.slick-gutter-15 {
  margin-left: -15px;
  margin-right: -15px; }
  .slick-gutter-15 .slick-slide {
    padding-left: 15px;
    padding-right: 15px; }

.slick-gutter-20 {
  margin-left: -20px;
  margin-right: -20px; }
  .slick-gutter-20 .slick-slide {
    padding-left: 20px;
    padding-right: 20px; }

.slick-gutter-25 {
  margin-left: -25px;
  margin-right: -25px; }
  .slick-gutter-25 .slick-slide {
    padding-left: 25px;
    padding-right: 25px; }

.slick-gutter-30 {
  margin-left: -30px;
  margin-right: -30px; }
  .slick-gutter-30 .slick-slide {
    padding-left: 30px;
    padding-right: 30px; }

.slick-gutter-35 {
  margin-left: -35px;
  margin-right: -35px; }
  .slick-gutter-35 .slick-slide {
    padding-left: 35px;
    padding-right: 35px; }

.slick-gutter-40 {
  margin-left: -40px;
  margin-right: -40px; }
  .slick-gutter-40 .slick-slide {
    padding-left: 40px;
    padding-right: 40px; }

.slick-gutter-45 {
  margin-left: -45px;
  margin-right: -45px; }
  .slick-gutter-45 .slick-slide {
    padding-left: 45px;
    padding-right: 45px; }

.slick-gutter-50 {
  margin-left: -50px;
  margin-right: -50px; }
  .slick-gutter-50 .slick-slide {
    padding-left: 50px;
    padding-right: 50px; }

.slick-gutter-55 {
  margin-left: -55px;
  margin-right: -55px; }
  .slick-gutter-55 .slick-slide {
    padding-left: 55px;
    padding-right: 55px; }

.slick-gutter-60 {
  margin-left: -60px;
  margin-right: -60px; }
  .slick-gutter-60 .slick-slide {
    padding-left: 60px;
    padding-right: 60px; }

.slick-gutter-65 {
  margin-left: -65px;
  margin-right: -65px; }
  .slick-gutter-65 .slick-slide {
    padding-left: 65px;
    padding-right: 65px; }

.slick-gutter-70 {
  margin-left: -70px;
  margin-right: -70px; }
  .slick-gutter-70 .slick-slide {
    padding-left: 70px;
    padding-right: 70px; }

.slick-gutter-75 {
  margin-left: -75px;
  margin-right: -75px; }
  .slick-gutter-75 .slick-slide {
    padding-left: 75px;
    padding-right: 75px; }

.slick-gutter-80 {
  margin-left: -80px;
  margin-right: -80px; }
  .slick-gutter-80 .slick-slide {
    padding-left: 80px;
    padding-right: 80px; }

.slick-gutter-85 {
  margin-left: -85px;
  margin-right: -85px; }
  .slick-gutter-85 .slick-slide {
    padding-left: 85px;
    padding-right: 85px; }

.slick-gutter-90 {
  margin-left: -90px;
  margin-right: -90px; }
  .slick-gutter-90 .slick-slide {
    padding-left: 90px;
    padding-right: 90px; }

.slick-gutter-95 {
  margin-left: -95px;
  margin-right: -95px; }
  .slick-gutter-95 .slick-slide {
    padding-left: 95px;
    padding-right: 95px; }

.slick-gutter-100 {
  margin-left: -100px;
  margin-right: -100px; }
  .slick-gutter-100 .slick-slide {
    padding-left: 100px;
    padding-right: 100px; }

/**************************************
    Header Styles
***************************************/
/*=======================
    Header Area 
========================*/
.color-picker {
  right: -190px; }

.app-header.header--transparent {
  left: 0;
  position: absolute;
  right: 0;
  top: 95px;
  z-index: 2; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .app-header.header--transparent {
      top: 60px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-header.header--transparent {
      top: 38px; } }
  @media only screen and (max-width: 767px) {
    .app-header.header--transparent {
      top: 38px; } }

.app-header .button-default {
  font-size: 11px;
  line-height: 32px;
  padding: 0 16px;
  background-color: #ffffff;
  color: #2d3e50;
  height: 32px; }
  .app-header .button-default:hover {
    background-color: #05C2F9;
    color: #ffffff; }

.mainmenu-wrapper {
  display: flex;
  justify-content: flex-end; }

.main-menu {
  display: flex;
  margin: 0; }
  .main-menu li a {
    color: #ffffff;
    display: block;
    font-family: "Raleway",sans-serif;
    font-size: 12px;
    font-weight: 800;
    line-height: 26px;
    margin-right: 34px;
    padding: 0 1px;
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    text-decoration: none;
    text-transform: uppercase; }
    .main-menu li a::before {
      background: #05C2F9 none repeat scroll 0 0;
      bottom: 0;
      content: "";
      height: 0;
      left: 50%;
      position: absolute;
      right: 50%;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: left, right;
      transition-property: left, right;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      z-index: -1; }
    .main-menu li a:hover::before {
      left: 0;
      right: 0;
      height: 2px; }
  .main-menu li.active a::before {
    left: 0;
    right: 0;
    height: 2px; }

.app-header.stick {
  background: #2d3e50 none repeat scroll 0 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  padding: 25px 0;
  position: fixed;
  top: 0;
  z-index: 99; }

.tap-top {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 150px;
  right: 30px;
  z-index: 99;
  color: white;
  text-align: center;
  background: #05C2F9;
  border-radius: 100%;
  font-size: 22px;
  cursor: pointer;
  line-height: 2.2;
  display: none;
  border: 1px solid white; }

/**************************************
    Element Styles
***************************************/
/*==================
    Button Area 
=====================*/
.button-default {
  background: #ffffff none repeat scroll 0 0;
  border: 0 none;
  color: #2d3e50;
  display: inline-block;
  font-family: "Raleway",sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 53px;
  padding: 0 31px;
  text-transform: uppercase;
  border-radius: 2px;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s; }
  .button-default:hover {
    background-color: #05C2F9;
    color: #ffffff; }
  .button-default.button-olive {
    background-color: #05C2F9;
    color: #ffffff; }
    .button-default.button-olive:hover {
      background: #ffffff none repeat scroll 0 0;
      color: #2d3e50; }
  .button-default.button-border {
    border: 1px solid #7a9757; }

/*=====================
    Section Title 
=======================*/
.section-title h2 {
  font-size: 36px;
  font-weight: 800;
  line-height: 33px;
  text-transform: uppercase; }
  @media only screen and (max-width: 767px) {
    .section-title h2 {
      font-size: 27px; } }

.section-title img {
  margin-bottom: 24px; }

.section-title p {
  padding: 0 27%; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-title p {
      padding: 0 19%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-title p {
      padding: 0 9%; } }
  @media only screen and (max-width: 767px) {
    .section-title p {
      padding: 0 5%; } }

/*=====================
    Service Area 
======================*/
.service-area {
  padding-bottom: 284px;
  padding-top: 306px;
  position: relative; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .service-area {
      padding-bottom: 120px;
      padding-top: 120px;
      position: relative;
      margin: 100px 0; } }
  @media only screen and (max-width: 767px) {
    .service-area {
      padding-bottom: 120px;
      padding-top: 120px;
      position: relative;
      margin: 100px 0; } }
  .service-area::after {
    background: #f0f0f0 none repeat scroll 0 0;
    content: "";
    height: 518px;
    left: 0;
    position: absolute;
    top: 10%;
    -webkit-transform: skewY(165deg);
    transform: skewY(165deg);
    width: 100%;
    z-index: -1; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .service-area::after {
        -webkit-transform: skewY(0) translateY(-50%);
                transform: skewY(0) translateY(-50%);
        top: 50%;
        height: 100%; } }
    @media only screen and (max-width: 767px) {
      .service-area::after {
        -webkit-transform: skewY(0) translateY(-50%);
                transform: skewY(0) translateY(-50%);
        top: 50%;
        height: 100%; } }
  .service-area.horizontal::after {
    -webkit-transform: skewY(0) translateY(-50%);
            transform: skewY(0) translateY(-50%);
    top: 50%; }
  .service-area.horizontal {
    padding-bottom: 100px;
    padding-top: 100px;
    position: relative; }
    .service-area.horizontal::after {
      width: 100%;
      height: 100%; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .service-area.horizontal {
        padding-bottom: 80px;
        padding-top: 80px;
        position: relative;
        margin: 0; } }
    @media only screen and (max-width: 767px) {
      .service-area.horizontal {
        padding-bottom: 80px;
        padding-top: 80px;
        position: relative;
        margin: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .service-area.vertical-service {
      margin: 0px 0 50px; } }
  @media only screen and (max-width: 767px) {
    .service-area.vertical-service {
      margin: 0px 0 50px; } }

.single-service .service-icon {
  background-color: #05C2F9;
  border-radius: 36px 36px 0;
  display: inline-table;
  height: 100px;
  margin-bottom: 24px;
  text-align: center;
  width: 100px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .single-service .service-icon i {
    color: #ffffff;
    display: table-cell;
    font-size: 50px;
    vertical-align: middle; }

.single-service .title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px; }

.single-service .desc {
  margin: 0 0 10px; }

.single-service:hover .service-icon {
  background-color: #2d3e50; }

.service-area .service-column:nth-child(2) {
  margin-top: -100px; }

.service-area .service-column:nth-child(3) {
  margin-top: -200px; }

/*========================
    Sirvice Horizontal 
==========================*/
.service-area.horizontal .service-column:nth-child(2) {
  margin-top: 0; }

.service-area.horizontal .service-column:nth-child(3) {
  margin-top: 0; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-area .service-column:nth-child(2) {
    margin-top: 30px; }
  .service-area .service-column:nth-child(3) {
    margin-top: 30px; }
  .service-area.horizontal .service-column:nth-child(2) {
    margin-top: 30px; }
  .service-area.horizontal .service-column:nth-child(3) {
    margin-top: 30px; } }

@media only screen and (max-width: 767px) {
  .service-area .service-column:nth-child(2) {
    margin-top: 30px; }
  .service-area .service-column:nth-child(3) {
    margin-top: 30px; }
  .service-area.horizontal .service-column:nth-child(2) {
    margin-top: 30px; }
  .service-area.horizontal .service-column:nth-child(3) {
    margin-top: 30px; } }

/*====================
Feature Area 
======================*/
.feature-bg-image {
  background-position: -8.5% 63%;
  background-size: 62% auto;
  background-image: url(/assets/images/bg/ICT-service-transparent.png);
  margin-top: -112px;
  padding-top: 35px;
  background-repeat: no-repeat;
  background-size: 63% auto;
  background-position: -11% 67%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .feature-bg-image {
      margin-top: 0;
      background-image: inherit; } }
  @media only screen and (max-width: 767px) {
    .feature-bg-image {
      margin-top: 0;
      background-image: inherit; } }

.feature-list {
  padding-left: 90px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .feature-list {
      padding: 0 50px; } }

.feature {
  margin-bottom: 96px;
  position: relative;
  z-index: 2;
  display: flex; }
  @media only screen and (max-width: 767px) {
    .feature {
      display: block;
      margin-bottom: 50px; } }
  .feature .feature-icon {
    margin-right: 38px;
    background-color: #05C2F9;
    border-radius: 36px 36px 0;
    display: inline-table;
    height: 100px;
    margin-bottom: 24px;
    text-align: center;
    width: 100px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .feature .feature-icon i {
      color: #ffffff;
      display: table-cell;
      font-size: 50px;
      vertical-align: middle; }
  .feature .content {
    width: 408px; }
    @media only screen and (max-width: 767px) {
      .feature .content {
        width: auto; } }
    .feature .content .title {
      line-height: 21px;
      margin-bottom: 11px;
      font-size: 28px;
      font-weight: 600; }
      @media only screen and (max-width: 767px) {
        .feature .content .title {
          margin-bottom: 15px;
          font-size: 22px; } }
    .feature .content .desc {
      margin-bottom: 0; }

.feature-list .feature:first-child,
.feature-list .feature:last-child {
  margin-left: -50px; }

.feature-list .feature:nth-child(2),
.feature-list .feature:nth-child(4) {
  margin-left: 56px; }

.feature-list .feature:nth-child(3) {
  margin-left: 138px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-list .feature:first-child,
  .feature-list .feature:last-child,
  .feature-list .feature:nth-child(2),
  .feature-list .feature:nth-child(4),
  .feature-list .feature:nth-child(3) {
    margin-left: 0; } }

@media only screen and (max-width: 767px) {
  .feature-list .feature:first-child,
  .feature-list .feature:last-child,
  .feature-list .feature:nth-child(2),
  .feature-list .feature:nth-child(4),
  .feature-list .feature:nth-child(3) {
    margin-left: 0; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-area.vertical-feature {
    position: relative;
    margin-bottom: -200px;
    z-index: 5; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-area.vertical-feature {
    padding-bottom: 0; } }

.feature-area.horizontal-feature {
  margin-top: 0;
  padding-top: 120px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .feature-area.horizontal-feature {
      margin-top: 0;
      padding-top: 120px;
      padding-bottom: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .feature-area.horizontal-feature {
      margin-top: 0;
      padding-top: 80px;
      padding-bottom: 0; } }
  @media only screen and (max-width: 767px) {
    .feature-area.horizontal-feature {
      margin-top: 0;
      padding-top: 80px;
      padding-bottom: 40px; } }

/*==================
    Pricing Table 
=====================*/
.pricing-table-area .pricing-column:first-child .single-price-package {
  margin-left: 60px;
  margin-right: 12px; }

.pricing-table-area .pricing-column:last-child .single-price-package {
  margin-right: 60px;
  margin-left: 12px; }

.single-price-package {
  box-shadow: 0 0 20px 1px rgba(35, 31, 32, 0.2);
  text-align: center; }
  .single-price-package .price-title {
    background: #2D3E50 none repeat scroll 0 0;
    padding: 35px 0 34px; }
    .single-price-package .price-title h3 {
      color: #ffffff;
      font-size: 22px;
      font-weight: 700;
      line-height: 18px;
      margin-bottom: 0;
      text-transform: uppercase; }
    .single-price-package .price-title .price {
      display: block; }
      .single-price-package .price-title .price h4,
      .single-price-package .price-title .price p {
        color: #ecebeb;
        display: inline-block;
        font-family: "Raleway", sans-serif;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
        bottom: -9px;
        position: relative; }
      .single-price-package .price-title .price h4 span.text-large {
        color: #ffffff;
        font-size: 60px;
        line-height: 61px; }
      .single-price-package .price-title .price h4 span.text-top {
        padding-right: 4px;
        position: relative;
        top: -20px; }

.price-list {
  padding-bottom: 30px;
  padding-top: 50px; }
  .price-list ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .price-list ul li {
      color: #2d3e50;
      font-family: "Raleway", sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px; }

.price-btn .button {
  background: #2d3e50 none repeat scroll 0 0;
  border: 0 none;
  border-radius: 13px;
  color: #ffffff;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 39px;
  margin-top: 52px;
  padding: 0 39px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

.price-btn .button:hover {
  background-color: #7a9757; }

.list-large .price .text-top {
  padding-right: 0;
  top: -23px; }

.list-large .price-title {
  padding: 37px 0 40px; }

.single-price-package.list-large {
  box-shadow: 0 0 20px 1px rgba(35, 31, 32, 0.4);
  margin: -34px 26px 0; }

.list-large .price-list li {
  line-height: 39px; }

@media only screen and (max-width: 767px) {
  .single-price-package.list-large {
    margin: 0; }
  .pricing-table-area .pricing-column:first-child .single-price-package {
    margin-left: 0;
    margin-right: 0; }
  .pricing-table-area .pricing-column:last-child .single-price-package {
    margin-right: 0;
    margin-left: 0; } }

.pricing-table-area.vertical-pricing {
  margin-top: -100px;
  position: relative;
  z-index: 2; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .pricing-table-area.vertical-pricing {
      margin-top: 40px; } }
  @media only screen and (max-width: 767px) {
    .pricing-table-area.vertical-pricing {
      margin-top: 0; } }

/*--------------------------------------------*/
/*  9. Testimonial Area
/*--------------------------------------------*/
.testimonial-area {
  padding-top: 127px; }

.testimonial-image-slider {
  margin-top: 5px; }

.slick-list {
  margin: 0 5px; }

.sin-testiImage {
  display: inline-block;
  margin-bottom: 11px;
  outline: medium none; }

.sin-testiImage img {
  border: 1px solid #7a9757;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block !important;
  height: 97px;
  margin-bottom: 30px;
  margin-top: 36px;
  outline: 0 none;
  width: 97px; }

.slick-current .sin-testiImage img {
  height: 170px;
  margin-top: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  width: 170px;
  border: 3px solid #7a9757; }

.slick-initialized .slick-slide {
  z-index: 1 !important; }

.testimonial-text-slider h2 {
  display: block;
  font-size: 25px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 1px;
  text-transform: uppercase; }

.testimonial-text-slider {
  background: #f0f0f0 none repeat scroll 0 0;
  border-radius: 7px;
  margin: auto;
  padding: 30px 55px 4px;
  position: relative;
  width: 71%;
  z-index: 9; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-text-slider {
      padding: 30px 19px 4px;
      width: 100%; } }
  @media only screen and (max-width: 767px) {
    .testimonial-text-slider {
      padding: 30px 19px 4px;
      width: 100%; } }

.testimonial-text-slider:after {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #f0f0f0;
  -webkit-border-image: none;
  border-image: none;
  border-style: solid;
  border-width: 22px 13px;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  top: -44px;
  width: 0; }

.sin-testiText {
  outline: none; }

.sin-testiText p {
  margin-bottom: 32px; }

.client-rating {
  margin-bottom: 5px; }

.client-rating i {
  color: #7d7d7d;
  font-size: 18px; }

.client-rating i.color {
  color: #f1c30d; }

.slick-prev,
.slick-next {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 25px;
  outline: medium none;
  padding: 0;
  position: absolute;
  top: 34.6%;
  width: 16px; }

.slick-prev {
  left: -31px; }

.slick-next {
  right: -31px; }

.slick-prev:before,
.slick-next:before {
  height: 25px;
  width: 16px;
  left: 0;
  top: 0;
  position: absolute;
  color: #2d3e50;
  content: "\f2fa";
  font-family: "Material-Design-Iconic-Font";
  font-size: 50px !important;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

.slick-prev:hover:before,
.slick-next:hover:before {
  color: #7a9757; }

.slick-next:before {
  right: 0;
  content: "\f2fb"; }

/*=============================
    Screenshot 
===============================*/
.screenshots-area {
  padding-top: 117px; }

.sin-screenshot {
  z-index: 9; }

.sin-screenshot a {
  display: block;
  position: relative; }

.sin-screenshot a:before {
  background: #24141e none repeat scroll 0 0;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.6;
  filter: alpha(opacity=60);
  position: absolute;
  right: 0;
  top: 0; }

.sin-screenshot a:hover:before {
  opacity: 0.2;
  filter: alpha(opacity=20); }

#screenshot-carousel {
  z-index: 9; }

/*======================
    Blog Area 
========================*/
.blog-area {
  position: relative;
  z-index: 2; }

.blog .inner {
  padding: 40px;
  position: relative;
  z-index: 1;
  padding-left: 0; }
  .blog .inner .title a {
    line-height: 1.4074;
    color: #2f2f2f; }
  .blog .inner ul.meta {
    padding: 0;
    margin: 0;
    list-style: none;
    margin: 0 -10px; }
    .blog .inner ul.meta li {
      font-size: 12px;
      line-height: 1;
      display: inline-block;
      margin: 0 10px; }
      .blog .inner ul.meta li a {
        color: #535353; }
  .blog .inner .desc {
    font-size: 16px;
    line-height: 1.71429; }
  .blog .inner .blog-btn .button-link {
    border: 1px solid #d1d1d1;
    padding: 12px 40px;
    text-transform: uppercase;
    color: #2f2f2f;
    background-color: rgba(255, 255, 255, 0);
    line-height: 20px;
    font-size: 13px;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: all .5s;
    transition: all .5s; }
    .blog .inner .blog-btn .button-link:hover {
      color: #fff;
      background-color: #05C2F9;
      border-color: #05C2F9; }

/*========================
Pagination Area 
===========================*/
.page-list {
  margin: 0 -3px; }
  .page-list li {
    display: inline-block;
    margin: 0 3px; }
    .page-list li a {
      font-size: 12px;
      text-align: center;
      display: block;
      position: relative;
      background: #fff;
      border: 1px solid #e0e0e0;
      padding: 15px 10px;
      line-height: 20px;
      min-width: 50px;
      -webkit-transition: all .3s;
      transition: all .3s;
      display: inline-block; }
      .page-list li a:hover {
        background-color: #2f2f2f;
        color: #fff;
        border-color: transparent; }
    .page-list li.ative a {
      background-color: #2f2f2f;
      color: #fff;
      border-color: transparent; }

/**************************************
	Template Styles
***************************************/
/*=======================
    Banner Area 
========================*/
.slider-area {
  height: 1196px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-area {
      height: 780px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-area {
      height: auto;
      padding: 150px 0;
      padding-bottom: 58px; } }
  @media only screen and (max-width: 767px) {
    .slider-area {
      height: auto;
      padding: 150px 0;
      padding-bottom: 58px; } }
  .slider-area.bg-shape::before {
    background-image: url(/assets/images/app/shape.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    content: "";
    height: 460px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
    bottom: 0px;
    right: 0;
    top: auto;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider-area.bg-shape::before {
        height: 283px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider-area.bg-shape::before {
        background-image: inherit;
        height: auto; } }
    @media only screen and (max-width: 767px) {
      .slider-area.bg-shape::before {
        background-image: inherit;
        height: auto; } }
  .slider-area.horizontal {
    height: 1075px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider-area.horizontal {
        height: auto; } }
    @media only screen and (max-width: 767px) {
      .slider-area.horizontal {
        height: auto; } }
    .slider-area.horizontal.bg-shape::before {
      height: 212px;
      background-color: #ffffff;
      background-image: inherit; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .slider-area.horizontal.bg-shape::before {
          height: auto; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .slider-area.horizontal.bg-shape::before {
          height: auto; } }
      @media only screen and (max-width: 767px) {
        .slider-area.horizontal.bg-shape::before {
          height: auto; } }
  .slider-area .row {
    height: 100%; }
  .slider-area .banner-text {
    height: 100%;
    display: table; }
    .slider-area .banner-text .banner-table-cell {
      display: table-cell;
      vertical-align: middle;
      z-index: 9;
      position: relative; }
      .slider-area .banner-text .banner-table-cell h1 {
        font-size: 45px;
        font-weight: 800;
        line-height: 72px;
        margin-bottom: 23px;
        text-transform: uppercase;
        color: #ffffff; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .slider-area .banner-text .banner-table-cell h1 {
            font-size: 50px;
            line-height: 58px;
            margin-bottom: 10px; }
            .slider-area .banner-text .banner-table-cell h1 br {
              display: none; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .slider-area .banner-text .banner-table-cell h1 br {
            display: none; } }
        @media only screen and (max-width: 767px) {
          .slider-area .banner-text .banner-table-cell h1 {
            font-size: 46px;
            margin-bottom: 11px; }
            .slider-area .banner-text .banner-table-cell h1 br {
              display: none; } }
      .slider-area .banner-text .banner-table-cell p {
        color: #ffffff;
        margin-bottom: 33px; }
      .slider-area .banner-text .banner-table-cell .banner-buttons .button-default {
        margin-right: 26px; }
        @media only screen and (max-width: 767px) {
          .slider-area .banner-text .banner-table-cell .banner-buttons .button-default {
            margin-bottom: 20px; } }

/*==================
    Horizontal 
===================*/
.slider-area.horizontal .banner-apps .single-app:nth-child(1) {
  top: 30px; }

.slider-area.horizontal .banner-apps .single-app:nth-child(2) {
  top: 30px; }

.slider-area.horizontal .banner-apps .single-app:nth-child(3) {
  top: 30px; }

/*====================
    Banner Images 
=====================*/
.banner-product-image {
  margin-top: 66%;
  position: relative;
  z-index: 9; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-product-image {
      margin-top: 156px;
      text-align: center !important; } }
  @media only screen and (max-width: 767px) {
    .banner-product-image {
      margin-top: 156px;
      text-align: center !important; } }

/*====================
    Banner Apps 
=====================*/
.banner-apps {
  margin-left: 76px;
  margin-top: -315px;
  position: relative; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-apps {
      margin-left: 0;
      margin-top: 37px; } }
  @media only screen and (max-width: 767px) {
    .banner-apps {
      margin-left: 0;
      margin-top: 37px; } }
  .banner-apps .single-app {
    height: 140px;
    width: 140px;
    border: 4px solid #ffffff;
    border-radius: 100%;
    background: #7a9757 none repeat scroll 0 0;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: 99;
    position: absolute;
    display: inline-table; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .banner-apps .single-app {
        height: 100px;
        width: 100px;
        border: 3px solid #ffffff; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .banner-apps .single-app {
        height: 100px;
        width: 100px;
        border: 3px solid #ffffff; } }
    @media only screen and (max-width: 767px) {
      .banner-apps .single-app {
        height: 100px;
        width: 100px;
        border: 3px solid #ffffff; } }
    .banner-apps .single-app .single-app-table-cell {
      display: table-cell;
      vertical-align: middle; }
    .banner-apps .single-app i {
      color: #ffffff;
      font-size: 35px;
      margin-bottom: 2px; }
    .banner-apps .single-app h4 {
      font-size: 16px;
      color: #ffffff;
      font-weight: 700;
      margin-bottom: 5px;
      text-transform: uppercase; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .banner-apps .single-app h4 {
          font-size: 11px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner-apps .single-app h4 {
          font-size: 11px; } }
      @media only screen and (max-width: 767px) {
        .banner-apps .single-app h4 {
          font-size: 11px; } }
    .banner-apps .single-app h3 {
      font-size: 18px;
      color: #ffffff;
      margin-bottom: 5px;
      text-transform: uppercase; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .banner-apps .single-app h3 {
          font-size: 14px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner-apps .single-app h3 {
          font-size: 14px; } }
      @media only screen and (max-width: 767px) {
        .banner-apps .single-app h3 {
          font-size: 14px; } }
    .banner-apps .single-app:nth-child(1) {
      left: 0;
      top: 122px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .banner-apps .single-app:nth-child(1) {
          top: 183px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner-apps .single-app:nth-child(1) {
          top: 100%; } }
      @media only screen and (max-width: 767px) {
        .banner-apps .single-app:nth-child(1) {
          top: 100%; } }
    .banner-apps .single-app:nth-child(2) {
      left: 258px;
      top: 60px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .banner-apps .single-app:nth-child(2) {
          left: 183px;
          top: 127px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner-apps .single-app:nth-child(2) {
          top: 100%; } }
      @media only screen and (max-width: 767px) {
        .banner-apps .single-app:nth-child(2) {
          top: 100%;
          left: 139px; } }
    .banner-apps .single-app:nth-child(3) {
      left: 516px;
      top: 0; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .banner-apps .single-app:nth-child(3) {
          left: 366px;
          top: 87px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner-apps .single-app:nth-child(3) {
          top: 100%; } }
      @media only screen and (max-width: 767px) {
        .banner-apps .single-app:nth-child(3) {
          top: 100%;
          left: 266px; } }
      @media only screen and (max-width: 479px) {
        .banner-apps .single-app:nth-child(3) {
          display: none; } }

/*====================
    About Area 
======================*/
.about-content .title {
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 19px; }

.about-content p {
  margin-bottom: 25px; }

.about-content .about-buttons {
  margin-top: 48px; }
  .about-content .about-buttons button {
    margin-right: 26px; }
    @media only screen and (max-width: 767px) {
      .about-content .about-buttons button {
        margin-bottom: 20px; } }

.app-about.horizontal-about {
  padding-bottom: 100px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .app-about.horizontal-about {
      padding-top: 100px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-about.horizontal-about {
      padding-bottom: 80px;
      padding-top: 80px; } }
  @media only screen and (max-width: 767px) {
    .app-about.horizontal-about {
      padding-bottom: 80px;
      padding-top: 80px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .app-about {
    padding-bottom: 80px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .app-about {
    padding-bottom: 80px;
    padding-top: 80px; } }

@media only screen and (max-width: 767px) {
  .app-about {
    padding-bottom: 80px;
    padding-top: 80px; } }

/*=====================
    Download Area 
=======================*/
.download-area {
  position: relative;
  margin-top: -197px;
  padding-bottom: 408px;
  padding-top: 358px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: -166px;
  padding-bottom: 348px;
  padding-top: 319px;
  position: relative;
  z-index: 2;
  background-image: url(/assets/images/bg/bg-image-1.jpg); }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .download-area {
      margin-top: -347px;
      padding-bottom: 308px;
      padding-top: 436px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .download-area {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      background-image: inherit;
      margin-top: 0;
      padding-bottom: 100px;
      padding-top: 100px; } }
  @media only screen and (max-width: 767px) {
    .download-area {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      background-image: inherit;
      margin-top: 0;
      padding-bottom: 100px;
      padding-top: 100px; } }
  .download-area .bg-overlay {
    background: rgba(45, 62, 80, 0.85) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }
  .download-area::before {
    background-image: url(/assets/images/app/shape-2.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2; }
  .download-area::after {
    background-image: url(/assets/images/app/shape-3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    content: "";
    right: 0;
    position: absolute;
    bottom: -1px;
    width: 100%;
    z-index: 1;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .download-area::before, .download-area::after {
    height: 460px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .download-area::before, .download-area::after {
        height: auto; } }
    @media only screen and (max-width: 767px) {
      .download-area::before, .download-area::after {
        height: auto; } }
  .download-area.horizontal {
    margin-top: 0;
    padding-bottom: 100px;
    padding-top: 100px;
    position: relative;
    z-index: 2;
    background-image: url(/assets/images/bg/download-our-apps.png);
    margin-bottom: 77px; }
    .download-area.horizontal::before, .download-area.horizontal::after {
      height: auto; }
    .download-area.horizontal::before {
      background-image: inherit; }
    .download-area.horizontal::after {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      background-image: inherit; }
    @media only screen and (max-width: 767px) {
      .download-area.horizontal {
        margin-bottom: 0; } }

.download-buttons {
  text-align: center; }
  .download-buttons .download-btn {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #ffffff;
    border-radius: 2px;
    height: 78px;
    margin: 0 21px;
    padding: 0 29px 0 87px;
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    display: inline-block;
    margin-bottom: 20px; }
    .download-buttons .download-btn i {
      color: #ffffff;
      float: left;
      font-size: 50px;
      left: 22px;
      position: absolute;
      width: 53px; }
    .download-buttons .download-btn span {
      color: #ffffff;
      display: block;
      font-family: "Raleway", sans-serif;
      font-size: 18px;
      line-height: 26px;
      text-align: left; }
      @media only screen and (max-width: 767px) {
        .download-buttons .download-btn span {
          font-size: 13px; } }
      .download-buttons .download-btn span span.large-text {
        display: block !important;
        font-weight: 700;
        font-size: 26px;
        position: relative;
        top: -4px;
        color: #ffffff; }

/*=========================
    Breadcaump Area 
===========================*/
.breadcaump-area .inner {
  text-align: center;
  padding-top: 120px; }
  .breadcaump-area .inner .title {
    font-size: 64px;
    color: #ffffff;
    font-weight: 400; }
  .breadcaump-area .inner .page-breadcrumb {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -moz-flex;
    display: flex;
    -moz-justify-content: center;
    justify-content: center;
    flex-wrap: wrap; }
    .breadcaump-area .inner .page-breadcrumb li {
      color: #ffffff;
      font-size: 14px;
      line-height: 1.71429;
      margin: 0 6px; }
      .breadcaump-area .inner .page-breadcrumb li a {
        color: #ffffff; }

/*========================
    Blog Details 
==========================*/
.single-post-details .content p {
  line-height: 28px;
  font-size: 16px; }
  .single-post-details .content p.first-bold {
    font-size: 18px;
    line-height: 1.94;
    font-weight: 500;
    color: #222222; }

.single-post-details .quote-content {
  position: relative;
  margin: 37px 0 44px !important;
  padding: 0 0 0 53px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.625; }
  .single-post-details .quote-content::before {
    content: "\f1b2";
    font-size: 26px;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: 'Material-Design-Iconic-Font';
    font-weight: 900;
    color: #05C2F9; }
  .single-post-details .quote-content .quote-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.625;
    color: #222222;
    font-style: normal; }

.blog-tag-list a {
  color: #2f2f2f;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-weight: 500;
  line-height: 1.58;
  font-size: 15px;
  position: relative;
  padding-left: 5px; }
  .blog-tag-list a + a::before {
    position: absolute;
    content: ",";
    left: 0; }
  .blog-tag-list a:hover {
    color: #05C2F9; }

.author-social {
  margin: 0 -4px;
  margin-left: 12px; }
  .author-social a {
    display: inline-block;
    margin: 0 4px;
    background: #05C2F9;
    width: 25px;
    height: 25px;
    line-height: 25px;
    color: #fff;
    border-radius: 100%;
    text-align: center;
    -webkit-transition: 0.5s;
    transition: 0.5s; }
    .author-social a.facebook {
      background: #3b5999; }
    .author-social a.twitter {
      background: #57aced; }
    .author-social a.google-plus {
      background: #dc143c; }

/*========================
    BLog Comment 
==========================*/
.commnent-list-wrap .comment {
  list-style-type: none;
  margin-top: 48px;
  padding-top: 37px;
  padding-left: 6px;
  border-top: 1px solid #eee; }
  .commnent-list-wrap .comment .thumb {
    float: left; }
    @media only screen and (max-width: 767px) {
      .commnent-list-wrap .comment .thumb {
        float: none; } }
    .commnent-list-wrap .comment .thumb img {
      border-radius: 100%;
      width: 100px;
      height: 100px; }
  .commnent-list-wrap .comment .content {
    position: relative;
    overflow: hidden;
    margin-left: 152px; }
    @media only screen and (max-width: 767px) {
      .commnent-list-wrap .comment .content {
        margin-left: 0;
        margin-top: 30px; } }
    .commnent-list-wrap .comment .content .reply-btn a {
      color: #ffffff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      display: inline-block;
      font-size: 12px;
      border: 1px solid #222222;
      background: #222222;
      padding: 3px 10px;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
      .commnent-list-wrap .comment .content .reply-btn a:hover {
        color: #05C2F9;
        border: 1px solid #05C2F9;
        background: transparent; }
    .commnent-list-wrap .comment .content .comment-footer span {
      font-size: 12px;
      font-weight: 500;
      display: inline-block; }
  .commnent-list-wrap .comment.comment-reply {
    padding-left: 61px; }
    @media only screen and (max-width: 767px) {
      .commnent-list-wrap .comment.comment-reply {
        padding-left: 30px; } }
    @media only screen and (max-width: 575px) {
      .commnent-list-wrap .comment.comment-reply {
        padding-left: 0; } }

.comment-form-wrapper .blog-btn a {
  background: #05C2F9;
  display: inline-block;
  color: #fff;
  padding: 0 36px;
  height: 55px;
  line-height: 55px;
  font-size: 14px;
  border-radius: 6px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
  font-weight: 600; }
  .comment-form-wrapper .blog-btn a:hover {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px); }

/* ====================
    Contact Form 
======================*/
.comment-form-wrapper .contact-form input {
  height: 50px;
  border: 1px solid #eee; }

.comment-form-wrapper .contact-form textarea {
  color: #777;
  border-color: #eee;
  background-color: #fff;
  height: 150px;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-radius: 0;
  padding: 12px 20px 3px;
  max-width: 100%;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

/*====================
    Footer Area 
======================*/
.footer-area {
  padding-top: 322px;
  background-image: url(/assets/images/bg/beautiful_addis_ababa.jpg);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  padding-bottom: 20px;
  position: relative;
  z-index: 1; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-area {
      padding-top: 455px; } }
  .footer-area::before {
    background-image: url(/assets/images/app/shape-2.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    content: "";
    height: 460px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-area {
      margin-top: 120px;
      padding-top: 120px; }
      .footer-area::before {
        background-image: inherit;
        height: auto; } }
  @media only screen and (max-width: 767px) {
    .footer-area {
      margin-top: 120px;
      padding-top: 120px; }
      .footer-area::before {
        background-image: inherit;
        height: auto; } }
  .footer-area .footer-bg {
    background: rgba(45, 62, 80, 0.85) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.contact-inner .title {
  color: #ffffff;
  font-size: 28px;
  font-weight: 800;
  line-height: 20px;
  text-transform: uppercase; }

.contact-inner .contact-form {
  margin-right: 21px;
  margin-top: 46px; }

.contact-inner .input-box {
  margin-bottom: 15px; }
  .contact-inner .input-box input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #ffffff;
    border-radius: 8px;
    box-shadow: none;
    color: #cccccc;
    height: 42px;
    margin-bottom: 3px;
    padding: 8px 17px;
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 1.42857143; }
    .contact-inner .input-box input::-webkit-input-placeholder {
      color: #fff; }
    .contact-inner .input-box input:-moz-placeholder {
      color: #fff; }
    .contact-inner .input-box input::-moz-placeholder {
      color: #fff; }
    .contact-inner .input-box input:-ms-input-placeholder {
      color: #fff; }
  .contact-inner .input-box textarea {
    height: 99px;
    resize: none;
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #ffffff;
    border-radius: 8px;
    box-shadow: none;
    color: #cccccc;
    margin-bottom: 3px;
    padding: 8px 17px; }
    .contact-inner .input-box textarea::-webkit-input-placeholder {
      color: #fff; }
    .contact-inner .input-box textarea:-moz-placeholder {
      color: #fff; }
    .contact-inner .input-box textarea::-moz-placeholder {
      color: #fff; }
    .contact-inner .input-box textarea:-ms-input-placeholder {
      color: #fff; }
  .contact-inner .input-box .submite-button {
    background-color: #05C2F9;
    border-radius: 7px;
    color: #ffffff;
    font-family: "Raleway", sans-serif;
    height: 44px;
    margin-top: 21px;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 100%;
    border: 0; }
    .contact-inner .input-box .submite-button:hover {
      background-color: #fff;
      color: #2d3e50; }

/*=====================
    Contact Address 
========================*/
.contact-icon {
  background-color: #05C2F9;
  border-radius: 21px 21px 0;
  color: #ffffff;
  display: inline-table;
  float: left;
  height: 54px;
  margin-right: 24px;
  text-align: center;
  width: 54px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

.single-contact-info:hover .contact-icon {
  background-color: #fff;
  color: #2D3E50; }

.contact-icon i {
  display: table-cell;
  font-size: 30px;
  vertical-align: middle; }

.contact-text > span {
  color: #eeeeee;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px; }

.single-contact-info {
  margin-bottom: 26px;
  overflow: hidden; }

.contact-text {
  display: inline-block; }

.conatct-info {
  margin-top: 52px; }

/*--------------------------------------------*/
/*  11.2 Newsletter
/*--------------------------------------------*/
.newsletter .title {
  margin-bottom: 23px;
  color: #ffffff;
  font-size: 28px;
  font-weight: 800;
  line-height: 20px;
  text-transform: uppercase; }
  @media only screen and (max-width: 767px) {
    .newsletter .title {
      font-size: 24px;
      line-height: 35px; } }

.newsletter {
  margin-top: 105px; }

.newsletter-content > input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 1px solid #ffffff;
  border-radius: 9px;
  color: #a3a3a3;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  height: 49px;
  padding: 0 19px;
  width: 100%;
  padding-right: 177px; }

.newsletter-content input::-webkit-input-placeholder {
  color: #fff; }

.newsletter-content input:-moz-placeholder {
  color: #fff; }

.newsletter-content input::-moz-placeholder {
  color: #fff; }

.newsletter-content input:-ms-input-placeholder {
  color: #fff; }

.newsletter-content {
  margin-top: 38px;
  position: relative; }

.newsletter-content .button {
  border: 0 none;
  border-radius: 9px;
  color: #2d3e50;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  height: 100%;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 29.5%;
  background: #d4d4d4; }

.newsletter-content .button:hover {
  background-color: #7a9757;
  color: #ffffff; }

/*=====================
Footer Links 
======================*/
.footer-links a {
  border: 1px solid #fafbfb;
  border-radius: 100%;
  color: #ffffff;
  display: inline-table;
  font-size: 20px;
  height: 36px;
  margin: 0 6.5px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 36px; }

.footer-links a i {
  display: table-cell;
  vertical-align: middle; }

.footer-links a:hover {
  background-color: #05C2F9;
  border-color: #05C2F9; }

.footer-links {
  margin-top: 40px; }

.footer-text span {
  color: #cccccc; }

.footer-text {
  margin-top: 23px; }

.footer-text span a {
  color: #cccccc; }

.footer-text span a:hover {
  border-bottom: 1px solid #7a9757;
  color: #05C2F9; }

.footer-area.vertical-footer {
  margin-top: -123px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-area.vertical-footer {
      margin-top: 40px; } }
  @media only screen and (max-width: 767px) {
    .footer-area.vertical-footer {
      margin-top: 40px; } }

.footer-area.horizontal {
  margin-top: 80px;
  padding-top: 120px; }
  .footer-area.horizontal::before {
    background-image: inherit;
    height: auto; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-area.horizontal {
      margin-top: 40px; } }
  @media only screen and (max-width: 767px) {
    .footer-area.horizontal {
      margin-top: 40px; } }

@media only screen and (max-width: 767px) {
  .newsletter-content .button {
    font-size: 12px; }
  .footer-links a {
    margin-bottom: 10px; } }

/*=============================================
=            landing page            =
=============================================*/
.landing-page-wrapper {
  /*************************
      1.General
    *************************/
  /*-- Common Classes --*/
  /*-- Section --*/
  /*-- Background Color --*/
  /*-- Button --*/
  /*-- Button Animation --*/
  /*-- Button Hover --*/
  /*-- Button Hover Animation --*/
  /*-- Button White --*/
  /*-- Button black --*/
  /*-- Button Color --*/
  /*-- Section Title --*/
  /*-- Section Title 2 --*/
  /*-- Section Title 3 --*/
  /*-- Overlay --*/
  /*-- Page Banner Section --*/
  /*-- Pagination --*/
  /*-- Scroll Up --*/
  /*-- Main Wrapper --*/
  /* -----------------------------------
      02. Header Section
    --------------------------------------*/
  /*-- Logo --*/
  /*=============================================
    =            custom style            =
    =============================================*/
  /*=====  End of landing page  ======*/
  /*=============================================
    =            responsive            =
    =============================================*/
  /* Large Screen  */
  /* Laptop Screen  */
  /* desktop :992px. */
  /* Tablet :768px. */
  /* Large Mobile :480px. */
  /* small mobile :320px. */ }
  .landing-page-wrapper .landing-hero-bg {
    background-image: url("/assets/images/landing/hero-bg.jpg"); }
  .landing-page-wrapper .header-section .logo {
    padding-right: 0;
    flex-basis: auto; }
    @media only screen and (max-width: 479px) {
      .landing-page-wrapper .header-section .logo a img {
        max-width: 100%; } }
  .landing-page-wrapper .fix {
    overflow: hidden; }
  .landing-page-wrapper .float-left {
    float: left; }
  .landing-page-wrapper .float-right {
    float: right; }
  .landing-page-wrapper .section {
    float: left;
    position: relative;
    width: 100%; }
  .landing-page-wrapper .bg-gray {
    background-color: #f6f6f6; }
  .landing-page-wrapper .bg-dark {
    background-color: #1a1a1a; }
  .landing-page-wrapper .bg-light-dark {
    background-color: #232323; }
  .landing-page-wrapper .btn {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: 12px;
    font-weight: 700;
    height: 43px;
    letter-spacing: 0.4px;
    line-height: 23px;
    overflow: hidden;
    padding: 9px 50px;
    position: relative;
    text-transform: uppercase;
    z-index: 1; }
  .landing-page-wrapper .btn::before {
    -webkit-animation: 0.3s linear 0s normal forwards 1 running btnAnimationOut;
    animation: 0.3s linear 0s normal forwards 1 running btnAnimationOut;
    background-color: inherit;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02);
    width: 21px;
    z-index: -1; }

@-webkit-keyframes btnAnimationOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    width: 100%; }
  50% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02);
    width: 100%; }
  100% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02); } }

@keyframes btnAnimationOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    width: 100%; }
  50% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02);
    width: 100%; }
  100% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02); } }
  .landing-page-wrapper .btn:hover {
    -webkit-transition: all 0.3s ease 0.3s;
    transition: all 0.3s ease 0.3s; }
  .landing-page-wrapper .btn:hover::before {
    -webkit-animation: 0.5s linear 0s normal forwards 1 running btnAnimationIn;
    animation: 0.5s linear 0s normal forwards 1 running btnAnimationIn; }

@-webkit-keyframes btnAnimationIn {
  0% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02); }
  50% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02);
    width: 100%; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    width: 100%; } }

@keyframes btnAnimationIn {
  0% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02); }
  50% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02);
    width: 100%; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    width: 100%; } }
  .landing-page-wrapper .btn.white {
    border: 1px solid #fff;
    color: #fff; }
  .landing-page-wrapper .btn.white:hover {
    color: #05C2F9; }
  .landing-page-wrapper .btn.white::before {
    background-color: #fff; }
  .landing-page-wrapper .btn.black {
    border: 1px solid #232323;
    color: #232323; }
  .landing-page-wrapper .btn.black:hover {
    color: #ffffff; }
  .landing-page-wrapper .btn.black::before {
    background-color: #232323; }
  .landing-page-wrapper .btn.color {
    border: 1px solid #05C2F9;
    color: #05C2F9; }
  .landing-page-wrapper .btn.color:hover {
    color: #ffffff; }
  .landing-page-wrapper .btn.color::before {
    background-color: #05C2F9; }
  .landing-page-wrapper .section-title h1 {
    color: #373737;
    display: block;
    font-weight: 500;
    line-height: 28px;
    margin: 0;
    position: relative;
    text-transform: capitalize;
    z-index: 1; }
  .landing-page-wrapper .section-title.white h1 {
    color: #ffffff; }
  .landing-page-wrapper .section-title h1 span {
    color: #05C2F9; }
  .landing-page-wrapper .section-title p {
    color: #5b5b5b;
    display: inline-block;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 0;
    margin-top: 30px;
    max-width: 775px; }
  .landing-page-wrapper .section-title.white p {
    color: #ffffff; }
  .landing-page-wrapper .section-title-2 {
    z-index: 1; }
  .landing-page-wrapper .section-title-2::before {
    color: #2b2b2b;
    content: attr(data-title);
    font-size: 150px;
    font-weight: 800;
    left: 15px;
    line-height: 110px;
    position: absolute;
    right: 15px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1; }
  .landing-page-wrapper .section-title-2 h1 {
    color: #ffffff;
    display: block;
    font-size: 40px;
    font-weight: 800;
    line-height: 28px;
    margin: 0;
    position: relative;
    text-transform: uppercase;
    z-index: 1; }
  .landing-page-wrapper .section-title-2 p {
    color: #d7d7d7;
    display: inline-block;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 0;
    margin-top: 33px;
    max-width: 775px; }
  .landing-page-wrapper .section-title-3 {
    z-index: 1; }
  .landing-page-wrapper .section-title-3 h1 {
    color: #373737;
    display: block;
    font-size: 32px;
    font-weight: 800;
    line-height: 23px;
    margin: 0;
    position: relative;
    text-transform: uppercase;
    z-index: 1; }
  .landing-page-wrapper .section-title-3.white h1 {
    color: #ffffff; }
  .landing-page-wrapper .section-title-3 h1 span {
    color: #05C2F9; }
  .landing-page-wrapper .section-title-3 p {
    color: #6b6b6b;
    display: inline-block;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    margin-bottom: 0;
    margin-top: 30px;
    max-width: 520px; }
  .landing-page-wrapper .section-title-3.white p {
    color: #ffffff; }
  .landing-page-wrapper .overlay {
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1; }
  .landing-page-wrapper .overlay::before {
    background-color: #000;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.7;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1; }
  .landing-page-wrapper .overlay-dark::before {
    background-color: #000000;
    opacity: 0.9; }
  .landing-page-wrapper .overlay-gradient::before {
    background: #2d3e50;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #2d3e50), color-stop(100%, #05C2F9));
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #2d3e50), color-stop(100%, #05C2F9));
    background: -webkit-linear-gradient(top, #2d3e50 0%, #05C2F9 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#2d3e50), to(#05C2F9));
    background: linear-gradient(to bottom, #2d3e50 0%, #05C2F9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2d3e50', endColorstr='$theme-color', GradientType=0);
    opacity: 0.9; }
  .landing-page-wrapper .page-banner-section {
    padding: 250px 0; }
  .landing-page-wrapper .page-banner-content h1 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 500;
    margin: 0;
    text-transform: capitalize; }
  .landing-page-wrapper .pagination {
    display: block;
    margin: 20px 0 0;
    text-align: center; }
  .landing-page-wrapper .pagination ul {
    display: inline-block;
    vertical-align: top; }
  .landing-page-wrapper .pagination ul li {
    display: block;
    float: left;
    margin: 0 5px; }
  .landing-page-wrapper .pagination ul li a {
    border: 1px solid #acacac;
    border-radius: 50%;
    color: #6d6d6d;
    display: block;
    font-size: 12px;
    font-weight: 300;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px; }
  .landing-page-wrapper .pagination ul li a i {
    display: block;
    font-size: 18px;
    line-height: 30px; }
  .landing-page-wrapper .pagination ul li a:hover,
  .landing-page-wrapper .pagination ul li.active a {
    border: 1px solid #05C2F9;
    color: #05C2F9; }
  .landing-page-wrapper #scrollUp {
    background-color: transparent;
    border: 2px solid #999;
    border-radius: 50%;
    bottom: 28px;
    color: #999;
    height: 40px;
    position: fixed;
    right: 30px;
    text-align: center;
    width: 40px; }
  .landing-page-wrapper #scrollUp i {
    display: block;
    font-size: 24px;
    line-height: 35px; }
  .landing-page-wrapper #scrollUp:hover {
    background-color: #3f3f3f;
    color: #fff; }
  .landing-page-wrapper .main-wrapper {
    background-color: #fff;
    margin-bottom: 278px;
    z-index: 9; }
  .landing-page-wrapper .header-section {
    background-color: transparent;
    left: 0;
    position: absolute;
    right: 0;
    padding: 20px 0;
    top: 0;
    z-index: 999; }
  .landing-page-wrapper .header-section.stick {
    -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    background-color: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0; }
  .landing-page-wrapper .logo a .sticky-logo {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    padding-left: 15px; }
  .landing-page-wrapper .stick .logo a img {
    opacity: 0; }
  .landing-page-wrapper .stick .logo a .sticky-logo {
    opacity: 1; }
  .landing-page-wrapper .buy-btn {
    border: 2px solid #ffffff;
    border-radius: 50px;
    color: #ffffff;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    height: 40px;
    line-height: 24px;
    margin-top: 0;
    padding: 6px 35px;
    text-transform: uppercase; }
  .landing-page-wrapper .stick .buy-btn {
    border: 2px solid #05C2F9;
    color: #05C2F9;
    margin-top: 3px; }
  .landing-page-wrapper .buy-btn:hover {
    background-color: #05C2F9;
    border-color: #05C2F9;
    color: #ffffff; }
  .landing-page-wrapper .hero-section {
    padding: 300px 0 250px; }
  .landing-page-wrapper .hero-content img {
    margin-bottom: 30px;
    width: 170px; }
  .landing-page-wrapper .hero-content h1 {
    color: #fff;
    font-weight: 500;
    font-size: 50px; }
  .landing-page-wrapper .hero-content h1 strong {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 30px;
    display: block; }
  .landing-page-wrapper .hero-content h1 span {
    display: block;
    font-size: 44px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 700; }
  .landing-page-wrapper .hero-content p {
    color: #fff;
    margin: 20px auto 0;
    max-width: 650px; }
  .landing-page-wrapper .hero-content a {
    margin-top: 40px;
    display: inline-block; }
  .landing-page-wrapper .demo-item {
    text-align: center; }
  .landing-page-wrapper .demo-item .image {
    display: block;
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .landing-page-wrapper .demo-item .image::after {
    background-color: #05C2F9;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .landing-page-wrapper .demo-item:hover .image {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  .landing-page-wrapper .demo-item:hover .image::after {
    opacity: 0.8; }
  .landing-page-wrapper .demo-item .image i {
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    height: 60px;
    left: 50%;
    line-height: 56px;
    margin-left: -30px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 60px;
    z-index: 9; }
  .landing-page-wrapper .demo-item:hover .image i {
    opacity: 1;
    margin-top: -30px; }
  .landing-page-wrapper .demo-item .image i:hover {
    border: 2px solid #ffffff;
    background-color: #ffffff;
    color: #05C2F9; }
  .landing-page-wrapper .demo-item .image img {
    width: 100%; }
  .landing-page-wrapper .demo-item .title {
    font-size: 18px;
    font-weight: 400;
    margin: 30px 0 0;
    text-transform: uppercase; }
  .landing-page-wrapper .demo-item .title a {
    color: #3e3e3e;
    display: block;
    padding: 5px; }
  .landing-page-wrapper .demo-item .title a:hover {
    color: #05C2F9; }
  .landing-page-wrapper .single-feature .icon {
    color: #05C2F9;
    margin-right: 20px; }
  .landing-page-wrapper .single-feature .icon i {
    display: block;
    font-size: 36px; }
  .landing-page-wrapper .single-feature .content h4 {
    display: block;
    font-size: 18px;
    line-height: 13px;
    padding-bottom: 2px;
    text-transform: capitalize; }
  .landing-page-wrapper .single-feature .content p {
    color: #666;
    margin: 0; }
  .landing-page-wrapper .footer-section {
    background-color: #05C2F9; }
  .landing-page-wrapper .footer-section h1 {
    color: #fff;
    display: block;
    float: left;
    font-size: 30px;
    margin-bottom: 20px; }
  .landing-page-wrapper .footer-section .buy-btn:hover {
    color: #05C2F9;
    border-color: #ffffff;
    background-color: #ffffff; }
  .landing-page-wrapper .also-like .demo-item .title {
    font-size: 16px;
    font-weight: 400;
    margin: 22px 0 0;
    text-transform: capitalize; }
  .landing-page-wrapper .buy-btn:hover {
    background-color: #05C2F9;
    border-color: #05C2F9;
    color: #ffffff; }
  .landing-page-wrapper .header-section .buy-btn {
    border-color: #ffffff;
    background-color: #ffffff;
    color: #000000;
    font-weight: 600; }
  .landing-page-wrapper .header-section .buy-btn:hover {
    border-color: #05C2F9;
    background-color: #05C2F9;
    color: #ffffff; }
  .landing-page-wrapper .stick.header-section .buy-btn {
    border-color: #000000;
    background-color: #000000;
    color: #ffffff; }
  .landing-page-wrapper .stick.header-section .buy-btn:hover {
    border-color: #05C2F9;
    background-color: #05C2F9;
    color: #ffffff; }
  .landing-page-wrapper .demo-item .image img {
    border: 1px solid #f1f1f1; }
  .landing-page-wrapper .demo-item:hover .image {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15); }
  .landing-page-wrapper .demo-item .image::after {
    display: none; }
  .landing-page-wrapper .demo-item .image i {
    display: none; }
  .landing-page-wrapper .stick .buy-btn {
    border: 2px solid #05C2F9;
    color: #05C2F9; }
  .landing-page-wrapper .single-feature .icon {
    color: #05C2F9;
    width: 50px; }
  .landing-page-wrapper .single-feature .content h4 {
    line-height: 1; }
  .landing-page-wrapper .footer-section {
    background-color: #444; }
  .landing-page-wrapper .stick .buy-btn:hover {
    color: #fff; }
  .landing-page-wrapper .demo-item .title a:hover {
    color: #05C2F9; }
  .landing-page-wrapper .demo-item .title {
    text-transform: capitalize;
    font-weight: 500;
    margin-top: 20px; }
  .landing-page-wrapper .demo-item .title span {
    font-size: 13px; }
  .landing-page-wrapper .footer-section .buy-btn:hover {
    background-color: #05C2F9;
    border-color: #05C2F9;
    color: #ffffff; }
  .landing-page-wrapper .hero-content h1 {
    text-transform: none; }
  .landing-page-wrapper .hero-content h1 strong {
    text-transform: capitalize; }
  .landing-page-wrapper .logo a,
  .landing-page-wrapper .stick .logo a {
    width: auto; }
  @media only screen and (max-width: 767px) {
    .landing-page-wrapper .footer-section .buy-btn {
      float: left !important; } }
  @media only screen and (min-width: 992px) and (max-width: 1169px) {
    .landing-page-wrapper .single-feature .content p {
      font-size: 14px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .landing-page-wrapper .hero-content h1 {
      font-size: 30px; }
    .landing-page-wrapper .demo-content h1 {
      font-size: 30px; }
    .landing-page-wrapper .demo-item .image i {
      height: 50px;
      line-height: 46px;
      width: 50px; }
    .landing-page-wrapper .single-feature .content p {
      font-size: 14px; }
    .landing-page-wrapper .footer-section h1 {
      font-size: 24px; } }
  @media only screen and (max-width: 767px) {
    .landing-page-wrapper .hero-section {
      padding: 150px 0 100px; }
    .landing-page-wrapper .hero-content h1 {
      font-size: 24px; }
    .landing-page-wrapper .hero-content h1 strong {
      font-size: 40px;
      margin-bottom: 15px; }
    .landing-page-wrapper .hero-content a {
      margin-top: 20px; }
    .landing-page-wrapper .demo-content h1 {
      font-size: 30px; }
    .landing-page-wrapper .section-title h1 {
      font-size: 30px; }
    .landing-page-wrapper .footer-section .buy-btn {
      float: left; } }
  @media only screen and (max-width: 479px) {
    .landing-page-wrapper .buy-btn {
      font-size: 11px;
      height: 34px;
      padding: 3px 25px;
      margin-top: 0; }
    .landing-page-wrapper .hero-section {
      padding: 150px 0 100px; }
    .landing-page-wrapper .hero-content h1 {
      font-size: 14px;
      line-height: 20px; }
    .landing-page-wrapper .hero-content h1 strong {
      font-size: 30px; }
    .landing-page-wrapper .hero-content p {
      font-size: 13px; }
    .landing-page-wrapper .hero-content a {
      margin-top: 20px; } }

.landing-page-wrapper .section-title h1 {
  color: #373737;
  display: block;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  font-size: 39px !important; }

/*=====  End of responsive  ======*/
/*============================
    Template Color Variation 
==============================*/
.template-color-1 .theme-color {
  color: #05C2F9; }

.template-color-2 .theme-color {
  color: #f4769a; }

.template-color-3 .theme-color {
  color: #2f21b3; }

.template-color-4 .theme-color {
  color: #8956e2; }

@-webkit-keyframes ring-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes ring-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.color-1 {
  background: #05C2F9; }

.color-2 {
  background: #05C2F9; }

.color-3 {
  background: #FDC007; }

.color-picker {
  position: fixed;
  right: -190px;
  top: 200px;
  width: 190px;
  z-index: 9;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }
  .color-picker .settings-header {
    background: -webkit-gradient(linear, left top, left bottom, from(#05C2F9), to(#05C2F9));
    background: -webkit-linear-gradient(#05C2F9, #05C2F9);
    background: linear-gradient(#05C2F9, #05C2F9);
    padding: 5px 15px; }
    .color-picker .settings-header h3 {
      color: #fff;
      margin: 0;
      font-size: 20px;
      padding: 4px 9px; }

.color-picker a.handle {
  position: absolute;
  right: 190px;
  width: 50px;
  height: 49px;
  color: #fff;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  z-index: -1;
  border-radius: 50%;
  text-decoration: none;
  top: -80px;
  border: 1px solid #fff; }

.handle i {
  -webkit-animation: ring-rotate 3s infinite linear;
          animation: ring-rotate 3s infinite linear; }

.sec-position {
  position: relative;
  top: -80px; }

.color-picker .section {
  background: #fff;
  padding: 20px;
  overflow: auto; }

.color-picker .colors a {
  width: 35px;
  height: 35px;
  margin: 0 7px;
  float: left;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in; }

/*=========================
    Thumbnail Color 
===========================*/
.color-picker a.handle {
  background: #05C2F9; }

.color-picker .section {
  border: 1px solid #05C2F9; }

.logo a img.logo-2,
.logo a img.logo-3,
.banner-product-image .image-2,
.banner-product-image .image-3,
.section-title .image-2,
.section-title .image-3,
.about-thumbnail .image-2,
.about-thumbnail .image-3,
.screenshot-carousel .image-2,
.screenshot-carousel .image-3 {
  display: none; }

